import React from "react";
import { useAtomValue } from "jotai";
import { Button, ButtonProps } from "primereact/button";
import { Icon } from "../../../theme/Icon";
import { themeAtom, Theme } from "../../../theme/themeAtoms";
import { getSystemTheme } from "../../../util";

export interface ThemeSelectButtonProps extends ButtonProps {
  theme?: Theme;
}

export const ThemeSelectButton: React.FC<ThemeSelectButtonProps> = ({
  theme,
  ...rest
}) => {
  const currentTheme = useAtomValue(themeAtom);
  const systemTheme = getSystemTheme();

  const storedTheme =
    theme ?? (currentTheme === "system" ? systemTheme : currentTheme);

  const iconName = storedTheme === "light" ? "SUN" : "MOON";

  return (
    <Button
      {...rest}
      aria-label="Select Theme"
      rounded
      icon={<Icon className={iconName} color={"var(--text-button-1)"} />}
    />
  );
};
