import React, {
  DetailedHTMLProps,
  HTMLAttributes,
  PropsWithChildren,
} from "react";
import { ThemeSelectCardPreview } from "./ThemeSelectCardPreview";
import { Theme } from "../../../theme";
import { PrimeIcons } from "primereact/api";

export interface ThemeSelectCardProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  mode?: "normal" | "outlined" | "selected";
  theme?: Theme;
}

const modeClasses = {
  normal: "m-2 border-round-2xl",
  outlined: "m-2 border-round-2xl border-2 border-200",
  selected: "m-2 border-round-2xl border-2 border-primary",
} satisfies Record<Required<ThemeSelectCardProps>["mode"], string>;

export const ThemeSelectCard: React.FC<
  PropsWithChildren<ThemeSelectCardProps>
> = ({ mode, theme, children, ...rest }) => {
  return (
    <div {...rest} style={{ ...rest.style, position: "relative" }}>
      <ThemeSelectCardPreview
        theme={theme}
        className={modeClasses[mode ?? "normal"]}
        style={{ cursor: "pointer" }}
      />
      {mode === "selected" && (
        <div
          style={{
            position: "absolute",
            top: "0px",
            right: "0px",
            marginTop: "16px",
            marginRight: "22px",
          }}
        >
          <i
            className={`${PrimeIcons.CHECK} border-circle bg-primary`}
            style={{
              padding: "60%",
              width: 24,
              height: 24,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          />
        </div>
      )}
      {children}
    </div>
  );
};
