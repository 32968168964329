import { ITranscriptionService } from "./exercise/Exercise";
import { standaloneTRPCClient } from "@arena-active/trpc-client";

export class TranscriptionService implements ITranscriptionService {
  apiUrl: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trpcClient: any;

  constructor(apiUrl: string) {
    this.apiUrl = apiUrl;
    this.trpcClient = standaloneTRPCClient(apiUrl);
  }

  async transcribe(key: string): Promise<string> {
    console.log("Transcribing", key);
    try {
      const result = await this.trpcClient.activity.transcribeAudio.query(
        { audioStorageKey: key ?? "" },
        { enabled: !!key },
      );
      console.log("Transcription result: ", result);
      return result;
    } catch (e) {
      console.error(e);
      return "Error transcribing";
    }
  }
}
