import { useAtom, useAtomValue } from "jotai";
import { showNavAtom } from "../layout/atoms";
import {
  progressAtom,
  totalStepsAtom,
  completedStepsAtom,
  selectedStepIndexAtom,
} from "../state/atoms";
import { ProgressCircle } from "../progress/ProgressCircle";
import styles from "./NavHeader.module.css";
import { Typography } from "@arena-active/client-lib";
import { ActivityHeaderButton } from "./ActivityHeaderButton";
import { useMediaQuery } from "react-responsive";

interface NavHeaderProps {
  label?: string;
  title?: string;
  showTitle?: boolean;
  showLabel?: boolean;
  showProgress?: boolean;
  showResumeButton?: boolean;
  onResumeActivity?: () => void;
}

export const NavHeader: React.FC<NavHeaderProps> = ({
  label,
  title,
  showTitle = true,
  showLabel = false,
  showProgress = true,
  showResumeButton = false,
  onResumeActivity,
}) => {
  const [, setShowNav] = useAtom(showNavAtom);
  const progress = useAtomValue(progressAtom);
  const completedSteps = useAtomValue(completedStepsAtom);
  const selectedStepIndex = useAtomValue(selectedStepIndexAtom);
  const totalSteps = useAtomValue(totalStepsAtom);
  const isMobile = useMediaQuery({ maxWidth: 960 });
  return (
    <>
      <div className={styles.navHeader}>
        <div className={styles.titleInfo}>
          <div style={{ display: "flex", gap: showLabel ? "8px" : "0px" }}>
            {showLabel && (
              <Typography variant={"titleLargeStrong"}>{label}</Typography>
            )}
            {showTitle && (
              <Typography variant={"titleLarge"}>{title}</Typography>
            )}
          </div>
          {showProgress && (
            <Typography variant={"titleMedium"}>
              <span style={{ color: "var(--text-subtitle)" }}>
                You're now at Step{" "}
                {selectedStepIndex ? selectedStepIndex : completedSteps + 1} of{" "}
                {totalSteps}
              </span>
            </Typography>
          )}
        </div>
        <div className={styles.spacer}></div>
        {showResumeButton && !isMobile && (
          <ActivityHeaderButton onClick={() => onResumeActivity?.()} />
        )}
        {showResumeButton && !isMobile && (
          <div
            style={{
              width: "2px",
              height: "24px",
              marginLeft: "24px",
              marginRight: "24px",
              background: "var(--surface-level-4, #DEDEE2)",
            }}
          ></div>
        )}
        <ProgressCircle
          start={progress.start}
          finish={progress.finish}
          onShowNav={() => setShowNav((prev: boolean) => !prev)}
        />
      </div>
      {isMobile && showResumeButton && (
        <div className={styles.mobileResume}>
          <ActivityHeaderButton onClick={() => onResumeActivity?.()} />
        </div>
      )}
    </>
  );
};
