import React, { ReactNode } from "react";
import styles from "./Button.module.css";
import { classNames } from "primereact/utils";
import { Spinner } from "./Spinner";

export interface ButtonProps {
  label?: string;
  children?: ReactNode;
  type?: ButtonType;
  size?: ButtonSize;
  disabled?: boolean;
  icon?: string | React.ReactNode;
  iconPlacement?: "left" | "right";
  className?: string;
  onClick?: () => void;
}

export const ButtonType = {
  primaryFilled: "PRIMARY_FILLED",
  primaryOutlined: "PRIMARY_OUTLINED",
  secondaryFilled: "SECONDARY_FILLED",
  secondaryOutlined: "SECONDARY_OUTLINED",
} as const;

export type ButtonType = (typeof ButtonType)[keyof typeof ButtonType];

export const ButtonSize = {
  small: "SMALL",
  medium: "MEDIUM",
  large: "LARGE",
  auto: "AUTO",
} as const;

export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize];

export const Button: React.FC<ButtonProps> = ({
  label,
  type = ButtonType.primaryFilled,
  size = ButtonSize.large,
  disabled = false,
  className,
  icon,
  iconPlacement = "left",
  children,
  onClick,
}) => {
  const renderedIcon =
    typeof icon === "string" ? (
      <i className={classNames(icon, styles.icon)} />
    ) : (
      <span className={styles.icon}>{icon}</span>
    );
  return (
    <button
      className={classNames(
        styles.button,
        styles[type],
        styles[size],
        disabled && styles.disabled,
        className,
      )}
      aria-label={label}
      onClick={() => !disabled && onClick?.()}
    >
      {iconPlacement == "left" && icon && renderedIcon}
      {label && <span>{label}</span>}
      {iconPlacement == "right" && icon && renderedIcon}
      {children}
    </button>
  );
};

export const SpinnerButton: React.FC<{ type?: ButtonType }> = ({
  type = ButtonType.primaryFilled,
}) => {
  const color = (() => {
    if (type == ButtonType.primaryFilled) {
      return "var(--text-button-1)";
    }
    if (type == ButtonType.primaryOutlined) {
      return "var(--text-primary)";
    }
    if (type == ButtonType.secondaryFilled) {
      return "var(--text-button-1)";
    }
    if (type == ButtonType.secondaryOutlined) {
      return "var(--text-button-2)";
    }
  })();
  return <Button type={type} icon={<Spinner color={color} />} />;
};
