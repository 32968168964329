import {
  ActiveSessionStateSummaryOutput,
  ActivityStateSummary,
  LessonNav,
} from "@arena-active/trpc-client";
import { SectionItem } from "../nav/NavSection";
import { ActivityType } from "../nav/types";
import { ResponseType } from "../layout/types";

export const getCurrentSessionIndex = (
  sessions: LessonNav["sessions"][number][],
  activities: ActivityStateSummary[],
) => {
  // Create a map of existing activity states for quick lookup
  const activityStateMap = new Map<number, ActivityStateSummary>();
  activities.forEach((activity) =>
    activityStateMap.set(activity.activityId, activity),
  );

  // Augment activities array with missing activities from sessions
  const augmentedActivities = sessions.flatMap((session) =>
    session.activities.map(
      (activity) =>
        activityStateMap.get(activity.id) || {
          id: activity.id,
          activityId: activity.id,
          sessionId: session.id,
          completed: false,
        },
    ),
  );

  for (let i = 0; i < sessions.length; i++) {
    const sessionActivities = augmentedActivities.filter(
      (activity) => activity.sessionId === sessions[i].id,
    );

    if (sessionActivities.some((activity) => !activity.completed)) {
      return i;
    }
  }

  return sessions.length - 1;
};

export const getCurrentSession = (
  lesson: LessonNav,
  activeSessionState: ActiveSessionStateSummaryOutput,
): LessonNav["sessions"][number] | null => {
  if (!lesson) return null;
  const sessionIndex = getCurrentSessionIndex(
    lesson.sessions,
    activeSessionState.activities,
  );
  return lesson.sessions[sessionIndex] || null;
};

export const isActivityLocked = (
  activityId: number,
  activeSessionState: ActiveSessionStateSummaryOutput,
): boolean => {
  const activityIndex = activeSessionState.activities.findIndex(
    (activity: ActivityStateSummary) => activity.activityId === activityId,
  );

  if (activityIndex === -1) return true; // If activity state is missing, treat it as locked

  return (
    activityIndex !== 0 &&
    !activeSessionState.activities[activityIndex - 1]?.completed
  );
};

export const isCurrentSession = (
  sessionId: number,
  currentSessionIndex: number,
  lesson: LessonNav,
) => {
  if (currentSessionIndex === -1) {
    return false;
  }
  return lesson.sessions[currentSessionIndex].id === sessionId;
};

export const getActivityState = (
  activityId: number,
  activities: ActivityStateSummary[],
): ActivityStateSummary | null => {
  return (
    activities.find((activity) => activity.activityId === activityId) || null
  );
};

export const getCurrentActivity = (
  sessions: LessonNav["sessions"][number][],
  activities: ActivityStateSummary[],
) => {
  const sessionIndex = getCurrentSessionIndex(sessions, activities);

  const currentSession = sessions[sessionIndex];
  if (!currentSession) {
    return null;
  }

  const sessionActivityIds = activities
    .filter((activity) => activity.sessionId === currentSession.id)
    .map((activity) => activity.activityId);

  const incompleteActivityIds = currentSession.activities
    .filter(
      (activity) =>
        !sessionActivityIds.includes(activity.id) ||
        !activities.find((a) => a.activityId === activity.id)?.completed,
    )
    .map((activity) => activity.id);

  const currentActivityDetails = currentSession.activities.find(
    (activityDetail) => incompleteActivityIds.includes(activityDetail.id),
  );

  return currentActivityDetails || null;
};

export const isCurrentActivityFirstActivityInSession = (
  lesson: LessonNav,
  activeSessionState: ActiveSessionStateSummaryOutput,
): boolean => {
  const currentActivity = getCurrentActivity(
    lesson.sessions,
    activeSessionState.activities,
  );
  return isFirstActivityInSession(lesson, currentActivity?.id || -1);
};

export const isFirstActivityInSession = (
  lesson: LessonNav,
  activityId: number,
): boolean => {
  for (const session of lesson.sessions) {
    if (
      session.activities.length > 0 &&
      session.activities[0].id === activityId
    ) {
      return true;
    }
  }
  return false;
};

export const isLastActivityInSession = (
  lesson: LessonNav,
  activityId: number,
): boolean => {
  for (const session of lesson.sessions) {
    if (
      session.activities.length > 0 &&
      session.activities[session.activities.length - 1].id === activityId
    ) {
      return true;
    }
  }
  return false;
};

/**
 * Gets the user-facing position for the activity in lesson
 * (starts at 1)
 * @param lesson lesson state
 * @param activityId
 * @returns
 */
export const getActivityPositionInLesson = (
  lesson: LessonNav,
  activityId: number,
) => {
  let position = 0;

  for (const session of lesson.sessions) {
    for (const activity of session.activities) {
      position++;
      if (activity.id === activityId) {
        return position;
      }
    }
  }
  return -1;
};

export const getActivityType = (type: string): ActivityType => {
  // TODO - remove the below, temporary fix while actityType changes
  if (type === "LessonChunk") {
    return ActivityType.Video;
  }
  if (type === "Exercise") {
    return ActivityType.Exercise;
  }

  if ((Object.values(ActivityType) as string[]).includes(type)) {
    return type as ActivityType;
  }
  throw new Error(`Invalid activity type: ${type}`);
};

export const getResponseType = (type: string | null): ResponseType => {
  if (!type) {
    return ResponseType.None;
  }
  if ((Object.values(ResponseType) as string[]).includes(type)) {
    return type as ResponseType;
  }
  throw new Error(`Invalid activity type: ${type}`);
};

export const activityToSectionItem = (
  lesson: LessonNav,
  activities: ActiveSessionStateSummaryOutput["activities"],
  activity: LessonNav["sessions"][number]["activities"][number],
  selectedActivityId: number | null,
): SectionItem => {
  return {
    id: activity.id,
    title: activity.title ? activity.title : activity.text ? activity.text : "",
    lessonPosition: getActivityPositionInLesson(lesson, activity.id),
    activityType: getActivityType(activity.activityType ?? "video"),
    responseType: getResponseType(activity.responseType),
    isCurrent:
      (selectedActivityId !== null && selectedActivityId === activity.id) ||
      (selectedActivityId == null &&
        getCurrentActivity(lesson.sessions, activities)?.id === activity.id),
    isComplete: activities.some(
      (a) => a.activityId === activity.id && a.completed,
    ),
    isFirstInSection: isFirstActivityInSession(lesson, activity.id),
    isLastInSection: isLastActivityInSession(lesson, activity.id),
    isSectionHasTitle: true,
  };
};

export const isSessionAvailable = (
  sessionIndex: number,
  lesson: LessonNav,
  activities: ActivityStateSummary[],
): boolean => {
  if (sessionIndex === 0) {
    return true;
  }

  const previousSession = lesson.sessions[sessionIndex - 1];
  if (!previousSession) {
    return false;
  }

  return previousSession.activities.every((activity) =>
    activities.some((a) => a.activityId === activity.id && a.completed),
  );
};

export const getProgress = (
  sessions: LessonNav["sessions"][number][],
  state: ActivityStateSummary[],
): { totalActivities: number; completedActivities: number } => {
  const totalActivities = sessions.reduce((total, session) => {
    return total + session.activities.length;
  }, 0);

  const completedActivities = state.reduce((count, activityState) => {
    if (activityState.completed) {
      const activityExists = sessions.some((session) =>
        session.activities.some(
          (activity) => activity.id === activityState.activityId,
        ),
      );
      if (activityExists) {
        count++;
      }
    }
    return count;
  }, 0);

  return { totalActivities, completedActivities };
};
