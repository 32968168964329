import { FC } from "react";

export const ReplayIcon: FC<{ className?: string; onClick: () => void }> = ({
  className,
  onClick,
}) => (
  <svg
    className={className}
    onClick={onClick}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.0002 58.6666C46.7278 58.6666 58.6668 46.7275 58.6668 31.9999C58.6668 17.2723 46.7278 5.33325 32.0002 5.33325C17.2726 5.33325 5.3335 17.2723 5.3335 31.9999C5.3335 46.7275 17.2726 58.6666 32.0002 58.6666ZM42.4925 14.9108C43.2456 15.2176 43.738 15.9498 43.738 16.763V24.0017C43.738 25.1063 42.8425 26.0017 41.738 26.0017H34.6669C33.8622 26.0017 33.1359 25.5195 32.8236 24.7779C32.5114 24.0363 32.6739 23.1798 33.2362 22.6042L35.2668 20.5254C31.4122 19.3371 27.0581 20.3013 24.0028 23.4292C19.5549 27.9825 19.5549 35.3839 24.0028 39.9373C28.4251 44.4645 35.5752 44.4645 39.9976 39.9373C41.8051 38.0869 42.8816 35.7632 43.2186 33.3452C43.3739 32.2306 44.2594 31.2931 45.3847 31.2931C46.4658 31.2931 47.3627 32.1586 47.2582 33.2347C46.922 36.6949 45.4591 40.0706 42.8589 42.7324C36.8673 48.8661 27.133 48.8661 21.1414 42.7324C15.1753 36.6249 15.1753 26.7416 21.1414 20.6341C25.7749 15.8907 32.6509 14.8123 38.3059 17.4142L40.3073 15.3655C40.8755 14.7837 41.7394 14.604 42.4925 14.9108Z"
      fill="white"
    />
  </svg>
);
