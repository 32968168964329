import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { getSystemTheme } from "../util";

export type Theme = "light" | "dark" | "system";
export const themeAtom = atomWithStorage<Theme>("theme", "system");

export const resolvedTheme = atom<"light" | "dark">((get) => {
  const atomTheme = get(themeAtom);
  const theme: "light" | "dark" =
    atomTheme === "system" ? getSystemTheme() : atomTheme;
  return theme;
});
