import { MainActivityProps } from "../layout/types";
import styles from "./InterstitialActivity.module.css";
import { Typography } from "@arena-active/client-lib";
import { Button } from "@arena-active/client-lib";

interface InterstitialConfig {
  title: string;
  content: string;
}

export interface InterstitialProps extends MainActivityProps {
  interstitialConfig: object;
}

export const InterstitialActivity: React.FC<InterstitialProps> = ({
  interstitialConfig,
  onComplete,
}) => {
  if (!interstitialConfig) {
    return <div>Error: No config provided.</div>;
  }

  const interstitialModel = interstitialConfig as InterstitialConfig;
  return (
    <div className={styles.interstitial}>
      <div>
        <div className={styles.interstitialTitle}>
          <Typography variant={"displaySmall"}>
            {interstitialModel.title}
          </Typography>
        </div>
        <div className={styles.interstitialContent}>
          <Typography variant={"headlineMedium"}>
            <span style={{ color: "var(--text-subtitle, #3F3F46)" }}>
              {interstitialModel.content}
            </span>
          </Typography>
        </div>
      </div>
      {/* {JSON.stringify(interstitialConfig)} */}
      <Button
        className={styles.continueButton}
        onClick={() => {
          onComplete ? onComplete() : undefined;
        }}
        label="Continue"
      />
    </div>
  );
};
