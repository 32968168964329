import { FC, SVGProps } from "react";

export const EditIcon = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H46C49.3137 0 52 2.68629 52 6V46C52 49.3137 49.3137 52 46 52H6C2.68629 52 0 49.3137 0 46V6Z"
      fill="#999999"
    />
    <g clipPath="url(#clip0_427_8009)">
      <path
        d="M41.1185 18.8769L38.2371 21.7583C37.9434 22.052 37.4684 22.052 37.1746 21.7583L30.2368 14.8205C29.9431 14.5267 29.9431 14.0517 30.2368 13.758L33.1182 10.8766C34.287 9.7078 36.1871 9.7078 37.3621 10.8766L41.1185 14.633C42.2936 15.8018 42.2936 17.7019 41.1185 18.8769ZM27.7617 16.2331L11.3486 32.6462L10.0235 40.2403C9.84226 41.2653 10.736 42.1528 11.7611 41.9778L19.3551 40.6465L35.7683 24.2334C36.0621 23.9396 36.0621 23.4646 35.7683 23.1708L28.8305 16.2331C28.5305 15.9393 28.0555 15.9393 27.7617 16.2331ZM17.7551 31.2399C17.4113 30.8961 17.4113 30.3461 17.7551 30.0024L27.3805 20.377C27.7242 20.0332 28.2742 20.0332 28.618 20.377C28.9618 20.7207 28.9618 21.2708 28.618 21.6145L18.9926 31.2399C18.6489 31.5837 18.0988 31.5837 17.7551 31.2399ZM15.4987 36.4964H18.4989V38.7652L14.4674 39.4715L12.5236 37.5277L13.2299 33.4962H15.4987V36.4964Z"
        fill="#FAFAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_427_8009">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const VideoIcon = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6C0 2.68629 2.68629 0 6 0H46C49.3137 0 52 2.68629 52 6V46C52 49.3137 49.3137 52 46 52H6C2.68629 52 0 49.3137 0 46V6Z"
      fill="#999999"
    />
    <path
      d="M28.6778 15.3334H12.6556C11.1889 15.3334 10 16.5223 10 17.9889V34.0112C10 35.4778 11.1889 36.6667 12.6556 36.6667H28.6778C30.1444 36.6667 31.3333 35.4778 31.3333 34.0112V17.9889C31.3333 16.5223 30.1444 15.3334 28.6778 15.3334ZM39.2 17.4278L33.1111 21.6278V30.3723L39.2 34.5667C40.3778 35.3778 42 34.55 42 33.1334V18.8612C42 17.45 40.3833 16.6167 39.2 17.4278Z"
      fill="#FAFAFA"
    />
  </svg>
);

export const CompletedIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_33_5149)">
      <path
        d="M12 6C12 9.31372 9.31372 12 6 12C2.68628 12 0 9.31372 0 6C0 2.68628 2.68628 0 6 0C9.31372 0 12 2.68628 12 6ZM5.30598 9.17695L9.7576 4.72534C9.90876 4.57418 9.90876 4.32907 9.7576 4.17791L9.21017 3.63048C9.05901 3.4793 8.8139 3.4793 8.66272 3.63048L5.03226 7.26092L3.33728 5.56594C3.18612 5.41478 2.94102 5.41478 2.78983 5.56594L2.2424 6.11337C2.09124 6.26453 2.09124 6.50964 2.2424 6.6608L4.75853 9.17693C4.90972 9.32811 5.1548 9.32811 5.30598 9.17695Z"
        fill="#43A047"
      />
    </g>
    <defs>
      <clipPath id="clip0_33_5149">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const FeedbackIcon = () => (
  <svg
    width="20"
    height="32"
    viewBox="0 0 20 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5005 10H11.288L13.9505 1.8875C14.2005 0.9375 13.4818 0 12.5005 0H3.50054C2.75054 0 2.11304 0.55625 2.01304 1.3L0.013039 16.3C-0.105711 17.2 0.594289 18 1.50054 18H8.91929L6.03804 30.1562C5.81304 31.1062 6.53804 32 7.49429 32C8.01929 32 8.51929 31.725 8.79429 31.25L19.7943 12.25C20.3755 11.2563 19.6568 10 18.5005 10Z"
      fill="#43A047"
    />
  </svg>
);

export const LessonIcon = () => (
  <svg
    width="35"
    height="32"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1177_42435)">
      <path
        d="M1.89529 0.171888C0.857698 0.171893 0.0174879 1.01457 0.0193524 2.05216C0.0232519 4.22232 0.0233239 6.39635 0.0194111 8.56651C0.0175403 9.60415 0.857775 10.4469 1.89541 10.4469C8.63861 10.4469 15.3818 10.4469 22.125 10.4469C23.1605 10.4469 24 9.60744 24 8.57191C24 6.3969 24 4.2219 24 2.04689C24 1.01136 23.1605 0.171899 22.125 0.171898C15.3818 0.171892 8.63853 0.171856 1.89529 0.171888Z"
        fill="white"
      />
      <path
        d="M12.1387 20.7434H22.9547C23.0798 20.7434 23.205 20.7544 23.3251 20.7899C23.4673 20.8318 23.6559 20.9013 23.749 20.9944C23.9097 21.1552 24 21.3732 24 21.6005V23.3148C24 23.5421 23.9097 23.7601 23.749 23.9209C23.5882 24.0816 23.3702 24.1719 23.1429 24.1719H12.1387C11.9113 24.1719 11.6933 24.0816 11.5326 23.9209C11.3718 23.7601 11.2815 23.5421 11.2815 23.3148V21.6005C11.2815 21.3732 11.3718 21.1552 11.5326 20.9944C11.6933 20.8337 11.9113 20.7434 12.1387 20.7434Z"
        fill="white"
      />
      <path
        d="M0.857159 13.8862H13.5663C13.7936 13.8862 14.0116 13.9765 14.1724 14.1373C14.3331 14.298 14.4234 14.516 14.4234 14.7434V16.4577C14.4234 16.685 14.3331 16.903 14.1724 17.0638C14.0116 17.2245 13.7936 17.3148 13.5663 17.3148H0.857159C0.629831 17.3148 0.411813 17.2245 0.251068 17.0638C0.0903224 16.903 1.65403e-05 16.685 1.65403e-05 16.4577V14.7434C1.65403e-05 14.516 0.0903224 14.298 0.251068 14.1373C0.411813 13.9765 0.629831 13.8862 0.857159 13.8862Z"
        fill="white"
      />
      <path
        d="M18.1787 13.8862H23.1352C23.3625 13.8862 23.5806 13.9765 23.7413 14.1373C23.902 14.298 23.9923 14.516 23.9923 14.7434V16.4577C23.9923 16.685 23.902 16.903 23.7413 17.0638C23.5806 17.2245 23.3625 17.3148 23.1352 17.3148H18.1787C17.9514 17.3148 17.7333 17.2245 17.5726 17.0638C17.4118 16.903 17.3215 16.685 17.3215 16.4577V14.7434C17.3215 14.516 17.4118 14.298 17.5726 14.1373C17.7333 13.9765 17.9514 13.8862 18.1787 13.8862Z"
        fill="white"
      />
      <path
        d="M0.857143 20.7434H7.46799C7.59314 20.7434 7.71834 20.7544 7.83837 20.7899C7.98059 20.8318 8.16917 20.9013 8.26227 20.9944C8.42302 21.1552 8.51332 21.3732 8.51332 21.6005V23.3148C8.51332 23.5421 8.42302 23.7601 8.26227 23.9209C8.10153 24.0816 7.88351 24.1719 7.65618 24.1719H0.857143C0.629814 24.1719 0.411797 24.0816 0.251051 23.9209C0.0903057 23.7601 0 23.5421 0 23.3148V21.6005C0 21.3732 0.0903057 21.1552 0.251051 20.9944C0.411797 20.8337 0.629814 20.7434 0.857143 20.7434Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1177_42435">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.171875)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Stop = () => (
  <svg height="23px" viewBox="0 -960 960 960" width="23px" fill="#EE4923">
    <path d="M240-320v-320q0-33 23.5-56.5T320-720h320q33 0 56.5 23.5T720-640v320q0 33-23.5 56.5T640-240H320q-33 0-56.5-23.5T240-320Z" />
  </svg>
);

export const Rec = () => (
  <svg height="16px" viewBox="0 -960 960 960" width="30px" fill="#EE4923">
    <path d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0 0q-134 0-227-93t-93-227q0-134 93-227t227-93q134 0 227 93t93 227q0 134-93 227t-227 93Z" />
  </svg>
);

export const NoteIcon: FC<{ className?: string }> = ({ className }) => (
  <svg
    width="32px"
    height="32px"
    viewBox="0 0 32 32"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0007 23.9997C16.737 23.9997 17.334 23.4027 17.334 22.6663V14.6663C17.334 13.93 16.737 13.333 16.0007 13.333C15.2643 13.333 14.6673 13.93 14.6673 14.6663V22.6663C14.6673 23.4027 15.2643 23.9997 16.0007 23.9997Z"
      fill="#3F3F46"
    />
    <path
      d="M17.334 10.6663C17.334 9.92996 16.737 9.33301 16.0007 9.33301C15.2643 9.33301 14.6673 9.92996 14.6673 10.6663C14.6673 11.4027 15.2643 11.9997 16.0007 11.9997C16.737 11.9997 17.334 11.4027 17.334 10.6663Z"
      fill="#3F3F46"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0007 3.99967C13.8127 3.99967 11.7653 4.58401 10.0016 5.60424C9.3642 5.97297 8.54857 5.75515 8.17984 5.11774C7.81112 4.48032 8.02893 3.66469 8.66635 3.29596C10.825 2.04726 13.3314 1.33301 16.0007 1.33301C24.1008 1.33301 30.6673 7.8995 30.6673 15.9997C30.6673 24.0998 24.1008 30.6663 16.0007 30.6663C7.90047 30.6663 1.33398 24.0998 1.33398 15.9997C1.33398 13.3304 2.04824 10.824 3.29694 8.66537C3.66566 8.02796 4.4813 7.81014 5.11871 8.17887C5.75613 8.54759 5.97395 9.36323 5.60522 10.0006C4.58499 11.7643 4.00065 13.8117 4.00065 15.9997C4.00065 22.6271 9.37323 27.9997 16.0007 27.9997C22.6281 27.9997 28.0006 22.6271 28.0006 15.9997C28.0006 9.37226 22.6281 3.99967 16.0007 3.99967Z"
      fill="#3F3F46"
    />
  </svg>
);

export const DangerCircleBrokenIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6C12.5523 6 13 6.44772 13 7V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V7C11 6.44772 11.4477 6 12 6Z"
    />
    <path d="M13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C10.359 3 8.82349 3.43825 7.50073 4.20343C7.02267 4.47997 6.41094 4.31661 6.13439 3.83855C5.85785 3.36049 6.02121 2.74876 6.49927 2.47222C8.11825 1.53569 9.99808 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 9.99808 1.53569 8.11825 2.47222 6.49927C2.74876 6.02121 3.36049 5.85785 3.83855 6.13439C4.31661 6.41094 4.47997 7.02267 4.20343 7.50073C3.43825 8.82349 3 10.359 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3Z"
    />
  </svg>
);
