import {
  IUploadTask,
  MultiPartUpload,
  StorageService,
  LargeBlobUploadProgressCallback,
  UploadTaskState,
} from "./types";

export type Part = {
  partNumber: number;
  size: number;
  bytesLoaded: number;
  retries: number;
};

type UploadJob = {
  targetPath: string;
  file: File;
  onProgress: LargeBlobUploadProgressCallback;
  bytesLoaded: number;
  bytesTotal: number;
  partSize: number;
  numberOfParts: number;
  parts: Part[];
  uploadTask: UploadTask;
  mpu: MultiPartUpload;
};

export const uploadLargeBlob =
  (service: StorageService) =>
  async (
    file: File,
    targetPath: string,
    bucket: string,
    onProgress: LargeBlobUploadProgressCallback,
  ): Promise<string> => {
    const MB = 1024 * 1024;
    // const GB = MB * 1024;

    // const AWS_MIN_PART_SIZE = 5 * MB;
    const AWS_MIN_PART_NUMBER = 1;
    const AWS_MAX_PART_NUMBER = 10000;

    const MAX_RETRIES = 5;
    const DEFAULT_PART_SIZE = 10 * MB;

    const uploadJob = await createUploadJob(
      file,
      targetPath,
      bucket,
      onProgress,
    );

    return Promise.all(
      uploadJob.parts.map(async (p) => {
        while (
          p.retries < MAX_RETRIES &&
          uploadJob.uploadTask.state === "running"
        ) {
          try {
            await new Promise((resolve) =>
              setTimeout(resolve, 10000 * p.retries),
            );
            const url = await getPartUploadUrl(uploadJob, p);
            await doUpload(uploadJob, p, url);
            return;
          } catch (err) {
            console.error("Error uploading part", err, p);
            p.retries++;
          }
        }
        if (uploadJob.uploadTask.state === "canceled") {
          throw new UploadCanceledError("Upload canceled", "CANCELED");
        }
        uploadJob.uploadTask.state = "error";
        throw new Error("Error uploading");
      }),
    )
      .then(() => completeMultiPartUpload(uploadJob))
      .catch((err) => {
        abortMultiPartUpload(uploadJob);
        throw err;
      });

    // ----------------------------------------

    async function createUploadJob(
      file: File,
      targetPath: string,
      bucket: string,
      onProgress: LargeBlobUploadProgressCallback,
    ): Promise<UploadJob> {
      const partSize = calcPartSize(file);
      const numberOfParts = Math.ceil(file.size / partSize);
      // const targetPathFolder = targetPath.split("/").slice(0, -1).join("/");
      const uploadTask = new UploadTask();

      return {
        targetPath,
        file,
        onProgress,
        bytesLoaded: 0,
        bytesTotal: file.size,
        partSize,
        numberOfParts,
        parts: createParts(numberOfParts, file.size, partSize),
        uploadTask,
        mpu: await createMultiPartUpload(targetPath, file.type, bucket),
      };
    }

    function doUpload(uploadJob: UploadJob, part: Part, uploadUrl: string) {
      const start = (part.partNumber - 1) * uploadJob.partSize;
      const blob = uploadJob.file.slice(start, start + part.size);
      part.bytesLoaded = 0;
      return uploadBlob(uploadUrl, blob, uploadJob.uploadTask, (loaded) => {
        part.bytesLoaded = loaded || 0;
        uploadJob.bytesLoaded = uploadJob.parts.reduce(
          (acc, p) => (acc += p.bytesLoaded),
          0,
        );
        uploadJob.onProgress(
          uploadJob.bytesLoaded,
          uploadJob.bytesTotal,
          uploadJob.uploadTask,
        );
      });
    }

    async function uploadBlob(
      uploadUrl: string,
      blob: Blob,
      uploadTask: UploadTask,
      onProgress: (loaded: number, total: number) => void,
    ) {
      try {
        return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", uploadUrl);

          xhr.upload.onprogress = (evt) => {
            if (uploadTask.state !== "running") {
              xhr.abort();
            }
            onProgress(evt.loaded, evt.total);
          };

          xhr.onreadystatechange = () => {
            if (uploadTask.state === "canceled") {
              reject(new UploadCanceledError("Upload canceled", "CANCELED"));
            } else if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                resolve(uploadUrl.split("?")[0]);
              } else {
                logAndSendError(xhr, "unexpected status:" + xhr.status);
                reject({ message: "unexpected status:" + xhr.status });
              }
            }
          };
          xhr.send(blob);
        });
      } catch (e) {
        logAndSendError(e, "error uploading file");
        throw e;
      }
    }

    function logAndSendError(e: unknown, message: string) {
      console.error(message, e);
    }

    async function createMultiPartUpload(
      targetPath: string,
      contentType: string,
      bucket: string,
    ): Promise<MultiPartUpload> {
      try {
        const response = await service.createMultiPartUpload(
          targetPath,
          contentType,
          bucket,
        );
        return response;
      } catch (error) {
        console.error("createMultiPartUpload", error);
        throw error;
      }
    }

    async function getPartUploadUrl(
      upload: UploadJob,
      part: Part,
    ): Promise<string> {
      try {
        const response = await service.getPartUploadUrl(
          upload.mpu,
          part.partNumber,
        );
        console.info("getPartUploadUrl", response);
        return response;
      } catch (error) {
        console.error("getPartUploadUrl", error);
        throw error;
      }
    }

    async function completeMultiPartUpload(upload: UploadJob): Promise<string> {
      try {
        const response = await service.completeMultiPartUpload(upload.mpu);
        console.info("completeMultiPartUpload", response);
        const url = response;
        return url;
      } catch (error) {
        console.error("completeMultiPartUpload", error);
        throw error;
      }
    }

    async function abortMultiPartUpload(upload: UploadJob): Promise<void> {
      try {
        const response = await service.abortMultiPartUpload(upload.mpu);
        console.info("abortMultiPartUpload", response);
      } catch (error) {
        console.error("abortMultiPartUpload", error);
      }
    }

    function createParts(
      numberOfParts: number,
      totalSize: number,
      partSize: number,
    ) {
      const parts = [];
      for (let i = AWS_MIN_PART_NUMBER; i <= numberOfParts; i++) {
        const part = {
          partNumber: i,
          retries: 0,
          size: partSize,
          bytesLoaded: 0,
        };
        parts.push(part);
      }
      parts[parts.length - 1].size =
        totalSize % partSize ? totalSize % partSize : partSize;
      return parts;
    }

    function calcPartSize(file: File): number {
      let partSize = DEFAULT_PART_SIZE;
      if (file.size / AWS_MAX_PART_NUMBER > partSize) {
        partSize = Math.ceil(file.size / AWS_MAX_PART_NUMBER);
      }
      return partSize;
    }
  };

class UploadCanceledError extends Error {
  code = "";

  constructor(msg: string, code: string) {
    super(msg);
    this.code = code;
  }
}

class UploadTask implements IUploadTask {
  state: UploadTaskState = "running";

  cancel() {
    this.state = "canceled";
  }
}
