import { motion, AnimatePresence } from "framer-motion";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { mainAnimationTypeAtom } from "../layout/atoms";
import { ActivityType } from "../layout/types";
import { WebPageActivity } from "../activity/WebPageActivity";
import { VideoPlayerActivity } from "../activity/VideoPlayerActivity";
import { SingleResponseExerciseActivity } from "../activity/SingleResponseExerciseActivity";
import {
  Activity,
  ActivityState,
  LessonPageActivity,
} from "@arena-active/trpc-client";
import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { interstitialsAtom, studentIdAtom } from "#lib/state/atoms.js";
import { InterstitialActivity as InterstitialActivityComponent } from "#lib/activity/InterstitialActivity.js";
import { showHeaderAtom } from "../layout/atoms";
import { UploadFilesActivity } from "#lib/activity/UploadFilesActivity.js";

export interface ActivityViewerProps {
  activity?: Activity;
  state?: ActivityState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onComplete?: ((args?: any, isComplete?: boolean) => any) | undefined;
}

export interface Feedback {
  evaluationFeedback: string | null;
  evaluationRating: string | null;
}

export interface Response {
  responseText: string | null;
  audioStorageKey: string | null;
  videoStorageKey: string | null;
}

export const ActivityViewer: React.FC<ActivityViewerProps> = ({
  activity,
  state,
  onComplete,
}) => {
  const mainAnimationVariants = {
    fade: {
      start: { opacity: 0, scale: 0.8 },
      enter: {
        opacity: 1,
        scale: 1,
        transition: { duration: 0.6, ease: "easeOut" },
      },
      exit: {
        opacity: 0,
        scale: 0.5,
        transition: { duration: 0.6, ease: "easeIn" },
      },
    },
    slide: {
      start: { x: "100%" },
      enter: {
        x: 0,
        transition: { duration: 0.5, type: "spring", stiffness: 80 },
      },
      exit: {
        x: "-100%",
        transition: { duration: 0.5, type: "spring", stiffness: 80 },
      },
    },
  };

  const componentRef = useRef<HTMLDivElement>(null);
  const makeActivityVisible = () => {
    if (componentRef.current) {
      const rect = componentRef.current.getBoundingClientRect();
      const isTopVisible = rect.top >= 0 && rect.top < window.innerHeight;
      if (!isTopVisible) {
        componentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  };

  const setShowHeader = useSetAtom(showHeaderAtom);

  const isActivityComplete = !!(
    (activity?.states?.length || 0) > 0 &&
    activity?.states.find((state) => state.completed)
  );

  useEffect(() => {
    if (
      activity?.activityType === ActivityType.LessonPage &&
      activity.isFinalPage
    ) {
      setShowHeader(false);
    } else {
      setShowHeader(true);
    }
  }, [activity, setShowHeader]);

  const userId = useAtomValue(studentIdAtom);

  const [seenInterstitials, setSeenInterstitials] =
    useAtom<number[]>(interstitialsAtom);

  const [currentPhase, setCurrentPhase] = useState<
    "beginInterstitial" | "mainActivity" | "endInterstitial" | null
  >(null);

  const isInterstitialSeen = useCallback(
    (interstitial: Activity["interstitials"][0]) =>
      seenInterstitials.includes(interstitial.id),
    [seenInterstitials],
  );

  const shouldShowInterstitial = useCallback(
    (interstitial: Activity["interstitials"][0] | undefined) => {
      if (isActivityComplete) return false;
      if (!interstitial) return false;
      if (!isInterstitialSeen(interstitial)) return true;
      if (!interstitial.hideWhenSeen) return true;
      return false;
    },
    [isInterstitialSeen],
  );

  const beginInterstitial = activity?.interstitials?.find(
    (i) => i.isStart && shouldShowInterstitial(i),
  );
  const endInterstitial = activity?.interstitials?.find(
    (i) => !i.isStart && shouldShowInterstitial(i),
  );

  useEffect(() => {
    if (beginInterstitial) {
      setCurrentPhase("beginInterstitial");
    } else {
      setCurrentPhase("mainActivity");
    }
  }, [activity, beginInterstitial]);

  const addSeenInterstitial = useCallback(
    (id: number) => {
      setSeenInterstitials((prev: number[]) => {
        if (!prev.includes(id)) {
          return [...prev, id];
        }
        return prev;
      });
    },
    [setSeenInterstitials],
  );

  const handleBeginInterstitialComplete = useCallback(() => {
    if (beginInterstitial) {
      addSeenInterstitial(beginInterstitial.id);
    }
    setCurrentPhase("mainActivity");
  }, [beginInterstitial, addSeenInterstitial]);

  const handleMainActivityComplete = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (response?: any, isComplete?: boolean) => {
      if (isComplete !== false && endInterstitial) {
        setCurrentPhase("endInterstitial");
      } else {
        return onComplete?.(response, isComplete);
      }
    },
    [endInterstitial, onComplete],
  );

  const handleEndInterstitialComplete = useCallback(async () => {
    if (endInterstitial) {
      addSeenInterstitial(endInterstitial.id);
    }
    await onComplete?.();
  }, [endInterstitial, addSeenInterstitial, onComplete]);

  const animationType = useAtomValue(mainAnimationTypeAtom);

  let content: ReactNode = null;

  if (!activity) {
    return null; // Or render a loading/error state
  }

  if (currentPhase === "beginInterstitial" && beginInterstitial) {
    content = (
      <InterstitialActivityComponent
        interstitialConfig={beginInterstitial.model as object}
        activity={activity}
        isComplete={true}
        onComplete={handleBeginInterstitialComplete}
      />
    );
  } else if (currentPhase === "mainActivity") {
    switch (activity.activityType) {
      case ActivityType.LessonPage:
        content = (
          <WebPageActivity
            enableDynamicHeight
            frameUrl={(activity.config as LessonPageActivity).pageUrl}
            isComplete={isActivityComplete}
            onComplete={handleMainActivityComplete}
          />
        );
        break;
      case ActivityType.Video:
        content = (
          <VideoPlayerActivity
            activity={activity}
            isComplete={isActivityComplete}
            onComplete={handleMainActivityComplete}
          />
        );
        break;
      case ActivityType.Exercise:
        content = (
          <SingleResponseExerciseActivity
            activity={activity}
            state={state}
            isComplete={isActivityComplete}
            onComplete={handleMainActivityComplete}
          />
        );
        break;
      case ActivityType.UploadFiles:
        content = (
          <UploadFilesActivity
            activity={activity}
            userId={userId}
            isComplete={isActivityComplete}
            onComplete={handleMainActivityComplete}
          />
        );
        break;
      default:
        content = <div>Unknown Activity: {activity.type}</div>;
    }
  } else if (currentPhase === "endInterstitial" && endInterstitial) {
    content = (
      <InterstitialActivityComponent
        interstitialConfig={endInterstitial.model as object}
        activity={activity}
        isComplete={true}
        onComplete={handleEndInterstitialComplete}
      />
    );
  }

  return (
    <AnimatePresence mode="wait">
      {activity && content && (
        <motion.div
          ref={componentRef}
          key={`activity-${activity.id}-phase-${currentPhase}`}
          variants={mainAnimationVariants[animationType]}
          initial="start"
          animate="enter"
          exit="exit"
          onAnimationStart={makeActivityVisible}
        >
          {content}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
