import React, { useEffect, useState } from "react";
import aiAnimationDefault from "./ai-bot-default.json";
import aiAnimationThinking from "./ai-bot-thinking.json";
import aiAnimationCompleted from "./ai-bot-completed.json";
import styles from "./AiBot.module.css";
import Lottie from "lottie-react";
import { classNames } from "primereact/utils";
import { useMediaQuery } from "react-responsive";

export enum AiBotMode {
  DEFAULT = 0,
  THINKING,
  COMPLETED,
}

const animations = {
  [AiBotMode.DEFAULT]: aiAnimationDefault,
  [AiBotMode.THINKING]: aiAnimationThinking,
  [AiBotMode.COMPLETED]: aiAnimationCompleted,
};
export const AiBotAnimation: React.FC<{
  mode: AiBotMode;
  width?: number;
  height?: number;
  className?: string;
}> = ({ className, width = 98, height = 88, mode = AiBotMode.DEFAULT }) => {
  const [completedFinished, setCompletedFinished] = useState<boolean>(false);

  const isMobile = useMediaQuery({ maxWidth: 961 });
  width = isMobile ? 71 : width;
  height = isMobile ? 64 : height;

  useEffect(() => {
    setCompletedFinished(false);
  }, [mode]);

  return (
    <div className={className}>
      {mode === AiBotMode.COMPLETED ? (
        <Lottie
          animationData={
            !completedFinished
              ? animations[AiBotMode.COMPLETED]
              : animations[AiBotMode.DEFAULT]
          }
          style={{ width, height }}
          loop={completedFinished}
          onComplete={() => setCompletedFinished(true)}
        />
      ) : (
        <Lottie
          animationData={animations[mode]}
          style={{ width, height }}
          loop={true}
        />
      )}
    </div>
  );
};

export const AiBot: React.FC<{
  mode?: AiBotMode;
  name?: string;
  status?: string;
  className?: string;
}> = ({
  mode = AiBotMode.DEFAULT,
  name = "Edison",
  status = "AI Coach",
  className,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <AiBotAnimation mode={mode} />
      <div className={styles.nameAndRole}>
        <div className={styles.name}>{name}</div>
        <div className={styles.role}>
          {status}
          {mode == AiBotMode.THINKING && " Generating feedback..."}
        </div>
      </div>
    </div>
  );
};
