import { ErrorIcon } from "./Icons";
import styles from "./ToastErrorNotification.module.css";

export { styles };

export const ToastErrorNotification: React.FC = () => (
  <div className={styles.toastContent}>
    <ErrorIcon />
    <div>
      <div className={styles.toastTitle}>Oops! Something Went Wrong</div>
      <div className={styles.toastDesc}>
        An error occurred. Please try again to continue your journey.
      </div>
    </div>
  </div>
);
