/** A file upload event target so we can pick up events
 * and interact with the upload
 * instead of waiting for it.
 */

import { UploadBackend } from "../storage-client";

export interface UploadBlob extends Blob {
  readonly name: string;
}

export interface UploadTarget {
  filename: string;
  size: number;
  progress: number;
  removeAllListeners(): void;
  status: "idle" | "uploading" | "error" | "success";
  error?: string;
  stop(): Promise<boolean>;
  start(): void;
  on<T extends EventType>(
    type: T,
    listener: (e: CustomEvent<EventTypeToDetailMap[T]>) => void,
  ): void;
}

/**
 * A target that represents a completed upload.
 */
export class CompleteUploadTarget implements UploadTarget {
  constructor(
    readonly filename: string,
    readonly size: number,
  ) {}
  error?: string | undefined;

  get progress() {
    return this.size;
  }

  get status(): "success" {
    return "success";
  }

  stop(): Promise<boolean> {
    return Promise.resolve(true);
  }

  start() {}

  removeAllListeners(): void {}

  on() {}
}

/** The base implementation */
export class AbstractUploadTarget extends EventTarget implements UploadTarget {
  private controller = new AbortController();

  protected _status: "idle" | "uploading" | "error" | "success" = "idle";

  get filename() {
    return this.file.name;
  }

  get size() {
    return this.file.size;
  }

  get status() {
    return this._status;
  }

  protected _progress: number;

  get progress() {
    return this._progress;
  }

  protected _total: number;

  get total() {
    return this._total;
  }

  protected _error?: string;

  get error() {
    return this._error;
  }

  constructor(readonly file: UploadBlob) {
    super();
    this._progress = 0;
    this._total = 0;
    this._error = undefined;
  }

  on<T extends EventType>(
    type: T,
    listener: (e: CustomEvent<EventTypeToDetailMap[T]>) => void,
  ): void {
    return this.addEventListener(
      type,
      listener as EventListenerOrEventListenerObject,
      { signal: this.controller.signal },
    );
  }

  removeAllListeners() {
    this.controller.abort();
  }

  /**
   * Stops the upload.
   * If it's completed, it will remove the file from the bucket.
   * If it's not completed, it will abort the upload.
   */
  stop(): Promise<boolean> {
    return Promise.reject(new Error("Not Implemented"));
  }

  emit<T extends EventType>(type: T, detail: EventTypeToDetailMap[T]) {
    const event = new CustomEvent(type, { detail });
    return this.dispatchEvent(event);
  }

  start(): void {
    throw new Error("Method not implemented.");
  }
}

export type EventType = keyof EventTypeToDetailMap;

export type EventTypeToDetailMap = {
  error: { filename: string; error: string };
  success: { filename: string; url: string };
  progress: { filename: string; progress: number; total: number };
};

export class XHRTarget extends AbstractUploadTarget {
  private xhr: XMLHttpRequest | null = null;

  constructor(
    readonly file: UploadBlob,
    readonly url: string,
  ) {
    super(file);
  }

  override start(): void {
    if (this.xhr) {
      this.xhr.abort();
      this.xhr = null;
    }

    this._progress = 0;
    this._total = this.file.size;
    this._error = undefined;
    this._status = "uploading";

    try {
      this.xhr = new XMLHttpRequest();
      this.xhr.open("PUT", this.url);

      this.xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          this._progress = event.loaded;
          this._total = event.total;

          this.emit("progress", {
            filename: this.file.name,
            progress: event.loaded,
            total: event.total,
          });
        }
      };

      this.xhr.onload = () => {
        if (this.xhr!.status === 200) {
          this._status = "success";
          this.emit("success", {
            filename: this.file.name,
            url: this.url.split("?")[0],
          });
        } else {
          this._status = "error";
          this._error = `Upload failed with status: ${this.xhr!.status}`;
          this.emit("error", {
            filename: this.file.name,
            error: this._error,
          });
        }
      };

      this.xhr.onerror = () => {
        this._status = "error";
        this._error = "Network Error";
        this.emit("error", {
          filename: this.file.name,
          error: this._error,
        });
      };

      this.xhr.setRequestHeader("Content-Type", this.file.type);
      this.xhr.send(this.file);
    } catch (error) {
      console.error("[XHRTarget] error:", error);
      this._status = "error";
      this._error = `Error: ${(error as Error).message}`;
      this.emit("error", {
        filename: this.file.name,
        error: this._error,
      });
    }
  }

  override stop() {
    return new Promise<boolean>((resolve) => {
      this._status = "idle";
      this._error = undefined;
      if (this.xhr) {
        this.xhr.abort();
        this.xhr = null;
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
}

export class UploadTargetWithBackend extends AbstractUploadTarget {
  private base: XHRTarget | undefined;

  constructor(
    readonly file: UploadBlob,
    private backend: UploadBackend,
  ) {
    super(file);
  }

  get status() {
    return this.base ? this.base.status : "idle";
  }

  get error() {
    return this.base ? this.base.error : undefined;
  }

  start(): void {
    // If we have a base, then we're already uploading, so ignore the request
    if (this.base) {
      return;
    }

    this.backend
      .getSignedUrl(this.file.name)
      .then((url) => {
        this.base = new XHRTarget(this.file, url);

        this.base.on("progress", (event) =>
          this.emit("progress", event.detail),
        );
        this.base.on("success", (event) => this.emit("success", event.detail));
        this.base.on("error", (event) => this.emit("error", event.detail));
        this.base.start();
      })
      .catch((e) => {
        console.error("[SimpleUploadTarget] error:", e);
        this._status = "error";
        this._error = `error getting signed url: ${e}`;
        this.emit("error", {
          filename: this.file.name,
          error: this._error,
        });
      });
  }

  override stop() {
    return new Promise<boolean>((resolve) => {
      this._status = "idle";
      this._error = undefined;
      if (this.base) {
        return this.base
          .stop()
          .then(() => {
            this.base = undefined;
            return true;
          })
          .catch(() => {
            this.base = undefined;
            return false;
          });
      } else {
        resolve(false);
      }
    });
  }
}
