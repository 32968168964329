import { AnalyticsBrowser } from "@segment/analytics-next";
import { useEffect, useState } from "react";

export const useSegmentAnalytics = (segmentKey: string) => {
  const [analytics, setAnalytics] = useState<AnalyticsBrowser>();

  useEffect(() => {
    const initializeAnalytics = async () => {
      if (!analytics && segmentKey) {
        const instance = new AnalyticsBrowser();
        await instance.load({ writeKey: segmentKey });
        setAnalytics(instance);
      } else {
        console.error("Missing Segment Key - analytics disabled");
      }
    };

    initializeAnalytics();
  }, [segmentKey, analytics]);

  return analytics;
};
