import { useEffect } from "react";
import { useUser } from "@clerk/clerk-react";
import { useAtomValue } from "jotai";
import { lessonLoadedAtom } from "#lib/state/atoms";
import { currentActivityLoadedAtom } from "#lib/state/atoms";
import { useSegmentAnalytics } from "@arena-active/client-lib";
import { identifySentryUser } from "@arena-active/client-lib";

/**
 * Sets up tracking with Segment for state changes in the app.
 * Hook only needs to be used once in the app.
 * To get access to the analytics object, use the `useSegmentAnalytics` hook.
 */
export const useInitializeStateTrackingAnalytics = () => {
  const segmentKey = import.meta.env.VITE_SEGMENT_KEY;
  const analytics = useSegmentAnalytics(segmentKey);

  const { user } = useUser();
  const currentActivity = useAtomValue(currentActivityLoadedAtom);
  const lesson = useAtomValue(lessonLoadedAtom);

  useEffect(() => {
    if (user) {
      analytics?.identify(user.id, {
        email: user.primaryEmailAddress?.emailAddress ?? "",
        fullName: user.fullName ?? "",
      });
      user.primaryEmailAddress?.emailAddress &&
        identifySentryUser(user.primaryEmailAddress.emailAddress);
    }
  }, [user, analytics]);

  useEffect(() => {
    if (currentActivity) {
      analytics?.track("Activity Loaded", {
        activityId: currentActivity.id,
        title: currentActivity.title,
      });

      if (currentActivity.isFinalPage) {
        analytics?.track("Final Page Loaded", {
          activityId: currentActivity.id,
        });
      }
    }
  }, [currentActivity, analytics]);

  useEffect(() => {
    if (lesson) {
      analytics?.track("Lesson Loaded", {
        lessonId: lesson.id,
        title: lesson.title,
      });
    }
  }, [lesson, analytics]);
};
