import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import styles from "./Spinner.module.css";

export const Spinner: React.FC<{ color?: string }> = ({ color }) => {
  return (
    <div className={styles.spinnerContainer}>
      <ProgressSpinner
        style={{ width: "24px", height: "24px" }}
        pt={{
          circle: {
            style: {
              stroke: color || "var(--text-primary)",
              strokeWidth: 6,
              animation: "none",
            },
          },
        }}
        strokeWidth="3"
        animationDuration="1s"
      />
    </div>
  );
};
