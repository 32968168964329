import { useEffect, useState } from "react";
import styles from "./VoiceAnimation.module.css";
import { random } from "lodash";

const calculateAverage = (bufferLength: number, dataArray: Uint8Array) => {
  let barHeight;

  const barHeightArray = [];
  for (let i = 0; i < 5; i++) {
    let sum = 0;
    const start = Math.floor(i * (bufferLength / 5));
    const end = Math.floor((i + 1) * (bufferLength / 5));

    for (let j = start; j < end; j++) {
      sum += dataArray[j];
    }

    barHeight = sum;
    const minBarHeight = 5;
    const maxBarHeight = 20;
    const scaledBarHeight =
      (barHeight / 255) * (maxBarHeight - minBarHeight) + minBarHeight;
    barHeightArray.push(scaledBarHeight);
  }
  return barHeightArray;
};

export interface VoiceAnimationProps {
  source: MediaRecorder | null;
}

export function VoiceAnimation({ source }: VoiceAnimationProps) {
  const [voiceLevel, setVoiceLevel] = useState([5, 5, 5, 5, 5]);

  useEffect(
    function setupAudioContext() {
      (async () => {
        if (source) {
          const audioCtx = new window.AudioContext();
          const distortion = audioCtx.createWaveShaper();
          const gainNode = audioCtx.createGain();
          const biquadFilter = audioCtx.createBiquadFilter();
          const analyser = audioCtx.createAnalyser();
          analyser.minDecibels = -90;
          analyser.maxDecibels = -10;

          analyser.fftSize = 1024;

          const node = audioCtx.createMediaStreamSource(source.stream);
          node.connect(distortion);
          distortion.connect(biquadFilter);
          biquadFilter.connect(gainNode);
          gainNode.connect(analyser);

          const interval = setInterval(() => {
            const bufferLength = analyser.frequencyBinCount;
            const dataArray = new Uint8Array(bufferLength);
            analyser.getByteFrequencyData(dataArray);
            const cuttedArray = dataArray.slice(0, 15);
            const aveg5 = calculateAverage(15, cuttedArray);
            setVoiceLevel(aveg5);
          }, 100);

          return function cleanupAudioContext() {
            clearInterval(interval);

            audioCtx.close();
          };
        }
      })();
    },
    [source],
  );

  return (
    <div className={styles.bars}>
      {Array(5)
        .fill(0)
        .map((_, i) => (
          <div
            key={i}
            style={{
              animationDuration: `${random(400, 500)}ms`,
              height: `${voiceLevel[i]}px`,
            }}
            className={styles.bar}
          />
        ))}
    </div>
  );
}
