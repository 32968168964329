import { FC, useCallback } from "react";
import styles from "./FileUpload.module.css";
import { Typography } from "../typography/Typography";
import { Button } from "../common/Button";
import { useDropzone } from "react-dropzone";
import { classNames } from "primereact/utils";
import { FileUploadPanel } from "./FileUploadPanel";
import { UploadTarget } from "../../storage";

const Header = () => (
  <div className={styles.header}>
    <Typography variant="bodyLarge">Upload File</Typography>
  </div>
);

interface OnDropProps {
  onDrop: (files: File[]) => void;
}

interface DropZoneProps extends OnDropProps {
  enabled: boolean;
  maxFiles?: number;
}

const DropZone: FC<DropZoneProps> = ({
  onDrop: handleDrop,
  enabled,
  maxFiles,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleDrop(acceptedFiles);
    },
    [handleDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const names = classNames(styles.dropZone, {
    [styles.dragActive]: isDragActive,
  });

  const rootProps = enabled ? getRootProps() : {};
  const inputProps = enabled ? getInputProps() : { style: { display: "none" } };
  const fileLabel = maxFiles === 1 ? "file" : "files";

  return (
    <div className={names} {...rootProps}>
      <input {...inputProps} />
      <i className={`pi pi-upload ${styles.icon}`}></i>
      <Typography
        variant="bodyMedium"
        color="subtitle"
        className={styles.dropLabel}
      >
        {enabled
          ? isDragActive
            ? `Drop file to upload`
            : `Drag and drop a file here or`
          : `You can only upload ${maxFiles} ${fileLabel}.`}
      </Typography>
      {enabled && (
        <Button label="Choose a File" type="PRIMARY_OUTLINED" size="SMALL" />
      )}
    </div>
  );
};

export interface FileUploadProps extends OnDropProps {
  maxFiles?: number;
  uploads: UploadTarget[];
  onRemove: (file: string) => void;
}

export const FileUpload: FC<FileUploadProps> = ({
  onDrop,
  uploads,
  onRemove,
  maxFiles,
}) => {
  const uploadEnabled = maxFiles === undefined || uploads.length < maxFiles;

  return (
    <div className={styles.fileUpload}>
      <Header />
      <DropZone onDrop={onDrop} maxFiles={maxFiles} enabled={uploadEnabled} />
      <div className={styles.uploadEntries}>
        {(uploads || []).map((u: UploadTarget) => {
          return (
            <FileUploadPanel
              className={styles.uploadEntry}
              target={u}
              key={u.filename}
              onRemove={onRemove}
            />
          );
        })}
      </div>
    </div>
  );
};
