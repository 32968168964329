import React, { SVGProps } from "react";
import { useAtomValue } from "jotai";
import { themeAtom, Theme } from "../../../theme/themeAtoms";
import { getSystemTheme } from "../../../util";

export interface ThemeSelectCardPreviewProps extends SVGProps<SVGSVGElement> {
  theme?: Theme;
}

export const ThemeSelectCardPreview: React.FC<ThemeSelectCardPreviewProps> = ({
  theme,
  ...svgProps
}) => {
  const currentTheme = useAtomValue(themeAtom);
  const systemTheme = getSystemTheme();

  const storedTheme = theme
    ? theme === "system"
      ? systemTheme
      : theme
    : currentTheme === "system"
    ? systemTheme
    : currentTheme;

  const foregroundColor = storedTheme === "light" ? "black" : "white";
  const backgroundColor = storedTheme === "light" ? "white" : "black";

  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...svgProps}
    >
      <rect width="100" height="100" fill={backgroundColor} />
      <path
        d="M11 30H59C60.6569 30 62 31.3431 62 33V49C62 50.6569 60.6569 52 59 52H11C9.34315 52 8 50.6569 8 49V33C8 31.3431 9.34315 30 11 30Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M87 18C84.2386 18 82 15.7614 82 13C82 10.2386 84.2386 8 87 8C89.7614 8 92 10.2386 92 13C92 15.7614 89.7614 18 87 18Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M8 13C8 10.2386 10.2386 8 13 8H26C28.7614 8 31 10.2386 31 13C31 15.7614 28.7614 18 26 18H13C10.2386 18 8 15.7614 8 13Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M68 13C68 10.2386 70.2386 8 73 8C75.7614 8 78 10.2386 78 13C78 15.7614 75.7614 18 73 18C70.2386 18 68 15.7614 68 13Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M8 59C8 57.3431 9.34315 56 11 56H59C60.6569 56 62 57.3431 62 59C62 60.6569 60.6569 62 59 62H11C9.34315 62 8 60.6569 8 59Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M8 69C8 67.3431 9.34315 66 11 66H59C60.6569 66 62 67.3431 62 69C62 70.6569 60.6569 72 59 72H11C9.34315 72 8 70.6569 8 69Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M8 79C8 77.3431 9.34315 76 11 76H36C37.6569 76 39 77.3431 39 79C39 80.6569 37.6569 82 36 82H11C9.34315 82 8 80.6569 8 79Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M8 89C8 87.3431 9.34315 86 11 86H36C37.6569 86 39 87.3431 39 89C39 90.6569 37.6569 92 36 92H11C9.34315 92 8 90.6569 8 89Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M43 79C43 77.3431 44.3431 76 46 76H59C60.6569 76 62 77.3431 62 79V89C62 90.6569 60.6569 92 59 92H46C44.3431 92 43 90.6569 43 89V79Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
      <path
        d="M66 33C66 31.3431 67.3431 30 69 30H89C90.6569 30 92 31.3431 92 33V89C92 90.6569 90.6569 92 89 92H69C67.3431 92 66 90.6569 66 89V33Z"
        fill={foregroundColor}
        fillOpacity="0.15"
      />
    </svg>
  );
};
