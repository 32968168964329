import { CSSProperties, useEffect, useRef, useState } from "react";
import { MainActivityProps } from "../layout/types";
import {
  getSystemTheme,
  SeparatorWithChildren,
  themeAtom,
} from "@arena-active/client-lib";
import { useAtomValue } from "jotai";
import { Button } from "@arena-active/client-lib";
import styles from "./WebPageActivity.module.css";

export interface WebPageActivityProps
  extends Omit<MainActivityProps, keyof Pick<MainActivityProps, "activity">> {
  frameUrl: string;
  enableDynamicWidth?: boolean;
  enableDynamicHeight?: boolean;
}

// window.addEventListener("message", (e) => console.log(e.data));

export const WebPageActivity: React.FC<WebPageActivityProps> = ({
  frameUrl,
  enableDynamicWidth,
  enableDynamicHeight,
  onComplete,
  isComplete,
}) => {
  const [loading, setLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const currentTheme = useAtomValue(themeAtom);
  const systemTheme = getSystemTheme();

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "content-load") {
        if (iframeRef.current) {
          if (enableDynamicWidth === true)
            iframeRef.current.width = `${event.data.width}`;
          if (enableDynamicHeight === true)
            iframeRef.current.height = `${event.data.height}`;
        }
      }

      if (
        event.data.type === "button-continue" &&
        event.data.message === "Continue clicked"
      ) {
        onComplete?.();
      }
    };
    window.addEventListener("message", handleMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [enableDynamicWidth, enableDynamicHeight, onComplete]);

  const theme = currentTheme === "system" ? systemTheme : currentTheme;

  if (!frameUrl) {
    return <div>Error: No URL provided.</div>;
  }

  const iFrameSrc = new URL(frameUrl);
  const params = new URLSearchParams(iFrameSrc.search);

  if (theme) {
    params.set("theme", theme);
  }

  iFrameSrc.search = params.toString();

  const handleLoad = () => {
    setLoading(false);
  };

  const wrapperStyle: CSSProperties = {
    position: "relative",
  };

  if (!enableDynamicWidth) wrapperStyle.width = "100%";
  if (!enableDynamicHeight) wrapperStyle.height = "100%";

  const iframeStyle: CSSProperties = {
    border: "none",
    overflow: "auto",
    visibility: loading ? "hidden" : "visible",
    backgroundColor: "var(--surface-level-1)",
  };

  if (!enableDynamicWidth) iframeStyle.width = "100%";
  if (!enableDynamicHeight) iframeStyle.height = "100%";

  return (
    <div style={wrapperStyle}>
      {loading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <p>Loading...</p>
        </div>
      )}
      <iframe
        scrolling="no"
        ref={iframeRef}
        src={iFrameSrc.toString()}
        title="Web Page"
        style={iframeStyle}
        onLoad={handleLoad}
      />
      {!isComplete && (
        <SeparatorWithChildren className={styles.separator}>
          <Button
            label="Continue"
            className={styles.button}
            onClick={() => onComplete?.()}
          />
        </SeparatorWithChildren>
      )}
    </div>
  );
};
