export const ProgressHamburger = () => {
  return (
    <svg
      width="16"
      aria-label="Show Progress"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.571429 3.57143H15.4286C15.7442 3.57143 16 3.31561 16 3V1.57143C16 1.25582 15.7442 1 15.4286 1H0.571429C0.255821 1 0 1.25582 0 1.57143V3C0 3.31561 0.255821 3.57143 0.571429 3.57143ZM0.571429 9.28572H15.4286C15.7442 9.28572 16 9.02989 16 8.71429V7.28571C16 6.97011 15.7442 6.71429 15.4286 6.71429H0.571429C0.255821 6.71429 0 6.97011 0 7.28571V8.71429C0 9.02989 0.255821 9.28572 0.571429 9.28572ZM0.571429 15H15.4286C15.7442 15 16 14.7442 16 14.4286V13C16 12.6844 15.7442 12.4286 15.4286 12.4286H0.571429C0.255821 12.4286 0 12.6844 0 13V14.4286C0 14.7442 0.255821 15 0.571429 15Z"
        fill="var(--text-body)"
      />
    </svg>
  );
};
