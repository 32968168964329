import React from "react";
import { classNames } from "primereact/utils";
import { CheckedFeedbackStar, UncheckedFeedbackStar } from "./Icons";
import styles from "./FeedbackRating.module.css";
import { motion } from "framer-motion";
import { times } from "lodash";
import { Tooltip } from "primereact/tooltip";

export const FeedbackRating: React.FC<{
  className?: string;
  rating: number;
}> = ({ className, rating }) => {
  const item = {
    initial: { opacity: 0, marginTop: -20 },
    visible: { opacity: 1, marginTop: 0 },
  };

  const tooltip =
    {
      1: "1/3 Needs work",
      2: "2/3 Good",
      3: "3/3 Excellent",
    }[rating] || "";

  return (
    <div className={styles.feedbackRatingContainer}>
      <Tooltip target={`.${styles.container}`} />
      <div
        className={classNames(styles.container, className)}
        data-pr-tooltip={tooltip}
        data-pr-position="top"
        data-pr-at="center top-10"
      >
        {times(3, (index) => (
          <motion.div
            initial="initial"
            animate="visible"
            variants={item}
            transition={{ delay: (2 - index) * 0.05 }}
            key={index}
          >
            {rating <= index ? (
              <UncheckedFeedbackStar />
            ) : (
              <CheckedFeedbackStar />
            )}
          </motion.div>
        ))}
      </div>
      <div className={classNames(styles.mobileTooltip, "md:hidden")}>
        {tooltip}
      </div>
    </div>
  );
};
