import { atom } from "jotai";
import { MainAnimationType } from "./types";

// key to trigger animation when mainContentAtom changes
export const contentKeyAtom = atom(0);

// Atom to manage the type of animation currently selected for main view
export const mainAnimationTypeAtom = atom<MainAnimationType>(
  MainAnimationType.FADE,
);

export const showNavAtom = atom(false);
export const showHeaderAtom = atom(true);
