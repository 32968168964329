import { inferProcedureOutput, inferProcedureInput } from "@trpc/server";
import { createTRPCReact } from "@trpc/react-query";
import { createTRPCProxyClient, httpBatchLink, httpLink } from "@trpc/client";
import type { AppRouter } from "@arena-active/api";
import type { CreateTRPCReact } from "@trpc/react-query";
import type { CreateTRPCProxyClient } from "@trpc/client";
import { createTRPCJotai } from "jotai-trpc";
export { DISABLED, createTRPCJotai } from "jotai-trpc";
export type {
  inferProcedureInput,
  inferProcedureOutput,
  ProcedureOptions,
} from "@trpc/server";

// Activity Types
export type {
  LessonVideoActivity,
  LessonPageActivity,
  SingleResponseExercise,
} from "@arena-active/api";

export const trpc: CreateTRPCReact<AppRouter, unknown, null> =
  createTRPCReact<AppRouter>();

export const standaloneTRPCClient = (
  apiUrl: string,
): CreateTRPCProxyClient<AppRouter> => {
  return createTRPCProxyClient<AppRouter>({
    links: [httpBatchLink({ url: apiUrl })],
  });
};

type TRPCJotaiClient = ReturnType<typeof createTRPCJotai<AppRouter>>;

export const jotaiTRPCClient = (apiUrl: string): TRPCJotaiClient => {
  return createTRPCJotai<AppRouter>({
    links: [httpLink({ url: apiUrl })],
  });
};

// TODO - this is only partial, but we can infer any types we need from the backend api here
// and they will be automatically udpated if we make any changes there
export type LessonsListOutput = inferProcedureOutput<
  AppRouter["lesson"]["list"]
>;
type LessonsArray = LessonsListOutput["lessons"];
export type Lesson = LessonsArray[number];
export type LearningObjective = inferProcedureOutput<
  AppRouter["objective"]["get"]
>;
export type ResponseAnalysis = inferProcedureOutput<
  AppRouter["prompt"]["analyzeResponse"]
>;
export type ActiveLessonListOutput = inferProcedureOutput<
  AppRouter["activeLesson"]["list"]
>;

export type PublishedActiveLesson = inferProcedureOutput<
  AppRouter["publishedActiveLesson"]["get"]
>;

export type LessonNav = inferProcedureOutput<
  AppRouter["publishedActiveLesson"]["getLessonNav"]
>;

type ActiveLessonsArray = ActiveLessonListOutput["activeLessons"];
export type ActiveLesson = ActiveLessonsArray[number];
export type ActivityState = inferProcedureOutput<
  AppRouter["activity"]["getOrCreateUserActivityState"]
>;

export type ActiveSessionStateOutput = inferProcedureOutput<
  AppRouter["activity"]["getUserActiveSessionState"]
>;

export type ActiveSessionStateSummaryOutput = inferProcedureOutput<
  AppRouter["activity"]["getUserActiveSessionStateSummary"]
>;

export type UpsertActivityStateInput = inferProcedureInput<
  AppRouter["activity"]["upsertActivityState"]
>;
export type UpsertActivityStateOutput = inferProcedureOutput<
  AppRouter["activity"]["upsertActivityState"]
>;

type InferredActivityStateSummary =
  ActiveSessionStateOutput["activities"][number];

export type DeprecatedActivityStateSummary = Omit<
  InferredActivityStateSummary,
  "id" | "response"
> & {
  id?: number;
  response?: Response;
};

export type ActiveSessionStateOutputActivity =
  ActiveSessionStateOutput["activities"][number];

export type ActivityStateSummary =
  ActiveSessionStateSummaryOutput["activities"][number];

type InferredResponse = inferProcedureInput<
  AppRouter["activity"]["updateResponse"]
>;
export type Response = Omit<InferredResponse, "id"> & {
  id?: number;
};

export type CourseListOutput = inferProcedureOutput<
  AppRouter["course"]["list"]
>;
export type Course = CourseListOutput[number];

export type CreateCourseInput = inferProcedureInput<
  AppRouter["course"]["upsert"]
>;

export type Activity = inferProcedureOutput<
  AppRouter["activity"]["getActivity"]
>;

// can't be inferred due to dynamic return type
type Counts = {
  lessons: number;
  activeLessons: number;
  enrollees: number;
};

export type CourseWithCounts = Course & {
  _count?: Counts;
};
