import { InputSwitch } from "primereact/inputswitch";

export interface ThemeToggleProps {
  value: boolean;
  setValue: (value: boolean) => void;
}

export const ThemeToggle: React.FC<ThemeToggleProps> = ({
  value,
  setValue,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputSwitch
        checked={value}
        onChange={(e) => setValue(e.value || false)}
      />
      <span style={{ color: "var(--text-title)", marginLeft: "10px" }}>
        {" "}
        {value ? "dark" : "light"} mode{" "}
      </span>
    </div>
  );
};
