import styles from "./SideNavIconWrapper.module.css";
import classNames from "classnames";

export enum IconState {
  Active = "active",
  Inactive = "inactive",
  Current = "current",
}

type IconWrapperProps = {
  children: React.ReactNode;
  state: IconState;
};

export const IconWrapper: React.FC<IconWrapperProps> = ({
  children,
  state,
}) => {
  const stateClass = classNames(styles.iconWrapper, {
    [styles.active]: state === IconState.Active,
    [styles.inactive]: state === IconState.Inactive,
    [styles.current]: state === IconState.Current,
  });
  return (
    <div className={stateClass}>
      <span style={{ height: "24px", lineHeight: "24px" }}>{children}</span>
    </div>
  );
};
