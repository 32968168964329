import {
  lessonAtom,
  activeSessionStateAtom,
  currentActivityIdAtom,
  currentActivityAtom,
  lessonIdAtom,
  useMarkActivityCompletedAtom,
  isNewActivityLoadingAtom,
  isLoadingAtom,
  showFooterAtom,
  firstNonCompletedActivityIdAtom,
  selectedStepIndexAtom,
} from "../state/atoms";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import {
  getActivityPositionInLesson,
  getActivityState,
  getCurrentActivity,
  getProgress,
} from "../state/util";
import { useCallback, useEffect } from "react";
import {
  Activity,
  LessonNav,
  ActiveSessionStateOutput,
  Response,
} from "@arena-active/trpc-client";
import {
  totalStepsAtom,
  completedStepsAtom,
  updateProgressEffect,
} from "../state/atoms";
import { showNavAtom } from "../layout/atoms";
import { useMediaQuery } from "react-responsive";

export interface UseLessonDataResult {
  lesson: LessonNav | undefined;
  activeSessionState: ActiveSessionStateOutput | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onComplete: (arg: any) => void;
  isLoading: boolean;
  showFooter: boolean;
  currentActivity: Activity | undefined;
  firstNonCompletedActivityId?: number;
  error: Error | null;
}

export const useLessonData = (
  studentId: number,
  lessonId: number,
  selectedActivityId: number | null,
): UseLessonDataResult => {
  const setLessonId = useSetAtom(lessonIdAtom);
  const showFooter = useAtomValue(showFooterAtom);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const { markCompleted, error } = useMarkActivityCompletedAtom();

  const [isNewActivityLoading, setNewActivityLoading] = useAtom(
    isNewActivityLoadingAtom,
  );

  const isLoading = useAtomValue(isLoadingAtom);

  useEffect(() => {
    setLessonId(lessonId);
  }, [studentId, lessonId, setLessonId]);

  const [lesson] = useAtom(lessonAtom);
  const [activeSessionState] = useAtom(activeSessionStateAtom);
  const setCurrentActivityId = useSetAtom(currentActivityIdAtom);
  const [firstNonCompletedActivityId, setFirstNonCompletedActivityId] = useAtom(
    firstNonCompletedActivityIdAtom,
  );
  /**
   * TODO: I'm exposing this as part of the hook api
   * As I think it's useful for the UI.
   * But I need to find out how to get this value from the server.
   */
  // const isLastActivity = false;

  // TODO - can the below loading state updates be moved to atom level?
  const [value] = useAtom(currentActivityAtom);
  const currentActivity: Activity | undefined =
    value.state === "hasData" ? value.data : undefined;
  const currentActivityLoadingState = value.state;
  const currentActivityError =
    value.state === "hasError" ? value.error : undefined;

  useEffect(() => {
    if (currentActivityLoadingState === "loading") {
      setNewActivityLoading(true);
    } else if (currentActivityLoadingState === "hasData") {
      setNewActivityLoading(false);
    } else if (currentActivityLoadingState === "hasError") {
      throw new Error(currentActivityError as string);
    }
  }, [
    currentActivity,
    currentActivityError,
    currentActivityLoadingState,
    setNewActivityLoading,
  ]);

  // update progress
  const setTotalSteps = useSetAtom(totalStepsAtom);
  const setCompletedSteps = useSetAtom(completedStepsAtom);
  const setSelectedStepIndex = useSetAtom(selectedStepIndexAtom);
  const setShowNav = useSetAtom(showNavAtom);
  useAtom(updateProgressEffect);

  useEffect(() => {
    if (currentActivity) {
      const { totalActivities, completedActivities } = getProgress(
        lesson?.sessions ?? [],
        activeSessionState?.activities ?? [],
      );
      totalActivities && setTotalSteps(totalActivities);
      if (completedActivities === 0 && !isMobile) {
        setShowNav(true);
      }
      setCompletedSteps(completedActivities);
    }
  }, [
    currentActivity,
    activeSessionState,
    lesson?.sessions,
    setTotalSteps,
    setCompletedSteps,
    setShowNav,
    isMobile,
  ]);

  useEffect(() => {}, [isNewActivityLoading]);

  useEffect(() => {
    if (lesson?.sessions && activeSessionState) {
      if (selectedActivityId) {
        setCurrentActivityId(selectedActivityId);
        const indexInLesson = getActivityPositionInLesson(
          lesson,
          selectedActivityId,
        );
        setSelectedStepIndex(indexInLesson);
        return;
      }
      setSelectedStepIndex(null);
      const currentActivity = getCurrentActivity(
        lesson?.sessions,
        activeSessionState.activities,
      );
      if (currentActivity) {
        setCurrentActivityId(currentActivity.id);
        setFirstNonCompletedActivityId(currentActivity.id);
      }
    }
  }, [
    activeSessionState,
    lesson,
    setCurrentActivityId,
    selectedActivityId,
    setSelectedStepIndex,
    setFirstNonCompletedActivityId,
  ]);

  const onComplete = useCallback(
    async (response: Response | undefined, isComplete?: boolean) => {
      console.log("completing activity with ", response);
      if (currentActivity && activeSessionState) {
        const result = await markCompleted(
          getActivityState(
            currentActivity.id,
            activeSessionState?.activities ?? [],
          )?.id,
          studentId,
          currentActivity.id,
          isComplete ?? true,
          response,
        );
        console.log("markcompleted result", result);
        return result;
      }
    },
    [currentActivity, activeSessionState, markCompleted, studentId],
  );

  return {
    lesson,
    activeSessionState,
    onComplete,
    error,
    isLoading,
    currentActivity,
    showFooter,
    firstNonCompletedActivityId,
  };
};
