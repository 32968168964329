import { Response } from "../lesson/ActivityViewer";
import { FC, useEffect, useState } from "react";
import { trpc } from "@arena-active/trpc-client";
import styles from "./ExistingResponse.module.css";
import { AudioPlayer, Spinner, UploadBlob } from "@arena-active/client-lib";

export const ExistingResponse: FC<{
  existingResponse?: Response;
  responseType: string | null;
}> = ({ existingResponse, responseType }) => {
  const [audioBlob, setAudioBlob] = useState<UploadBlob | null>(null);
  const [loadingAudio, setLoadingAudio] = useState<boolean>(false);
  const { data: signedAudioUrl, isError: signAudioError } =
    trpc.storage.getSignedUrl.useQuery(
      {
        objectKey: existingResponse?.audioStorageKey ?? "",
      },
      {
        enabled: !!existingResponse?.audioStorageKey,
        refetchOnWindowFocus: false,
      },
    );

  const { data: signedVideoUrl, isError: signVideoError } =
    trpc.storage.getSignedUrl.useQuery(
      {
        objectKey: existingResponse?.videoStorageKey ?? "",
      },
      {
        enabled: !!existingResponse?.videoStorageKey,
        refetchOnWindowFocus: false,
      },
    );

  if (signAudioError || signVideoError) {
    console.error(
      "Error getting url",
      existingResponse?.audioStorageKey,
      existingResponse?.videoStorageKey,
    );
  }

  useEffect(() => {
    if (signedAudioUrl) {
      setLoadingAudio(true);

      fetch(signedAudioUrl)
        .then((response) => response.blob())
        .then((blob) => setAudioBlob(blob))
        .then(() => setLoadingAudio(false));
    }
  }, [signedAudioUrl]);

  return (
    <div className={styles.container}>
      {responseType === "text" && <div>{existingResponse?.responseText}</div>}
      {responseType === "audio" && (
        <div style={{ width: "100%" }}>
          {loadingAudio && <Spinner />}
          {audioBlob && (
            <AudioPlayer
              capturedAudio={audioBlob}
              showAudioVisualizer={false}
            />
          )}
          <div className={styles.transcript}>
            {existingResponse?.responseText}
          </div>
        </div>
      )}
      {responseType === "video" && (
        <div>
          <video
            className={styles.video}
            src={signedVideoUrl}
            controls={true}
          />
          <div className={styles.transcript}>
            {existingResponse?.responseText}
          </div>
        </div>
      )}
    </div>
  );
};
