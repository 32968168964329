import { Activity } from "@arena-active/trpc-client";

/**
 * TODO: should be coming from the API
 */
export enum ActivityType {
  LessonPage = "lessonPage",
  Exercise = "exercise",
  Video = "video",
  UploadFiles = "uploadFiles",
}

export enum ResponseType {
  Video = "video",
  Audio = "audio",
  Text = "text",
  File = "file",
  None = "none",
}

// below is the type definition for the configuration for content that goes in main view of layout
export interface MainActivityProps {
  activity: Activity;
  isComplete: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onComplete?: (args?: any, isComplete?: boolean) => any;
}
export enum MainAnimationType {
  FADE = "fade",
  SLIDE = "slide",
}
