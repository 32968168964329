import { Spinner } from "../common/Spinner";
import styles from "./UserMediaRecorder.module.css";
import type { UserMediaRecorderProps } from "./UserMediaRecorder";
import { useState } from "react";
import { ErrorIcon } from "../common/Icons";
import { truncate } from "lodash";
import { useMediaQuery } from "react-responsive";

export interface SimpleTranscriptViewerProps {
  text: string | undefined;
  onEditClicked: Required<UserMediaRecorderProps>["onRequestTranscriptEdit"];
  transcribeError?: Error | null;
  onRetry: () => void;
}

export function SimpleTranscriptViewer({
  text,
  onEditClicked,
  transcribeError,
  onRetry,
}: SimpleTranscriptViewerProps) {
  const [longText, setLongText] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 961 });
  const MAX_VISIBLE_TEXT_LENGTH = isMobile ? 100 : 200;

  if (transcribeError) {
    return (
      <div className={styles.transcriptContainer}>
        <div className={styles.transcribeError}>
          <ErrorIcon />
          <div>
            There was an issue transcribing your file. Please{" "}
            <span className={styles.tryAgainTranscribe} onClick={onRetry}>
              Try Again.
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.transcriptContainer}>
      {(!text || text.length == 0) && (
        <div className={styles.processing}>
          <Spinner />
          <p>Getting transcript...</p>
        </div>
      )}
      {text && text.length > 0 && (
        <div className={styles.transcript}>
          {longText
            ? text
            : truncate(text, { length: MAX_VISIBLE_TEXT_LENGTH })}{" "}
          {text.length > MAX_VISIBLE_TEXT_LENGTH && !longText && (
            <span
              onClick={() => setLongText(!longText)}
              className={styles.more}
            >
              View All
            </span>
          )}
          {(longText || text.length < MAX_VISIBLE_TEXT_LENGTH) && (
            <span
              className={styles.editButton}
              onClick={() => onEditClicked(text)}
            >
              <p>Edit transcript</p>
              <i className="pi pi-pencil"></i>
            </span>
          )}
        </div>
      )}
    </div>
  );
}
