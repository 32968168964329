import React from "react";
import styles from "./GridLayout.module.css";
import { useAtomValue } from "jotai/index";
import { showNavAtom, showHeaderAtom } from "./atoms";
import { classNames } from "primereact/utils";
import "primeflex/primeflex.css";

export interface LayoutProps {
  headerContent?: React.ReactNode;
  navContent?: React.ReactNode;
  mainContent?: React.ReactNode;
  footerContent?: React.ReactNode;
  className?: string;
}

export const GridLayout: React.FC<LayoutProps> = ({
  headerContent,
  navContent,
  footerContent,
  mainContent,
  className,
}) => {
  const showNav = useAtomValue(showNavAtom);
  const showHeader = useAtomValue(showHeaderAtom);

  return (
    <div
      className={classNames(
        className,
        styles.layout,
        showNav && styles.showNav,
      )}
    >
      <div
        className={styles.header}
        style={{ display: showHeader ? "block" : "none" }}
      >
        {headerContent}
      </div>

      <div className={classNames(styles.mainAndNav, showNav && styles.showNav)}>
        <div className={styles.mainContainer}>
          <div className={styles.main}>{mainContent}</div>
          {footerContent && (
            <div className={styles.footer}>{footerContent}</div>
          )}
        </div>

        <div className={styles.nav}>{navContent}</div>
      </div>
    </div>
  );
};
