import {
  DetailedHTMLProps,
  VideoHTMLAttributes,
  useRef,
  useState,
  useEffect,
} from "react";
import { PlayCircle } from "../common/Icons";
import styles from "./UserMediaRecorder.module.css";

export function SimpleVideoPlayer(
  props: DetailedHTMLProps<
    VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
  >,
) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(
    function observeVideoPlayback() {
      if (videoRef.current) {
        const videoEl = videoRef.current;

        const handler = () => {
          setIsPlaying(true);
        };

        videoEl.addEventListener("play", handler);

        return function cleanup() {
          videoEl.removeEventListener("play", handler);
        };
      }
    },
    [videoRef],
  );

  return (
    <div className={styles.videoPlayer}>
      <video {...props} ref={videoRef} />
      {!isPlaying && (
        <div
          className={styles.overlay}
          onClick={() => {
            videoRef?.current?.play();
          }}
        >
          <PlayCircle fillColor="#fff" />
        </div>
      )}
    </div>
  );
}
