import {
  StorageService,
  UploadFileResponse,
  UploadProgressCallback,
} from "./types";
import { UploadBlob, XHRTarget } from "./upload/UploadTarget";

export const uploadFileToUrl = (
  url: string,
  blob: UploadBlob,
  onProgress: UploadProgressCallback,
): Promise<UploadFileResponse> => {
  try {
    return new Promise((resolve, reject) => {
      const target = new XHRTarget(blob, url);

      target.on("progress", (event) => {
        onProgress((event.detail.progress / event.detail.total) * 100);
      });

      target.on("success", () => {
        resolve({
          success: true,
          message: "File uploaded successfully.",
          downloadUrl: url.split("?")[0],
        });
      });

      target.on("error", (event) => {
        reject({ success: false, message: event.detail.error });
      });
      target.start();
    });
  } catch (error) {
    return Promise.reject({
      success: false,
      message: `Error: ${(error as Error).message}`,
    });
  }
};

export const uploadFile =
  (service: StorageService) =>
  async (
    blob: UploadBlob,
    filename: string,
    onProgress: UploadProgressCallback,
  ): Promise<UploadFileResponse> => {
    const signedUrl = await service.getSignedUrl(filename);
    return uploadFileToUrl(signedUrl, blob, onProgress);
  };
