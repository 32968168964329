import { ActivityType } from "./types";
import { ActivityItem } from "./ActivityItem";
import { NavSectionTitle } from "./NavSectionTitle";
import { ResponseType } from "../layout/types";

interface NavSectionProps {
  items: SectionItem[];
  title: string;
  isShowLabel: boolean;
  isShowTitle: boolean;
  isShowIcon: boolean;
  isShowSectionTitle: boolean;
  isShowPosition: boolean;
  isSectionAvailable?: boolean;
  onClickItem: (itemId: number, isComplete: boolean) => void;
}

export interface SectionItem {
  id: number;
  title: string;
  lessonPosition: number;
  activityType: ActivityType;
  responseType: ResponseType;
  isCurrent: boolean;
  isComplete: boolean;
  isFirstInSection: boolean;
  isLastInSection: boolean;
  isSectionHasTitle: boolean;
}

export const NavSection: React.FC<NavSectionProps> = ({
  title,
  items,
  isShowLabel,
  isShowTitle,
  isShowIcon,
  isShowPosition,
  isShowSectionTitle,
  isSectionAvailable,
  onClickItem,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        width: "100%",
        gridTemplateColumns: "auto",
      }}
    >
      {isShowSectionTitle && (
        <NavSectionTitle
          title={title}
          isSectionAvailable={isSectionAvailable ?? false}
        />
      )}
      {items.map((item, index) => (
        <ActivityItem
          key={index}
          {...item}
          isShowLabel={isShowLabel}
          isShowTitle={isShowTitle}
          showIcon={isShowIcon}
          isShowPosition={isShowPosition}
          isSectionHasTitle={isShowSectionTitle}
          onClick={onClickItem}
        />
      ))}
    </div>
  );
};
