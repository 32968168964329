import React, { ReactNode, useRef } from "react";
import { useAtom } from "jotai";
import { Menubar } from "primereact/menubar";
import { OverlayPanel } from "primereact/overlaypanel";
import { ThemeSelectButton } from "./themeSelect/ThemeSelectButton";
import { ThemeSelectDropdown } from "./themeSelect/ThemeSelectDropdown";
import { SessionsIcon } from "./SessionsIcon";
import { SessionsWorkmark } from "./SessionsWorkmark";
import { themeAtom } from "../../theme/themeAtoms";
import styles from "./Navbar.module.css";

export interface NavBarProps {
  subTitle: string;
  authAvatarButton?: ReactNode;
}

const NavBarStart = ({ subTitle }: { subTitle: string }) => (
  <div className="flex align-items-center sm:flex-row md:flex-column lg:flex-row md:align-items-start lg:align-items-center">
    <SessionsIcon className={`${styles.logo} w-2 md:w-2 md:hidden`} />
    <SessionsWorkmark
      className="w-auto hidden md:block"
      link={import.meta.env.VITE_SESSIONS_HOMEPAGE_URL}
    />

    <div className="mx-4 sm:block md:hidden lg:block">
      <p style={{ color: `var(--surface-level-4)` }}>|</p>
    </div>
    <div className="w-full">
      <p
        className="lg:text-lg md:text-md m-0"
        style={{ color: `var(--text-title)` }}
      >
        {subTitle}
      </p>
    </div>
  </div>
);

export const NavBar: React.FC<NavBarProps> = ({
  subTitle,
  authAvatarButton,
}) => {
  const themeSelectorOverlay = useRef<OverlayPanel>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setTheme] = useAtom(themeAtom);

  return (
    <Menubar
      pt={{
        root: {
          className: `${styles.navBarRoot}`,
          style: {
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderRadius: 0,
            borderColor: "var(--surface-level-4)",
            backgroundColor: "var(--surface-level-1)",
          },
        },
        button: {
          style: { display: "none" },
        },
      }}
      start={<NavBarStart subTitle={subTitle} />}
      end={
        <div className="flex flex-row gap-3 justify-content-end">
          <ThemeSelectButton
            onClick={(e) => themeSelectorOverlay.current?.toggle(e)}
          />
          {authAvatarButton}
          <OverlayPanel
            className={`${styles.themeSelectorOverlay} ml-5 mt-1`}
            ref={themeSelectorOverlay}
          >
            <ThemeSelectDropdown onChanged={(theme) => setTheme(theme)} />
          </OverlayPanel>
        </div>
      }
    />
  );
};
