import { classNames } from "primereact/utils";
import styles from "./UserMediaRecorder.module.css";

export interface RecorderIconProps {
  SVG: JSX.Element;
  onClick?: () => void;
  onMouseOver?: () => void;
  className?: string;
  dataPrTooltip?: string | undefined;
  dataPrPosition?: "top" | "bottom" | "left" | "right" | "mouse" | undefined;
  dataPrAt?: string | undefined;
  dataPrDisabled?: boolean | undefined;
}

export function RecorderIcon({
  SVG,
  onClick,
  onMouseOver,
  className,
  dataPrTooltip,
  dataPrPosition,
  dataPrAt,
  dataPrDisabled,
}: RecorderIconProps) {
  return (
    <div
      className={classNames(styles.icon_container, className)}
      onClick={onClick}
      onMouseOver={onMouseOver}
      data-pr-tooltip={dataPrTooltip}
      data-pr-position={dataPrPosition}
      data-pr-at={dataPrAt}
      data-pr-disabled={dataPrDisabled}
    >
      {SVG}
    </div>
  );
}
