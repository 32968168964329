import { FC, useEffect, useState } from "react";
import styles from "./Exercise.module.css";
import { AiBot, FeedbackPanel, Typography } from "@arena-active/client-lib";
import { StorageClient } from "@arena-active/client-lib";
import { withStorageClient } from "#lib/withStorageClient";
import { API_URL } from "../config";
import { WrittenResponse } from "./WrittenResponse";
import { VideoResponse } from "./VideoResponse";
// TODO - only commenting this out for now as there's an open quuestion about whether to enable this
// import { NoteIcon } from "../Icons";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import classNames from "classnames";
import { ExistingResponse } from "./ExistingResponse";
import { Button } from "@arena-active/client-lib";

export type SubmitPayload = {
  responseText?: string;
  audioStorageKey?: string;
  videoStorageKey?: string;
};

export interface ITranscriptionService {
  transcribe(audioStorageKey: string): Promise<string>;
}

const ratingToNumber = (rating: string) => {
  if (/needs/gi.test(rating)) {
    return 1;
  }
  if (/satisfactory/gi.test(rating)) {
    return 2;
  }
  if (/good/gi.test(rating)) {
    return 3;
  }
  if (/excellent/gi.test(rating)) {
    return 3;
  }
  return 0;
};

export const Exercise: FC<{
  storageClient?: ReturnType<typeof StorageClient>;
  responseType: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  feedback: any;
  details?: string;
  onSubmit: (payload: SubmitPayload) => void;
  onComplete: () => void;
}> = ({
  storageClient,
  details,
  feedback,
  response,
  responseType,
  onSubmit,
  onComplete,
}) => {
  const [submitting, setSubmitting] = useState<SubmitPayload | null>(null);
  const [showInitialScreen, setShowInitialScreen] = useState<boolean>(
    !response,
  );
  const [selectedTab, setSelectedTab] = useState<string>("response");
  const [textEnforced, setTextEnforced] = useState<boolean>(false);

  useEffect(() => {
    setShowInitialScreen(!response);
    console.log("babke", response);
  }, [response]);

  return (
    <motion.div className={styles.exercise} layoutRoot>
      <div
        className={classNames(
          styles.panel,
          showInitialScreen && styles.initial,
          (feedback || submitting) && styles.feedback,
        )}
      >
        <LayoutGroup>
          <AnimatePresence mode="popLayout">
            {showInitialScreen && (
              <motion.div
                className={styles.nameRow}
                initial={{ opacity: 1, marginTop: 0 }}
                exit={{ opacity: 0, marginTop: -100 }}
                layout
              >
                <AiBot />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {(feedback || submitting) && (
              <motion.div
                className={styles.tabs}
                initial={{ opacity: 0, marginTop: 0 }}
                animate={{ opacity: 1, marginTop: 0 }}
                layout
              >
                <div
                  className={classNames(
                    selectedTab == "response" && styles.selected,
                  )}
                  onClick={() => setSelectedTab("response")}
                >
                  You Wrote
                </div>
                <div
                  className={classNames(
                    selectedTab == "question" && styles.selected,
                  )}
                  onClick={() => setSelectedTab("question")}
                >
                  Question
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {selectedTab === "question" && (
              <motion.div
                className={classNames(
                  styles.details,
                  showInitialScreen && styles.initial,
                )}
                layout
                initial={{ opacity: 0, marginTop: 100 }}
                animate={{ opacity: 1, marginTop: 0 }}
              >
                <Typography variant="displaySmall">{details}</Typography>
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence mode="wait">
            {!feedback && !submitting && selectedTab === "response" && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, marginTop: 0, height: 0 }}
                layout
                transition={{
                  type: "spring",
                  stiffness: 400,
                  // duration: 100,
                  damping: 30,
                  mass: 1,
                }}
              >
                <Typography
                  variant={"displaySmall"}
                  className={classNames(
                    styles.details,
                    showInitialScreen && styles.initial,
                  )}
                >
                  {details}
                </Typography>
              </motion.div>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {showInitialScreen && (
              <motion.div
                className={styles.bottomRow}
                initial={{ opacity: 1 }}
                exit={{
                  opacity: 0,
                  minHeight: 0,
                  height: 0,
                }}
                layout
                transition={{
                  type: "spring",
                  stiffness: 400,
                  damping: 30,
                  mass: 1,
                }}
              >
                <Button
                  className={styles.button}
                  size="LARGE"
                  label="Start exercise"
                  onClick={() => setShowInitialScreen(false)}
                />
                {/* <div className={styles.divider} />
                <div className={styles.description}>
                  <NoteIcon className={styles.icon} />
                  <Typography variant="bodyMedium">
                    <strong>Note:</strong> Clicking 'Start Exercise' will
                    temporarily pause access to the lesson. Please make sure
                    you're ready you continue.
                  </Typography>
                </div> */}
              </motion.div>
            )}
          </AnimatePresence>
          {response ? (
            <ExistingResponse
              existingResponse={response}
              responseType={responseType}
            />
          ) : (
            <>
              <AnimatePresence>
                {!showInitialScreen &&
                  (responseType === "text" || textEnforced) && (
                    <motion.div
                      style={{
                        width: "100%",
                        display: selectedTab === "response" ? "block" : "none",
                      }}
                      initial={{ marginTop: 200 }}
                      animate={{ marginTop: 0 }}
                    >
                      <WrittenResponse
                        onSubmit={(payload) => {
                          setSubmitting(payload);
                          onSubmit(payload);
                        }}
                      />
                    </motion.div>
                  )}

                {!showInitialScreen &&
                  responseType === "audio" &&
                  !textEnforced && (
                    <motion.div
                      style={{
                        width: "100%",
                        display: selectedTab === "response" ? "block" : "none",
                      }}
                      initial={{ marginTop: 200 }}
                      animate={{ marginTop: 0 }}
                    >
                      <VideoResponse
                        feedback={feedback}
                        storageClient={storageClient}
                        initialMediaConstraints={{ audio: true, video: false }}
                        onSubmit={(payload) => {
                          setSubmitting(payload);
                          onSubmit(payload);
                        }}
                        onComplete={onComplete}
                        onSwitchToText={() => setTextEnforced(true)}
                      />
                    </motion.div>
                  )}

                {!showInitialScreen &&
                  responseType === "video" &&
                  !textEnforced && (
                    <motion.div
                      style={{
                        width: "100%",
                        display: selectedTab === "response" ? "block" : "none",
                      }}
                      initial={{ marginTop: 200 }}
                      animate={{ marginTop: 0 }}
                    >
                      <VideoResponse
                        feedback={feedback}
                        storageClient={storageClient}
                        initialMediaConstraints={{ audio: true, video: true }}
                        onSubmit={(payload) => {
                          setSubmitting(payload);
                          onSubmit(payload);
                        }}
                        onComplete={onComplete}
                        onSwitchToText={() => setTextEnforced(true)}
                      />
                    </motion.div>
                  )}
              </AnimatePresence>
            </>
          )}
          {(feedback || submitting) && selectedTab === "response" && (
            <div className={styles.feedbackPanel}>
              <FeedbackPanel
                className={styles.feedback}
                feedback={feedback && feedback.evaluationFeedback}
                rating={ratingToNumber(feedback?.evaluationRating)}
                observeOnly={!!response}
                onContinue={() => onComplete()}
              />
            </div>
          )}
        </LayoutGroup>
      </div>
    </motion.div>
  );
};

export const ExerciseWithStorage: typeof Exercise = withStorageClient(
  Exercise,
  API_URL,
);
