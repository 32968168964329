import { useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Tooltip as Base, TooltipProps as BaseProps } from "primereact/tooltip";
import styles from "./Tooltip.module.css";

export type TooltipProps = Omit<BaseProps, "ref" | "pt" | "ptOptions"> & {
  hideOnTargetClick: boolean;
};

const defaultProps = {
  autoHide: true,
} satisfies Partial<TooltipProps>;

export function Tooltip({ hideOnTargetClick, ...props }: TooltipProps) {
  const baseRef = useRef<Base>(null);

  // TODO: a better fix would be to use `touchstart` and `touchend` events
  // to show/hide the tooltip - unfortunately this doesn't appear to be
  // trivial with prime react's tooltip component.
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Base
      {...defaultProps}
      {...props}
      ref={baseRef}
      disabled={isMobile}
      showOnDisabled={false}
      onShow={(ev) => {
        if (hideOnTargetClick) {
          ev.target.addEventListener(
            "click",
            () => {
              baseRef.current?.hide(ev);
            },
            { once: true },
          );
        }
      }}
      pt={{
        text: {
          className: styles.text,
        },
        arrow: {
          className: styles.arrow,
        },
      }}
    />
  );
}
