import { InputTextarea } from "primereact/inputtextarea";
import styles from "./WrittenResponse.module.css";
import { Button } from "@arena-active/client-lib";
import { forwardRef, useState } from "react";
import { SubmitPayload } from "./Exercise";
import { AnimatePresence, motion } from "framer-motion";
import { ButtonSize } from "@arena-active/client-lib";

export const WrittenResponse = forwardRef<
  HTMLTextAreaElement,
  {
    onSubmit: (payload: SubmitPayload) => void;
  }
>(({ onSubmit }, ref) => {
  const [responseText, setResponseText] = useState("");
  const [submitting, setSubmitting] = useState(false);

  return (
    <>
      <div className={styles.inputHolder}>
        <InputTextarea
          ref={ref}
          className={styles.input}
          autoResize={true}
          rows={1}
          value={responseText}
          readOnly={submitting}
          placeholder="Write something..."
          onChange={(ev) => setResponseText(ev.target.value)}
        />
        {
          <AnimatePresence>
            {responseText?.length > 0 && !submitting && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
              >
                <Button
                  label="Continue"
                  className={styles.continueButton}
                  size={ButtonSize.large}
                  onClick={() => {
                    setSubmitting(true);
                    onSubmit && onSubmit({ responseText });
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        }
      </div>
    </>
  );
});
