import { StorageService } from "./types";
import { MULTIPART_MIN_SIZE } from "./upload";
import { UploadTarget, UploadTargetWithBackend } from "./upload/UploadTarget";
import { uploadFile } from "./uploadFile";
import { uploadLargeBlob } from "./uploadLargeBlob";

export const StorageClient = (service: StorageService) => {
  return {
    uploadFile: uploadFile(service),
    uploadLargeBlob: uploadLargeBlob(service),
  };
};

export type StorageClient = ReturnType<typeof StorageClient>;

/**
 * A backend that you provide to the Upload function.
 */
export interface UploadBackend {
  getSignedUrl: (filename: string) => Promise<string>;
  deleteFile: (filename: string) => Promise<boolean>;
}

export const newUpload = (file: File, backend: UploadBackend): UploadTarget => {
  if (file.size > MULTIPART_MIN_SIZE) {
    throw new Error("Multipart is todo");
  } else {
    const target = new UploadTargetWithBackend(file, backend);
    target.start();
    return target;
  }
};
