export const keys = [
  "overlay-level--1",
  "overlay-level--2",
  "overlay-level--3",
  "overlay-level--4",
  "overlay-level-1",
  "overlay-level-2",
  "overlay-level-3",
  "overlay-level-4",
  "shadow-level-1",
  "shadow-level-2",
  "surface-alert-1",
  "surface-alert-2",
  "surface-error-1",
  "surface-error-2",
  "surface-level-0",
  "surface-level-1",
  "surface-level-2",
  "surface-level-3",
  "surface-level-4",
  "surface-primary-1",
  "surface-primary-2",
  "surface-secondary-1",
  "surface-secondary-2",
  "surface-success-1",
  "surface-success-2",
  "surface-tooltip",
  "text-alert",
  "text-body",
  "text-button-1",
  "text-button-2",
  "text-button-3",
  "text-caption",
  "text-error",
  "text-primary",
  "text-subtitle",
  "text-success",
  "text-title",
  "text-transcript",
];
