import { createContext } from "react";
import { StorageClient, newUpload } from "./storage-client";

import { StorageService } from "./types";
export { StorageClient } from "./storage-client";
export { CompleteUploadTarget } from "./upload/UploadTarget";
export type { UploadTarget, UploadBlob } from "./upload";

export { newUpload };
export type { UploadBackend } from "./storage-client";
export type {
  GetSignedUrlFunction,
  MultiPartUpload as CreateMultiPartUploadResponse,
  CreateMultiPartUploadFunction,
  GetPartUploadUrlFunction,
  CompleteMultiPartUploadFunction,
  AbortMultiPartUploadFunction,
  UploadProgressCallback,
  StorageService,
} from "./types";
export { makeStorageServices } from "./makeStorageServices";

export type StorageContextData = {
  client: StorageClient;
  service: StorageService;
  bucket: string;
};

const noContextError = new Error(
  "No StorageContext set - set one at the root of your app",
);

export const StorageContext = createContext<StorageContextData>({
  bucket: "",
  service: {} as StorageService,
  client: {
    uploadFile: () => Promise.reject(noContextError),
    uploadLargeBlob: () => Promise.reject(noContextError),
  },
});
