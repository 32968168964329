import React from "react";
import { AnimationControls, motion, useAnimation } from "framer-motion";
import styles from "./ActivityHeaderButton.module.css";

export const ActivityHeaderButton: React.FC<{
  buttonTitle?: string;
  onClick: () => void;
}> = ({ buttonTitle = "Continue", onClick }) => {
  const controls = useAnimation();
  const textControls = useAnimation();
  const svgControls = useAnimation();

  const buttonVariants = {
    initial: {
      backgroundPosition: "0% 0%",
      boxShadow:
        "0px 2px 6px 2px var(--shadow-level-1, rgba(0, 0, 0, 0.05)), 0px 1px 2px 0px var(--shadow-level-2, rgba(0, 0, 0, 0.10))",
    },
    animate: {
      backgroundPosition: "100% 0%",
      transition: {
        duration: 0.3,
        type: "tween",
        stiffness: 400,
        damping: 30,
      },
    },
    pulse: {
      boxShadow: [
        "0 0 0 0 rgba(37, 99, 235, 0.4)",
        "0 0 0 20px rgba(37, 99, 235, 0)",
        "0 0 0 0 rgba(37, 99, 235, 0.4)",
      ],
      transition: {
        duration: 1,
        repeat: 3,
        repeatType: "loop" as const,
        ease: "easeInOut",
        delay: 1.6,
        repeatDelay: 3,
      },
    },
  };

  React.useEffect(() => {
    const sequence = async () => {
      await Promise.all([
        controls.start("animate"),
        textControls.start({
          color: "var(--text-primary)",
          transition: { duration: 0.3 },
        }),
        svgControls.start({
          fill: ["var(--text-button-1, #2563EB)", "var(--text-primary)"],
          transition: { duration: 0.3 },
        }),
      ]);
      controls.start("pulse");
    };

    const timer = setTimeout(sequence, 1000);

    return () => clearTimeout(timer);
  }, [controls, textControls, svgControls]);

  return (
    <motion.div
      className={styles.activityHeaderButton}
      style={{
        width: "205px",
        height: "56px",
        borderRadius: "12px",
        padding: "0 16px",
        gap: "8px",
        background:
          "linear-gradient(90deg, var(--surface-primary-1) 50%, var(--surface-level-1) 50%)",
        backgroundSize: "200% 100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
        border: "1px solid var(--surface-level-4, #DEDEE2)",
      }}
      initial="initial"
      animate={controls}
      variants={buttonVariants}
      onClick={onClick}
    >
      <motion.div
        initial={{ color: "var(--text-button-1)" }}
        animate={textControls}
        style={{ display: "flex", alignItems: "center", gap: "8px" }}
      >
        <div>{buttonTitle}</div>
        <IconRoundDoubleArrowRight svgControls={svgControls} />
      </motion.div>
    </motion.div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const IconRoundDoubleArrowRight: React.FC<{
  svgControls: AnimationControls;
}> = ({ svgControls }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 128 128"
      initial={{ fill: "var(--text-button-1, #2563EB)" }}
      animate={svgControls}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.334 64.0003C117.334 93.4555 93.4555 117.334 64.0003 117.334C34.5451 117.334 10.667 93.4555 10.667 64.0003C10.667 34.5451 34.5451 10.667 64.0003 10.667C93.4555 10.667 117.334 34.5451 117.334 64.0003ZM69.4954 45.1719C67.9333 43.6098 65.4007 43.6098 63.8386 45.1719C62.2765 46.734 62.2765 49.2667 63.8386 50.8288L77.0101 64.0003L63.8386 77.1719C62.2765 78.734 62.2765 81.2667 63.8386 82.8288C65.4007 84.3909 67.9333 84.3909 69.4954 82.8288L85.4954 66.8288C87.0575 65.2667 87.0575 62.734 85.4954 61.1719L69.4954 45.1719ZM42.5052 45.1719C44.0673 43.6098 46.6 43.6098 48.1621 45.1719L64.1621 61.1719C65.7242 62.734 65.7242 65.2667 64.1621 66.8288L48.1621 82.8288C46.6 84.3909 44.0673 84.3909 42.5052 82.8288C40.9431 81.2667 40.9431 78.734 42.5052 77.1719L55.6768 64.0003L42.5052 50.8288C40.9431 49.2667 40.9431 46.734 42.5052 45.1719Z"
      />
    </motion.svg>
  </div>
);
