import React, { SVGProps } from "react";
import { useAtomValue } from "jotai";
import { themeAtom, Theme } from "../../theme";
import { getSystemTheme } from "../../util";

export interface SessionsWorkmarkProps extends SVGProps<SVGSVGElement> {
  theme?: Theme;
  link?: string;
}

export const SessionsWorkmark: React.FC<SessionsWorkmarkProps> = ({
  theme,
  link,
  ...props
}) => {
  const currentTheme = useAtomValue(themeAtom);
  const systemTheme = getSystemTheme();

  const storedTheme =
    theme ?? (currentTheme === "system" ? systemTheme : currentTheme);

  const themeColor = storedTheme === "light" ? "black" : "white";

  return (
    <svg
      {...props}
      onClick={() => {
        if (link) {
          window?.open(link, "_blank")?.focus();
        }
      }}
      style={{ cursor: "pointer" }}
      width="186"
      height="24"
      viewBox="0 0 186 24"
      fill="none"
    >
      <path
        d="M109.937 19.242C108.805 19.242 107.776 19.1019 106.844 18.8181C105.908 18.5378 105.096 18.0999 104.405 17.5112C103.723 16.9226 103.189 16.1799 102.815 15.2864C102.437 14.393 102.25 13.3454 102.25 12.1436C102.25 10.8788 102.454 9.78569 102.855 8.86773C103.256 7.94627 103.82 7.18598 104.545 6.58335C105.243 6.01226 106.052 5.59182 106.964 5.32554C107.879 5.05927 108.828 4.92613 109.814 4.92613C110.699 4.92613 111.514 5.02773 112.259 5.22744C113.007 5.43065 113.702 5.68992 114.344 6.01576V9.72613H113.759C113.599 9.58248 113.405 9.4108 113.178 9.2181C112.951 9.0254 112.673 8.8362 112.346 8.65051C112.029 8.47533 111.685 8.32817 111.31 8.20905C110.933 8.08992 110.499 8.03387 109.997 8.03387C108.892 8.03387 108.04 8.40175 107.448 9.14102C106.854 9.87679 106.556 10.8823 106.556 12.1472C106.556 13.412 106.86 14.4455 107.468 15.1218C108.08 15.798 108.945 16.1378 110.064 16.1378C110.585 16.1378 111.053 16.0747 111.474 15.9486C111.892 15.826 112.239 15.6788 112.516 15.5072C112.777 15.346 113.004 15.1778 113.208 14.9991C113.405 14.8204 113.592 14.6488 113.759 14.4806H114.344V18.1945C113.692 18.5168 113.011 18.7726 112.299 18.9618C111.588 19.151 110.799 19.2456 109.941 19.2456L109.937 19.242Z"
        fill={themeColor}
      />
      <path
        d="M129.318 12.0981C129.318 14.3299 128.696 16.0923 127.457 17.3781C126.214 18.664 124.474 19.3051 122.229 19.3051C119.984 19.3051 118.24 18.664 117 17.3781C115.761 16.0923 115.139 14.3334 115.139 12.0981C115.139 9.86279 115.764 8.08293 117.014 6.8006C118.263 5.51826 120.004 4.87709 122.232 4.87709C124.46 4.87709 126.238 5.52176 127.47 6.81111C128.706 8.10045 129.321 9.85929 129.321 12.0946L129.318 12.0981ZM124.213 15.3986C124.484 15.0517 124.684 14.6348 124.818 14.1478C124.951 13.6607 125.018 12.988 125.018 12.1226C125.018 11.3238 124.951 10.6546 124.811 10.1151C124.671 9.57549 124.48 9.14454 124.233 8.8187C123.986 8.48936 123.692 8.25461 123.348 8.12147C123 7.98483 122.626 7.91476 122.229 7.91476C121.831 7.91476 121.474 7.97082 121.163 8.08293C120.852 8.19505 120.552 8.41928 120.271 8.75914C120.017 9.07447 119.813 9.50542 119.666 10.052C119.516 10.6021 119.439 11.2888 119.439 12.1226C119.439 12.8619 119.502 13.5066 119.636 14.0497C119.766 14.5962 119.957 15.0307 120.214 15.36C120.458 15.6753 120.755 15.9031 121.096 16.0502C121.437 16.1939 121.828 16.2675 122.265 16.2675C122.643 16.2675 123 16.2009 123.344 16.0678C123.689 15.9346 123.976 15.7104 124.213 15.3951V15.3986Z"
        fill={themeColor}
      />
      <path
        d="M134.75 18.8812H130.587V0.0665741H134.75V18.8812Z"
        fill={themeColor}
      />
      <path
        d="M141.031 18.8812H136.868V0.0665741H141.031V18.8812Z"
        fill={themeColor}
      />
      <path
        d="M155.967 12.897H146.466C146.526 13.9586 146.914 14.7749 147.625 15.339C148.337 15.9031 149.386 16.1834 150.769 16.1834C151.644 16.1834 152.493 16.0187 153.315 15.6894C154.137 15.36 154.788 15.0026 155.262 14.6243H155.723V18.1174C154.788 18.5133 153.903 18.7971 153.071 18.9758C152.242 19.1545 151.324 19.2421 150.318 19.2421C147.719 19.2421 145.728 18.6289 144.345 17.4026C142.965 16.1764 142.27 14.4351 142.27 12.1682C142.27 9.90133 142.925 8.1495 144.234 6.84264C145.544 5.53228 147.341 4.87709 149.623 4.87709C151.731 4.87709 153.311 5.43417 154.374 6.55184C155.433 7.6695 155.964 9.27418 155.964 11.3694V12.8934L155.967 12.897ZM151.841 10.3428C151.818 9.43184 151.604 8.74863 151.197 8.28615C150.789 7.82717 150.154 7.59593 149.292 7.59593C148.494 7.59593 147.836 7.81666 147.321 8.25111C146.803 8.68556 146.516 9.38279 146.456 10.3428H151.841Z"
        fill={themeColor}
      />
      <path
        d="M170.429 17.3221C170.429 18.5974 170.259 19.6695 169.911 20.5384C169.567 21.4108 169.08 22.0905 168.458 22.5845C167.837 23.0856 167.085 23.4464 166.206 23.6672C165.325 23.8914 164.332 24 163.226 24C162.328 24 161.439 23.944 160.567 23.8318C159.695 23.7197 158.94 23.5831 158.302 23.4219V20.0234H158.81C159.318 20.2336 159.932 20.4263 160.664 20.598C161.392 20.7731 162.044 20.8572 162.622 20.8572C163.39 20.8572 164.012 20.7837 164.493 20.633C164.97 20.4823 165.338 20.2721 165.592 19.9988C165.829 19.7396 165.999 19.4102 166.11 19.0073C166.217 18.6044 166.27 18.1209 166.27 17.5568V17.301C165.772 17.7285 165.218 18.0683 164.613 18.3171C164.005 18.5658 163.33 18.692 162.585 18.692C160.774 18.692 159.378 18.1209 158.392 16.9752C157.41 15.8295 156.919 14.0882 156.919 11.7512C156.919 10.6301 157.069 9.66307 157.367 8.85022C157.667 8.03387 158.085 7.32613 158.633 6.72C159.141 6.15591 159.762 5.71796 160.504 5.40263C161.242 5.0873 162 4.92964 162.779 4.92964C163.474 4.92964 164.112 5.01723 164.683 5.19241C165.254 5.36759 165.776 5.60584 166.243 5.91066L166.394 5.30803H170.429V17.3256V17.3221ZM166.267 14.5997V8.4438C166.029 8.33869 165.735 8.25811 165.391 8.19854C165.047 8.13898 164.737 8.10745 164.459 8.10745C163.37 8.10745 162.555 8.43679 162.01 9.09197C161.463 9.75066 161.192 10.6651 161.192 11.8423C161.192 13.1492 161.426 14.0602 161.887 14.5752C162.351 15.0902 163.039 15.3495 163.951 15.3495C164.366 15.3495 164.773 15.2794 165.167 15.1428C165.565 15.0061 165.933 14.8239 166.26 14.5997H166.267Z"
        fill={themeColor}
      />
      <path
        d="M184.955 12.897H175.454C175.517 13.9586 175.901 14.7749 176.613 15.339C177.324 15.9031 178.373 16.1834 179.757 16.1834C180.632 16.1834 181.484 16.0187 182.302 15.6894C183.124 15.36 183.776 15.0026 184.253 14.6243H184.714V18.1174C183.776 18.5133 182.894 18.7971 182.062 18.9758C181.233 19.1545 180.314 19.2421 179.309 19.2421C176.713 19.2421 174.719 18.6289 173.335 17.4026C171.956 16.1764 171.261 14.4351 171.261 12.1682C171.261 9.90133 171.916 8.1495 173.225 6.84264C174.535 5.53228 176.332 4.87709 178.614 4.87709C180.719 4.87709 182.302 5.43417 183.365 6.55184C184.427 7.6695 184.955 9.27418 184.955 11.3694V12.897ZM180.826 10.3428C180.802 9.43184 180.588 8.74863 180.177 8.28615C179.773 7.82717 179.138 7.59593 178.277 7.59593C177.475 7.59593 176.82 7.81666 176.302 8.25111C175.788 8.68556 175.5 9.38279 175.44 10.3428H180.826Z"
        fill={themeColor}
      />
      <path
        d="M12.1172 14.5122C12.1172 15.9136 11.5192 17.0453 10.3198 17.9037C9.12046 18.7621 7.48011 19.1896 5.39544 19.1896C4.24285 19.1896 3.19717 19.0774 2.26174 18.8497C1.32631 18.6255 0.571281 18.3767 0 18.0999V14.5192H0.380854C0.587986 14.6698 0.828525 14.838 1.10247 15.0202C1.37642 15.2024 1.76062 15.3986 2.26174 15.6053C2.69271 15.791 3.18047 15.9522 3.72502 16.0818C4.27292 16.215 4.85756 16.2815 5.47896 16.2815C6.29078 16.2815 6.89547 16.1869 7.28634 15.9977C7.67722 15.8085 7.87433 15.5282 7.87433 15.1569C7.87433 14.831 7.7574 14.5893 7.52688 14.4386C7.29971 14.2879 6.86206 14.1478 6.21728 14.0112C5.90992 13.9376 5.49232 13.8605 4.96447 13.7764C4.43662 13.6923 3.95888 13.5907 3.52791 13.4681C2.35194 13.1458 1.47999 12.6447 0.912046 11.9615C0.344105 11.2818 0.0601349 10.4339 0.0601349 9.41787C0.0601349 8.11101 0.651461 7.02488 1.83077 6.15948C3.01009 5.29408 4.6237 4.85962 6.66829 4.85962C7.63379 4.85962 8.56588 4.96473 9.4512 5.16794C10.3399 5.37466 11.0314 5.59188 11.5325 5.82663V9.26021H11.1751C10.5604 8.81875 9.85878 8.45787 9.07035 8.18108C8.28191 7.9043 7.48345 7.76415 6.67831 7.76415C6.00681 7.76415 5.44555 7.86225 4.98785 8.05495C4.53016 8.24765 4.29964 8.52094 4.29964 8.87831C4.29964 9.20065 4.40655 9.4459 4.61368 9.61408C4.82081 9.78225 5.30857 9.95393 6.07696 10.1221C6.50125 10.2097 6.9556 10.2973 7.44336 10.3814C7.93112 10.4655 8.42222 10.5741 8.91333 10.7002C10.0024 11.0015 10.8109 11.4675 11.3354 12.1087C11.8566 12.7498 12.1172 13.5487 12.1172 14.5087V14.5122Z"
        fill="#2563EB"
      />
      <path
        d="M26.5361 12.8304H17.0348C17.0949 13.892 17.4824 14.7084 18.194 15.2725C18.9056 15.8366 19.9513 16.1169 21.3344 16.1169C22.2097 16.1169 23.0583 15.9522 23.8835 15.6228C24.7053 15.2935 25.3534 14.9361 25.8312 14.5577H26.2922V18.0509C25.3534 18.4468 24.4681 18.7306 23.6396 18.9093C22.8111 19.088 21.8923 19.1755 20.8867 19.1755C18.2876 19.1755 16.2965 18.5624 14.9134 17.3361C13.5336 16.1099 12.8387 14.3685 12.8387 12.1017C12.8387 9.83481 13.4935 8.08299 14.8031 6.77612C16.1127 5.46576 17.9101 4.81058 20.1919 4.81058C22.2999 4.81058 23.8801 5.36766 24.9425 6.48532C26.0016 7.60299 26.5361 9.20766 26.5361 11.3028V12.8304ZM22.4068 10.2763C22.3834 9.36532 22.1663 8.68211 21.762 8.21963C21.3578 7.76065 20.7197 7.52941 19.8611 7.52941C19.0627 7.52941 18.4045 7.75014 17.89 8.18459C17.3755 8.61904 17.0849 9.31627 17.0248 10.2763H22.4102H22.4068Z"
        fill="#2563EB"
      />
      <path
        d="M39.375 14.5122C39.375 15.9136 38.777 17.0453 37.5776 17.9037C36.3816 18.7621 34.7379 19.1896 32.6532 19.1896C31.5007 19.1896 30.4583 19.0774 29.5196 18.8497C28.5808 18.6255 27.8291 18.3767 27.2578 18.0999V14.5192H27.6387C27.8458 14.6698 28.0863 14.838 28.3569 15.0202C28.6309 15.2024 29.0151 15.3986 29.5162 15.6053C29.9438 15.791 30.4316 15.9522 30.9795 16.0818C31.5274 16.215 32.112 16.2815 32.7334 16.2815C33.5486 16.2815 34.1499 16.1869 34.5442 15.9977C34.935 15.8085 35.1321 15.5282 35.1321 15.1569C35.1321 14.831 35.0186 14.5893 34.7847 14.4386C34.5542 14.2879 34.1199 14.1478 33.4718 14.0112C33.1644 13.9376 32.7468 13.8605 32.2189 13.7764C31.6944 13.6923 31.2134 13.5907 30.7857 13.4681C29.6064 13.1458 28.7345 12.6447 28.1665 11.9615C27.5952 11.2818 27.3146 10.4339 27.3146 9.41787C27.3146 8.11101 27.9026 7.02488 29.0819 6.15948C30.2646 5.29408 31.8748 4.85962 33.9228 4.85962C34.8916 4.85962 35.817 4.96473 36.7057 5.16794C37.591 5.37466 38.2859 5.59188 38.787 5.82663V9.26021H38.4295C37.8148 8.81875 37.1133 8.45787 36.3248 8.18108C35.5364 7.9043 34.7379 7.76415 33.9328 7.76415C33.2646 7.76415 32.7 7.86225 32.2423 8.05495C31.7846 8.24765 31.5575 8.52094 31.5575 8.87831C31.5575 9.20065 31.661 9.4459 31.8682 9.61408C32.0753 9.78225 32.563 9.95393 33.3314 10.1221C33.7557 10.2097 34.2101 10.2973 34.6978 10.3814C35.1856 10.4655 35.68 10.5741 36.1711 10.7002C37.2602 11.0015 38.0687 11.4675 38.5899 12.1087C39.1111 12.7498 39.3717 13.5487 39.3717 14.5087L39.375 14.5122Z"
        fill="#2563EB"
      />
      <path
        d="M52.3604 14.5122C52.3604 15.9136 51.7624 17.0453 50.563 17.9037C49.3637 18.7621 47.7233 19.1896 45.6386 19.1896C44.486 19.1896 43.4404 19.0774 42.5049 18.8497C41.5662 18.6255 40.8111 18.3767 40.2432 18.0999V14.5192H40.624C40.8345 14.6699 41.0717 14.838 41.3457 15.0202C41.6196 15.2024 42.0038 15.3986 42.5049 15.6053C42.9326 15.791 43.4203 15.9522 43.9682 16.0818C44.5128 16.215 45.1008 16.2815 45.7221 16.2815C46.5373 16.2815 47.1387 16.1869 47.5295 15.9977C47.9238 15.8085 48.1175 15.5282 48.1175 15.1569C48.1175 14.831 48.0039 14.5893 47.7701 14.4386C47.5396 14.288 47.1019 14.1478 46.4571 14.0112C46.1498 13.9376 45.7322 13.8605 45.2077 13.7764C44.6798 13.6923 44.2021 13.5907 43.7711 13.4681C42.5951 13.1458 41.7265 12.6447 41.1552 11.9615C40.5873 11.2818 40.3033 10.4339 40.3033 9.41788C40.3033 8.11101 40.8947 7.02488 42.074 6.15948C43.2533 5.29408 44.8669 4.85963 46.9115 4.85963C47.877 4.85963 48.8091 4.96474 49.6944 5.16795C50.583 5.37466 51.2746 5.59189 51.7724 5.82663V9.26021H51.4183C50.8002 8.81875 50.0986 8.45788 49.3135 8.18109C48.5251 7.9043 47.7266 7.76415 46.9215 7.76415C46.2533 7.76415 45.6887 7.86226 45.231 8.05496C44.7733 8.24766 44.5462 8.52094 44.5462 8.87831C44.5462 9.20065 44.6497 9.44591 44.8602 9.61408C45.0673 9.78226 45.5551 9.95394 46.3235 10.1221C46.7478 10.2097 47.2021 10.2973 47.6932 10.3814C48.181 10.4655 48.6688 10.5741 49.1632 10.7002C50.2557 11.0015 51.0608 11.4675 51.5853 12.1087C52.1065 12.7499 52.3671 13.5487 52.3671 14.5087L52.3604 14.5122Z"
        fill="#2563EB"
      />
      <path
        d="M57.8092 3.3005H53.416V6.10352e-05H57.8092V3.3005ZM57.6956 18.8147H53.5329V5.23802H57.6956V18.8147Z"
        fill="#2563EB"
      />
      <path
        d="M73.0637 12.0316C73.0637 14.2634 72.4457 16.0258 71.2062 17.3116C69.9635 18.5974 68.2195 19.2386 65.9745 19.2386C63.7295 19.2386 61.9889 18.5974 60.7494 17.3116C59.5067 16.0258 58.8853 14.2669 58.8853 12.0316C58.8853 9.79628 59.51 8.01642 60.7595 6.73408C62.0089 5.45175 63.7495 4.81058 65.9745 4.81058C68.1995 4.81058 69.9835 5.45525 71.2163 6.74459C72.4524 8.03394 73.0671 9.79277 73.0671 12.0281L73.0637 12.0316ZM67.9556 15.332C68.2262 14.9852 68.4267 14.5682 68.5603 14.0812C68.6939 13.5942 68.7607 12.9215 68.7607 12.0561C68.7607 11.2573 68.6906 10.5881 68.5536 10.0485C68.4166 9.50898 68.2229 9.07803 67.979 8.75219C67.7318 8.42284 67.4344 8.1881 67.0903 8.05496C66.7429 7.91832 66.3721 7.84824 65.9712 7.84824C65.5703 7.84824 65.2161 7.9043 64.9054 8.01642C64.5947 8.12854 64.2941 8.35277 64.0101 8.69262C63.7562 9.00795 63.5557 9.4389 63.4054 9.98547C63.2551 10.5355 63.1816 11.2223 63.1816 12.0561C63.1816 12.7954 63.245 13.4401 63.3787 13.9831C63.509 14.5297 63.6994 14.9642 63.9533 15.2935C64.2005 15.6088 64.4945 15.8366 64.8353 15.9837C65.176 16.1274 65.5669 16.201 66.0046 16.201C66.3821 16.201 66.7429 16.1344 67.0837 16.0012C67.4278 15.8681 67.7184 15.6439 67.9556 15.3285V15.332Z"
        fill="#2563EB"
      />
      <path
        d="M87.3124 18.8147H83.1497V12.0807C83.1497 11.5306 83.1197 10.984 83.0662 10.4409C83.0128 9.89788 82.9192 9.49496 82.7923 9.23919C82.6386 8.94138 82.4114 8.72416 82.1174 8.58751C81.8234 8.45087 81.4092 8.3808 80.8813 8.3808C80.5038 8.3808 80.1196 8.44737 79.7321 8.5735C79.3445 8.70313 78.9203 8.90635 78.4693 9.19014V18.8147H74.3066V5.23802H78.4693V6.73759C79.2076 6.13145 79.9158 5.66897 80.594 5.34664C81.2722 5.0243 82.0272 4.86313 82.8591 4.86313C84.2556 4.86313 85.348 5.29058 86.1331 6.14547C86.9215 7.00036 87.3124 8.27919 87.3124 9.97846V18.8182V18.8147Z"
        fill="#2563EB"
      />
      <path
        d="M100.796 14.5122C100.796 15.9136 100.198 17.0453 98.9985 17.9037C97.7991 18.7621 96.1554 19.1896 94.0741 19.1896C92.9215 19.1896 91.8758 19.0774 90.9371 18.8497C89.9983 18.6255 89.2466 18.3767 88.6787 18.0999V14.5192H89.0595C89.2666 14.6698 89.5072 14.838 89.7778 15.0202C90.0517 15.2024 90.4393 15.3986 90.9371 15.6053C91.368 15.791 91.8558 15.9522 92.4003 16.0818C92.9482 16.215 93.5329 16.2815 94.1543 16.2815C94.9694 16.2815 95.5708 16.1869 95.965 15.9977C96.3592 15.8085 96.553 15.5282 96.553 15.1569C96.553 14.831 96.4394 14.5893 96.2089 14.4386C95.9784 14.288 95.5407 14.1478 94.8959 14.0112C94.5886 13.9376 94.1676 13.8605 93.6465 13.7764C93.1186 13.6923 92.6375 13.5907 92.2099 13.4681C91.034 13.1458 90.162 12.6447 89.5907 11.9615C89.0228 11.2818 88.7388 10.4339 88.7388 9.41788C88.7388 8.11101 89.3301 7.02488 90.5094 6.15948C91.6887 5.29408 93.3024 4.85962 95.3469 4.85962C96.3124 4.85962 97.2412 4.96473 98.1299 5.16795C99.0152 5.37466 99.7101 5.59189 100.208 5.82663V9.26021H99.8504C99.2357 8.81875 98.5341 8.45787 97.7457 8.18109C96.9572 7.9043 96.1588 7.76415 95.3536 7.76415C94.6821 7.76415 94.1209 7.86225 93.6632 8.05495C93.2055 8.24765 92.9783 8.52094 92.9783 8.87831C92.9783 9.20065 93.0819 9.4459 93.289 9.61408C93.4961 9.78226 93.9839 9.95393 94.7523 10.1221C95.1766 10.2097 95.6309 10.2973 96.1187 10.3814C96.6064 10.4655 97.0975 10.5741 97.5886 10.7002C98.6777 11.0015 99.4862 11.4675 100.011 12.1087C100.532 12.7498 100.796 13.5487 100.796 14.5087V14.5122Z"
        fill="#2563EB"
      />
    </svg>
  );
};
