import {
  LessonNav,
  ActiveSessionStateSummaryOutput,
} from "@arena-active/trpc-client";
import { NavSection, SectionItem } from "./NavSection";
import { activityToSectionItem, isSessionAvailable } from "../state/util";

export interface SideNavProps {
  lesson: LessonNav;
  activeSessionState: ActiveSessionStateSummaryOutput;
  selectedActivityId: number | null;
  onSelectActivity: (activityId: number) => void;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SideNav: React.FC<SideNavProps> = ({
  lesson,
  activeSessionState,
  selectedActivityId,
  onSelectActivity,
}) => {
  const getSectionItems = (
    activities: LessonNav["sessions"][number]["activities"],
    selectedActivityId: number | null,
  ): SectionItem[] => {
    const items = activities.map((activity) =>
      activityToSectionItem(
        lesson,
        activeSessionState.activities,
        activity,
        selectedActivityId,
      ),
    );
    return items;
  };

  if (!lesson || !activeSessionState) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {lesson.sessions.map((session, index) => {
        const isAvailable = isSessionAvailable(
          index,
          lesson,
          activeSessionState.activities,
        );
        return (
          <div key={session.id} style={{ marginBottom: "20px" }}>
            <NavSection
              title={session.title}
              isShowLabel={true}
              isShowTitle={true}
              isShowIcon={true}
              isShowPosition={true}
              isShowSectionTitle={true}
              items={getSectionItems(session.activities, selectedActivityId)}
              isSectionAvailable={isAvailable}
              onClickItem={(itemId: number, isComplete: boolean) => {
                if (isComplete) {
                  onSelectActivity(itemId);
                }
              }}
            />
          </div>
        );
      })}
    </div>
  );
};
