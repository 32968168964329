import {
  forwardRef,
  useState,
  useMemo,
  useEffect,
  useImperativeHandle,
  HTMLAttributes,
  DetailedHTMLProps,
} from "react";
import {
  useMediaDevices,
  useMedia,
  useMediaRecorder,
  closeMedia,
  VideoPlayer as RUMVideoPlayer,
} from "@smashcut/react-user-media";
import { UploadBlob } from "../../storage";
import { Skeleton } from "primereact/skeleton";
import { classNames } from "primereact/utils";
import { useBlobUrl } from "../../util/use-blob-url";
import { AudioPlayer } from "../audioPlayer/AudioPlayer";
import { Tooltip } from "../menu/Tooltip";
import { Option, SettingsMenu } from "../audioPlayer/SettingsMenu";
import {
  SettingsIcon,
  Rec,
  Mic,
  Red,
  CameraOnIcon,
  CameraOffIcon,
  MicMini,
  CamMini,
} from "../common/Icons";
import { VoiceAnimation } from "../common/VoiceAnimation";
import { SimpleTranscriptViewer } from "./SimpleTranscriptViewer";
import { SimpleVideoPlayer } from "./SimpleVideoPlayer";
import { RecorderIcon } from "./RecorderIcon";
import {
  getSupportedAudioRecordingTypes,
  getSupportedVideoRecordingTypes,
} from "../../util/media";
import styles from "./UserMediaRecorder.module.css";
import { RecorderTimer } from "./RecorderTimer";
import { AudioVisualizer } from "./AudioVisualizer";

/**
 * We forward _most_ `HTMLDivElement` props to our topmost `<div />` via {@link UserMediaRecorderProps}.
 */
type DivForwardedProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  keyof Pick<
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
    "ref" | "itemRef"
  >
>;

export interface UserMediaRecorderProps extends DivForwardedProps {
  /**
   * Optional event handler that'll be invoked when recording has been completed.
   *
   * Default: `() => {}`
   *
   * Note: Will be raised twice in React "Strict" Mode for Development.
   *
   * @param audioMedia the recorded audio (if any).
   * @param videoMedia the recorded video (if any).
   */
  onMediaRecorded?: (audioMedia: UploadBlob, videoMedia: UploadBlob) => void;

  /**
   * Optional event handler that'll be invoked when the user issues a request
   * to edit a transcripts text.
   *
   * Default: `() => {}`
   *
   * @param text the current full transcript text
   */
  onRequestTranscriptEdit?: (text: string | undefined) => void;

  /**
   * Optional event handler that'll be invoked when the user requests
   * usage of text input over rich-response mediums like audio or video.
   *
   * Default: `() => {}`
   */
  onRequestTextInput?: () => void;

  /**
   * Optional initial constraints for the user media request.
   *
   * Default: `{audio: true, video: true}`
   *
   * Note: The user may change this within the component after the initial render.
   *
   * __Note: Video only is not supported at the moment__ (as we want transcription).
   */
  initialConstraints?: MediaStreamConstraints;

  transcribeError?: Error | null;

  onRetry?: () => void;

  children?: string | JSX.Element | JSX.Element[];
}

export interface UserMediaRecorderAPI {
  /**
   * Obtains the current state of the {@link UserMediaRecorder} instance.
   *
   * - `initial` indicates the component is loaded, waiting for user input.
   * - `recording` indicates media is being recorded.
   * - `previewing` indicates media is being previewed.
   * (at which time {@link UserMediaRecorderProps.onTranscriptEdited} may be raised).
   */
  getState(): "initial" | "recording" | "previewing";

  /**
   * Resets the internal state of the {@link UserMediaRecorder} instance.
   *
   * Note: This frees any active media streams, recordings content, media device preferences, etc.
   */
  reset(): void;

  /**
   * Sets transcribed text (obtained from audio data, see {@link UserMediaRecorderProps.onMediaRecorded})
   * for the active recording.
   *
   * Note: This text will only be displayed until the user navigates away from the active recording,
   * or {@link reset} is invoked.
   *
   * @param text the transcribed text.
   */
  setTranscriptionText(text: string | undefined): void;

  /**
   * For testing. Starts the recording.
   */
  startRecording(): void;
}

const defaultProps = {
  onMediaRecorded: () => {},
  onRequestTranscriptEdit: () => {},
  onRequestTextInput: () => {},
  onRetry: () => {},
  initialConstraints: { audio: true, video: true },
} satisfies Partial<UserMediaRecorderProps>;

/**
 * Settings-menu values when audio and video constraints are used.
 */
const avSettings = {
  Microphone: {
    id: "System Default",
    label: "System Default (Microphone)",
    kind: "audioinput",
  },
  Webcam: {
    id: "System Default",
    label: "System Default (Webcam)",
    kind: "videoinput",
  },
};

/**
 * Settings-menu values when audio only constraints are used.
 */
const audioSettings = {
  Microphone: {
    id: "System Default",
    label: "System Default (Microphone)",
    kind: "audioinput",
  },
};

export const UserMediaRecorder = forwardRef<
  UserMediaRecorderAPI,
  UserMediaRecorderProps
>(function UserMediaRecorder(props, apiRef) {
  const {
    onMediaRecorded,
    onRequestTranscriptEdit,
    onRequestTextInput,
    initialConstraints,
    transcribeError,
    children,
    onRetry,
    ...divForwardedProps
  } = {
    ...defaultProps,
    ...props,
  };
  const [isSettingsMenuVisible, setIsSettingsMenuVisible] = useState(false);
  const [transcriptionText, setTranscriptionText] = useState<string>();

  /**
   * The user media constraints for the obtained media stream.
   *
   * Note: This is initially {@link initialConstraints} but may then be mutated by the user.
   */
  const [userMediaConstraints, setUserMediaConstraints] =
    useState<MediaStreamConstraints>(initialConstraints);

  /**
   * Indicates that we have an audio-only constraint, meaning no video
   * should be captured/recorded.
   *
   * We further optimize recorder count based on this value, skipping
   * video recording when this value is `true`.
   */
  const hasAudioOnlyConstraint = useMemo(
    () => userMediaConstraints?.audio && !userMediaConstraints.video,
    [userMediaConstraints],
  );

  const {
    isError: hasDevicesError,
    isReady: isDevicesReady,
    error: devicesError,
    devices,
    request: requestDevices,
  } = useMediaDevices({
    deviceChangedEvent: false,
  });

  /**
   * A map of media devices available on the system indexed by their label.
   */
  const deviceMap = useMemo(
    () =>
      [
        {
          deviceId: "sys_default",
          label: "System Default (Microphone)",
          kind: "audioinput",
        },
        {
          deviceId: "sys_default",
          label: "System Default (Webcam)",
          kind: "videoinput",
        },
      ]
        .concat(devices ?? [])
        .reduce(
          (p, c) => {
            p[c.label] = {
              id: c.deviceId,
              label: c.label,
              kind: c.kind,
            };

            return p;
          },
          {} as Record<string, Option & { kind: string }>,
        ),
    [devices],
  );

  const {
    isError: hasUserMediaError,
    isLoading: isUserMediaLoading,
    isReady: isUserMediaReady,
    error: userMediaError,
    media: userMedia,
    request: requestUserMedia,
  } = useMedia("user");

  /**
   * The extracted {@link userMedia} audio tracks.
   */
  const audioOnlyUserMedia = useMemo(
    () => new MediaStream(userMedia?.getAudioTracks() ?? []),
    [userMedia],
  );

  /**
   * The extracted `label` from the first audio track within {@link userMedia}.
   *
   * Note: If no such audio track exists, the value will be `System Default`.
   *
   */
  const audioDeviceLabel = useMemo(
    () => userMedia?.getAudioTracks().at(0)?.label ?? "System Default",
    [userMedia],
  );

  /**
   * the audio visualizer component takes it's input as a `MediaRecorder`, even though it just
   * accesses it's `.stream` property. As such, we need a dedicated recorder for passing that
   *  stream to the visualizer.
   */
  const audioVisualizerRecorder = useMemo(
    () => new MediaRecorder(audioOnlyUserMedia),
    [audioOnlyUserMedia],
  );

  const {
    isError: hasAudioRecorderError,
    isRecording: isRecordingAudio,
    isFinalized: isRecordingAudioFinalized,
    error: audioRecorderError,
    segments: audioSegments,
    mimeType: audioMimeType,
    startRecording: startRecordingAudio,
    stopRecording: stopRecordingAudio,
  } = useMediaRecorder();

  /**
   * The {@link audioSegments} combined into a single {@link Blob} when {@link isRecordingAudioFinalized} is `true`.
   *
   * By default, this is an empty `Blob` until {@link isRecordingAudioFinalized} becomes `true`.
   */
  const recordedAudio = useMemo(
    () =>
      isRecordingAudioFinalized
        ? new Blob(audioSegments, { type: audioMimeType })
        : new Blob([]),
    [isRecordingAudioFinalized, audioSegments, audioMimeType],
  );

  const {
    isError: hasVideoRecorderError,
    isFinalized: isRecordingVideoFinalized,
    error: videoRecorderError,
    segments: videoSegments,
    mimeType: videoMimeType,
    startRecording: startRecordingVideo,
    stopRecording: stopRecordingVideo,
  } = useMediaRecorder();

  /**
   * The {@link videoSegments} combined into a single {@link Blob} when {@link isRecordingVideoFinalized} is `true`.
   *
   * By default, this is an empty `Blob` until {@link isRecordingVideoFinalized} becomes `true`.
   */
  const recordedVideo = useMemo(
    () =>
      isRecordingVideoFinalized
        ? new Blob(videoSegments, { type: videoMimeType })
        : new Blob([]),
    [isRecordingVideoFinalized, videoSegments, videoMimeType],
  );

  /**
   * A {@link Blob} URL representing the contents of {@link recordedVideo}.
   *
   * Required for playback on most devices, as most browsers never implemented direct Blob playback.
   */
  const recordedVideoUrl = useBlobUrl(recordedVideo);

  useEffect(
    function requestUserMediaImplicitly() {
      // our previous implementation relied on this being requested when the component mounts
      // as such, we emulate that here. However, it's recommended to instead request media from
      // within a user-event handler, to ensure permissions are granted.
      requestUserMedia(userMediaConstraints);
    },
    [userMediaConstraints, requestUserMedia],
  );

  useEffect(
    function requestUserDevicesImplicitly() {
      // our previous implementation relied on this being requested when the component mounts
      // and we have sufficient permissions. The best way to ensure the permissions in an
      // implicit context is to wait until after the userMedia is obtained already.
      //
      // It's recommended to instead request devices from within a user-event handler,
      // to ensure permissions are granted directly.
      if (isUserMediaReady) {
        requestDevices();
      }
    },
    [isUserMediaReady, requestDevices],
  );

  // observe recorded media and inform the caller by raising the `onMediaRecorded` event
  useEffect(
    function raiseMediaRecordedEvent() {
      if (isRecordingAudioFinalized) {
        onMediaRecorded(recordedAudio, recordedVideo);
      }
    },
    [isRecordingAudioFinalized, recordedAudio, recordedVideo, onMediaRecorded],
  );

  // setup our refApi
  useImperativeHandle(
    apiRef,
    function createApi() {
      return {
        getState() {
          return isRecordingAudio
            ? ("recording" as const)
            : isRecordingAudioFinalized
            ? ("previewing" as const)
            : ("initial" as const);
        },
        setTranscriptionText(text) {
          setTranscriptionText(text);
        },
        reset() {
          if (isUserMediaReady) {
            closeMedia(userMedia);
          }

          // we'll start recording from an empty stream to zero out segments values
          const emptyStream = new MediaStream();

          stopRecordingAudio();

          try {
            startRecordingAudio(emptyStream);
          } catch (_) {
            /* swallow */
          }
          stopRecordingAudio();

          if (!hasAudioOnlyConstraint) {
            stopRecordingVideo();
            try {
              startRecordingVideo(emptyStream);
            } catch (_) {
              /* swallow */
            }
            stopRecordingVideo();
          }

          requestUserMedia(userMediaConstraints);
          requestDevices();

          setTranscriptionText(undefined);
          setUserMediaConstraints(initialConstraints);
          console.log("reset done");
        },
        startRecording() {
          if (!isRecordingAudio) {
            startRecordingAudio(audioOnlyUserMedia, {
              timeslice: 1000,
              mimeType: getSupportedAudioRecordingTypes()[0],
            });

            if (!hasAudioOnlyConstraint)
              userMedia &&
                startRecordingVideo(userMedia, {
                  timeslice: 1000,
                  mimeType: getSupportedVideoRecordingTypes()[0],
                });
          }
        },
      };
    },
    [
      isRecordingAudio,
      isRecordingAudioFinalized,
      hasAudioOnlyConstraint,
      startRecordingAudio,
      startRecordingVideo,
      stopRecordingAudio,
      stopRecordingVideo,
      isUserMediaReady,
      audioOnlyUserMedia,
      userMedia,
      requestUserMedia,
      initialConstraints,
      userMediaConstraints,
      requestDevices,
    ],
  );

  if (!userMediaConstraints.audio && userMediaConstraints.video) {
    throw new Error(
      `Video-only constraints are not supported. Did you mean to enable audio and video?`,
    );
  }

  if (hasDevicesError) {
    return (
      <h2>
        Error:{" "}
        <small>
          {devicesError.message}
          <br />
          {devicesError?.stack}
        </small>
      </h2>
    );
  }

  if (hasUserMediaError) {
    return (
      <h2>
        Error:{" "}
        <small>
          {userMediaError.message}
          <br />
          {userMediaError?.stack}
        </small>
      </h2>
    );
  }

  if (hasAudioRecorderError) {
    return (
      <h2>
        Error:{" "}
        <small>
          {audioRecorderError.message}
          <br />
          {audioRecorderError?.stack}
        </small>
      </h2>
    );
  }

  if (hasVideoRecorderError) {
    return (
      <h2>
        Error:{" "}
        <small>
          {videoRecorderError.message}
          <br />
          {videoRecorderError?.stack}
        </small>
      </h2>
    );
  }

  return (
    <>
      <Tooltip hideOnTargetClick target=".tooltip" />
      <div
        {...divForwardedProps}
        className={classNames(
          divForwardedProps.className,
          !isRecordingAudioFinalized && styles.top_container,
          isUserMediaLoading && styles.faded,
          isRecordingAudio && styles.relativePositioned,
          !hasAudioOnlyConstraint && styles.videoTopContainer,
        )}
      >
        <div
          className={classNames(
            styles.background_overlay,
            isRecordingAudio && styles.animate,
          )}
        />
        {!(hasAudioOnlyConstraint || isRecordingAudioFinalized) && (
          <div style={{ maxHeight: "700px" }} className={styles.cam_container}>
            {isUserMediaReady ? (
              <RUMVideoPlayer
                muted
                autoPlay
                playsInline
                controls={false}
                media={userMedia}
                style={{ transform: "scaleX(-1)", width: "100%" }}
              />
            ) : (
              <Skeleton animation="none" shape="rectangle" />
            )}
          </div>
        )}

        {hasAudioOnlyConstraint ? (
          isRecordingAudioFinalized ? (
            <div className={styles.recordedAudioContainer}>
              <AudioPlayer
                showAudioVisualizer={false}
                capturedAudio={recordedAudio}
              />
              <SimpleTranscriptViewer
                text={transcriptionText}
                onEditClicked={onRequestTranscriptEdit}
                transcribeError={transcribeError}
                onRetry={onRetry}
              />
              {children}
            </div>
          ) : (
            <div className={styles.recorder_container}>
              {isRecordingAudio && audioOnlyUserMedia && (
                <AudioVisualizer
                  isRecordingAudio={isRecordingAudio}
                  media={audioOnlyUserMedia}
                />
              )}
            </div>
          )
        ) : (
          <div />
        )}

        {isUserMediaReady && (
          <div
            className={classNames(
              styles.control_buttons,
              !hasAudioOnlyConstraint && styles.absolutePositioned,
              isRecordingAudioFinalized && styles.hidden,
            )}
          >
            <div className={styles.button_container}>
              {!isRecordingAudio && (
                <RecorderIcon
                  onClick={() => setIsSettingsMenuVisible(true)}
                  SVG={<SettingsIcon />}
                  className={classNames("tooltip", styles.setting_icon)}
                  dataPrTooltip="Settings"
                  dataPrPosition="top"
                  dataPrAt="center top-10"
                  dataPrDisabled={isSettingsMenuVisible}
                />
              )}
              {userMediaConstraints.audio && (
                <RecorderIcon
                  SVG={<VoiceAnimation source={audioVisualizerRecorder} />}
                  className={classNames(
                    "tooltip",
                    styles.details,
                    !hasAudioOnlyConstraint && styles.overLay,
                  )}
                  dataPrTooltip={audioDeviceLabel}
                  dataPrPosition="top"
                  dataPrAt="center top-10"
                />
              )}
            </div>

            <div
              className={classNames(
                styles.recorderButtonContainer,
                hasAudioOnlyConstraint && !isRecordingAudio && styles.ready,
              )}
            >
              {hasAudioOnlyConstraint && !isRecordingAudio && (
                <div className={styles.recordAudioTooltip}>Record Audio</div>
              )}
              <RecorderIcon
                SVG={
                  isRecordingAudio ? (
                    <Rec />
                  ) : hasAudioOnlyConstraint ? (
                    <Mic />
                  ) : (
                    <Red />
                  )
                }
                onClick={function onRecordingButtonToggled() {
                  if (isRecordingAudio) {
                    stopRecordingAudio();

                    if (!hasAudioOnlyConstraint) stopRecordingVideo();

                    closeMedia(userMedia);
                  } else {
                    startRecordingAudio(audioOnlyUserMedia, {
                      // must be 1K for audio for transcription on iOS
                      // see https://community.openai.com/t/whisper-problem-with-audio-mp4-blobs-from-safari/322252/4
                      timeslice: 1000,
                      mimeType: getSupportedAudioRecordingTypes()[0],
                    });

                    if (!hasAudioOnlyConstraint)
                      startRecordingVideo(userMedia, {
                        timeslice: 1000,
                        // use the _first supported_ mimeType for the platform
                        mimeType: getSupportedVideoRecordingTypes()[0],
                      });
                  }
                }}
                className={classNames(
                  "tooltip",
                  styles.record_button,
                  isRecordingAudio && styles.recording,
                )}
              />
            </div>
            <div className={styles.timer_container}>
              {isRecordingAudio ? (
                <RecorderTimer punctuation="." />
              ) : (
                <>
                  {userMediaConstraints.video && (
                    <RecorderIcon
                      onClick={() =>
                        setUserMediaConstraints((p) => ({
                          ...p,
                          // swap between video disabled and video from the initial constraints
                          // unless the initial constraint is false, in which case swap to true
                          video: p.video
                            ? false
                            : initialConstraints.video === false
                            ? true
                            : initialConstraints.video,
                        }))
                      }
                      SVG={
                        hasAudioOnlyConstraint ? (
                          <CameraOnIcon />
                        ) : (
                          <CameraOffIcon />
                        )
                      }
                      className="tooltip"
                      dataPrTooltip="Switch to Audio only"
                      dataPrPosition="top"
                      dataPrAt="center top-10"
                    />
                  )}
                  {isSettingsMenuVisible && isDevicesReady && (
                    <SettingsMenu
                      side="left"
                      onSwitchToText={onRequestTextInput}
                      closeSettingsMenu={() => setIsSettingsMenuVisible(false)}
                      settingsState={
                        hasAudioOnlyConstraint ? audioSettings : avSettings
                      }
                      hasAudioOnlyConstraint={hasAudioOnlyConstraint}
                      updateSettingsState={(_name, opt) => {
                        if (opt.kind.startsWith("audio")) {
                          setUserMediaConstraints((p) => ({
                            ...p,
                            audio:
                              typeof p.audio === "boolean"
                                ? { deviceId: opt.id }
                                : { ...p.audio, deviceId: opt.id },
                          }));
                        } else {
                          setUserMediaConstraints((p) => ({
                            ...p,
                            video:
                              typeof p.video === "boolean"
                                ? { deviceId: opt.id }
                                : { ...p.video, deviceId: opt.id },
                          }));
                        }
                      }}
                      menuItems={[
                        {
                          name: "Microphone",
                          icon: <MicMini />,
                          label: "Microphone",
                          value: "System Default",
                          options: Object.values(deviceMap).filter(
                            (d) => d.kind === "audioinput",
                          ),
                        },
                      ].concat(
                        hasAudioOnlyConstraint
                          ? []
                          : [
                              {
                                name: "Webcam",
                                icon: <CamMini />,
                                label: "Webcam",
                                value: "System Default",
                                options: Object.values(deviceMap).filter(
                                  (d) => d.kind === "videoinput",
                                ),
                              },
                            ],
                      )}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames(
          styles.top_container,
          divForwardedProps.className,
          !isRecordingVideoFinalized && styles.cam_container,
          !isRecordingVideoFinalized && styles.hidden,
        )}
      >
        {isRecordingVideoFinalized && (
          <>
            <div className={styles.videoContainer}>
              <SimpleVideoPlayer src={recordedVideoUrl} controls={false} />
              <SimpleTranscriptViewer
                text={transcriptionText}
                onEditClicked={onRequestTranscriptEdit}
                transcribeError={transcribeError}
                onRetry={onRetry}
              />
            </div>
            {children}
          </>
        )}
      </div>
    </>
  );
});
