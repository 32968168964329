import { initSentry } from "@arena-active/client-lib";

const isProduction = import.meta.env.VITE_STACK === "prod" ? true : false;

/**
 * Initialize Sentry for error tracking for this app.
 *
 * Keeping DSN public as it is safe to do so, and saves a bunch of env-passing.
 * Per sentry docs:
 * DSNs are safe to keep public because they only allow submission of new events and related event data;
 * they do not allow read access to any information.
 */
export function setupSentryForStudentApp() {
  initSentry(
    `https://f46a4d834f52b0185bd9924a1d1354a4@o4507930031882240.ingest.us.sentry.io/4507930039156736`,
    isProduction,
    import.meta.env.VITE_STACK,
  );
}
