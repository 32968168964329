import React from "react";
import styles from "./SeparatorWithChildren.module.css";
import { classNames } from "primereact/utils";

export interface SeparatorWithChildrenProps {
  className?: string;
  children: React.ReactNode;
  align?: "left" | "center" | "right";
  lineThickness?: number;
}

export const SeparatorWithChildren: React.FC<SeparatorWithChildrenProps> = ({
  className,
  align = "center",
  children,
  lineThickness = 2,
}) => {
  return (
    <div className={classNames(styles.lineContainer, className)}>
      {align != "left" && (
        <div
          className={styles.line}
          style={{ borderBottomWidth: lineThickness }}
        />
      )}
      <div>{children}</div>
      {align != "right" && (
        <div
          className={styles.line}
          style={{ borderBottomWidth: lineThickness }}
        />
      )}
    </div>
  );
};

export const TextLessonSeparator = ({
  children,
}: SeparatorWithChildrenProps) => (
  <SeparatorWithChildren align={"left"} lineThickness={2} children={children} />
);

export const ExerciseSeparator = ({ children }: SeparatorWithChildrenProps) => (
  <SeparatorWithChildren
    align={"center"}
    lineThickness={1}
    children={children}
  />
);
