import "primeicons/primeicons.css";

import React, { CSSProperties } from "react";
import { PrimeIcons } from "primereact/api";

export interface IconProps extends Pick<CSSProperties, "fontSize" | "color"> {
  /**
   * The icon classes to use - See https://primereact.org/icons/#list
   */
  className: keyof typeof PrimeIcons;

  /**
   * Additional CSS styling to add to the icon `<i>` element.
   */
  style?: Omit<CSSProperties, keyof Pick<CSSProperties, "fontSize" | "color">>;
}

const defaultProps = {
  fontSize: "1rem",
  color: "var(--primary-color)",
} satisfies Partial<IconProps>;

/**
 * A PrimeReact icon.
 */
export const Icon: React.FC<IconProps> = (props) => {
  const { fontSize, color, className, style } = { ...defaultProps, ...props };
  return (
    <i
      className={PrimeIcons[className]}
      style={{ ...style, fontSize, color }}
    ></i>
  );
};
