import { SeparatorWithChildren } from "@arena-active/client-lib";
import { Button } from "@arena-active/client-lib";

export interface ContinueFooterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onComplete: (response: any) => void;
  isLoading: boolean;
}

export const ContinueFooter: React.FC<ContinueFooterProps> = ({
  onComplete,
  isLoading,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "60px",
        position: "absolute",
        bottom: 20,
      }}
    >
      <SeparatorWithChildren>
        <Button
          label="Continue"
          disabled={isLoading}
          onClick={() => onComplete(null)}
        />
      </SeparatorWithChildren>
    </div>
  );
};
