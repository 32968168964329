export const ErrorIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0717 6.33683C16.4533 4.33516 18.1433 3.3335 20 3.3335C21.8567 3.3335 23.5467 4.3335 26.9283 6.33683L28.0717 7.0135C31.4533 9.01683 33.1433 10.0185 34.0717 11.6668C35 13.3168 35 15.3168 35 19.3235V20.6768C35 24.6818 35 26.6852 34.0717 28.3335C33.1433 29.9818 31.4533 30.9835 28.0717 32.9852L26.9283 33.6635C23.5467 35.6652 21.8567 36.6668 20 36.6668C18.1433 36.6668 16.4533 35.6668 13.0717 33.6635L11.9283 32.9852C8.54667 30.9852 6.85667 29.9818 5.92833 28.3335C5 26.6835 5 24.6835 5 20.6768V19.3235C5 15.3168 5 13.3152 5.92833 11.6668C6.85667 10.0185 8.54667 9.01683 11.9283 7.0135L13.0717 6.33683ZM21.6667 26.6668C21.6667 27.1089 21.4911 27.5328 21.1785 27.8453C20.8659 28.1579 20.442 28.3335 20 28.3335C19.558 28.3335 19.134 28.1579 18.8215 27.8453C18.5089 27.5328 18.3333 27.1089 18.3333 26.6668C18.3333 26.2248 18.5089 25.8009 18.8215 25.4883C19.134 25.1758 19.558 25.0002 20 25.0002C20.442 25.0002 20.8659 25.1758 21.1785 25.4883C21.4911 25.8009 21.6667 26.2248 21.6667 26.6668ZM20 10.4168C20.3315 10.4168 20.6495 10.5485 20.8839 10.7829C21.1183 11.0174 21.25 11.3353 21.25 11.6668V21.6668C21.25 21.9984 21.1183 22.3163 20.8839 22.5507C20.6495 22.7851 20.3315 22.9168 20 22.9168C19.6685 22.9168 19.3505 22.7851 19.1161 22.5507C18.8817 22.3163 18.75 21.9984 18.75 21.6668V11.6668C18.75 11.3353 18.8817 11.0174 19.1161 10.7829C19.3505 10.5485 19.6685 10.4168 20 10.4168Z"
      fill="#DC2626"
    />
  </svg>
);
