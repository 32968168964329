import { standaloneTRPCClient } from "@arena-active/trpc-client";
import { StorageClient } from "./index.js";
import { MultiPartUpload, StorageService } from "./types.js";

export function makeStorageServices(trpcClientUrl: string): {
  client: StorageClient;
  service: StorageService;
} {
  const trpcClient = standaloneTRPCClient(trpcClientUrl);

  const getSignedUrl = async (fileName: string) => {
    try {
      const data = await trpcClient.storage.getUploadUrl.query({ fileName });
      return data;
    } catch (error) {
      throw new Error("Failed to get upload url");
    }
  };

  const createMultiPartUpload = async (
    targetPath: string,
    contentType: string,
    bucket: string,
  ) => {
    try {
      const data = await trpcClient.storage.createMultiPartUpload.query({
        targetPath,
        contentType,
        bucket,
      });
      return data;
    } catch (error) {
      throw new Error("Failed to create multi part upload");
    }
  };

  const getPartUploadUrl = async (mpu: MultiPartUpload, partNumber: number) => {
    try {
      const data = await trpcClient.storage.getPartUploadUrl.query({
        mpu,
        partNumber,
      });
      return data;
    } catch (error) {
      throw new Error("Failed to get part upload url");
    }
  };

  const completeMultiPartUpload = async (mpu: MultiPartUpload) => {
    try {
      const data = await trpcClient.storage.completeMultiPartUpload.query(mpu);
      return data;
    } catch (error) {
      throw new Error("Failed to complete multi part upload");
    }
  };

  const abortMultiPartUpload = async (mpu: MultiPartUpload) => {
    try {
      const data = await trpcClient.storage.abortMultiPartUpload.query(mpu);
      return data;
    } catch (error) {
      throw new Error("Failed to abort multi part upload");
    }
  };

  const service = {
    getSignedUrl,
    createMultiPartUpload,
    getPartUploadUrl,
    completeMultiPartUpload,
    abortMultiPartUpload,
  };

  const client = StorageClient(service);

  return { client, service };
}
