import styles from "./App.module.css";
import { PrimeReactProvider } from "primereact/api";
import {
  SignIn,
  SignUp,
  SignedIn,
  SignedOut,
  UserButton,
  useUser,
} from "@clerk/clerk-react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { httpBatchLink } from "@trpc/client";
import { trpc } from "@arena-active/trpc-client";
import React, { useEffect, useState } from "react";
import { useAuth } from "@clerk/clerk-react";
// import { useAuth } from "#lib/auth"; //@clerk/clerk-react";
import { useAtomValue, useSetAtom } from "jotai";
import {
  studentIdAtom,
  tokenAtom,
  lessonAtom,
  requestedPathAtom,
} from "./state/atoms";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import {
  themeAtom,
  ThemeToggle,
  ThemePreview,
  ThemeContainer,
  NavBar,
} from "@arena-active/client-lib";
import { useAtom } from "jotai";
import { LessonViewer } from "./lesson/LessonViewer";
import { useInitializeStateTrackingAnalytics } from "./hooks/useInitializeStateTrackingAnalytics";
import { API_URL } from "./config";

function useNumberParams() {
  const params = useParams();
  const out: Record<string, number> = {};

  Object.keys(params).forEach((key) => {
    if (params[key]) {
      out[key] = parseInt(params[key]!, 10);
    }
  });
  return out;
}

const LessonViewerWithParams: React.FC<{ studentId: number }> = ({
  studentId,
}) => {
  const { lessonId } = useNumberParams();
  if (isNaN(lessonId)) return <Navigate to="/" />;
  return <LessonViewer lessonId={lessonId} studentId={studentId} />;
};

function Layout() {
  const currentLesson = useAtomValue(lessonAtom);

  return (
    <ThemeContainer>
      <NavBar
        subTitle={currentLesson?.origin.course?.title ?? ""}
        authAvatarButton={
          <UserButton
            appearance={{
              layout: {
                shimmer: false,
              },
              elements: {
                rootBox: "w-3rem",
                avatarBox: "h-full w-full",
                userButtonTrigger: "shadow-none",
                userPreviewAvatarContainer: "w-1",
              },
            }}
          />
        }
      />
      <Outlet />
    </ThemeContainer>
  );
}

function InnerApp() {
  useInitializeStateTrackingAnalytics();
  const { isSignedIn, user } = useUser();

  const [theme, setTheme] = useAtom(themeAtom);
  const [studentId, setStudentId] = useAtom(studentIdAtom);

  const [requestedPath, setRequestedPath] = useAtom(requestedPathAtom);

  function HandleBaseUrl() {
    const requestedPath = useAtomValue(requestedPathAtom);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (requestedPath) {
        if (location.pathname !== requestedPath) {
          navigate(requestedPath, { replace: true });
        }
      } else if (window.location.href !== "https://www.sessions.edu") {
        window.location.href = "https://www.sessions.edu";
      }
    }, [requestedPath, navigate, location.pathname]);

    return null;
  }

  /**
   * On first signup the user info is added to the db via a webhook
   * A retry is added here to try again if not found.
   */
  const MAX_RETRIES = 5;
  let retryCount = 0;

  const { data: userData, refetch } = trpc.user.getByAuthId.useQuery(
    { authId: user?.id || "" },
    {
      enabled: !!user && !!user.id,
      onSuccess: (data) => {
        if (data === null && retryCount < MAX_RETRIES) {
          retryCount++;
          setTimeout(() => {
            refetch();
          }, 1000);
        } else if (data === null && retryCount >= MAX_RETRIES) {
          throw new Error(
            `[App.tsx] Failed to load user after ${MAX_RETRIES} retries`,
          );
        }
      },
      onError: (error) => {
        console.error("[App.tsx] Error loading user by auth ID:", error);
      },
    },
  );

  useEffect(() => {
    const loadUser = async () => {
      /**
       * We auth w/ clerk (which we store as authId),
       * but we interact w/ the api using the db PK id.
       * So we need to pull that from the db and set it
       *
       * TODO: We should move away from using PK id in the api altogether
       */
      if (userData) {
        setStudentId(userData.id);
      }
    };
    loadUser();
  }, [isSignedIn, setStudentId, userData, user]);

  useEffect(() => {
    const path = document.location.pathname;
    if (!isSignedIn && !path.startsWith("/sign-up") && path !== "/") {
      setRequestedPath(path);
    }
  }, [isSignedIn, setRequestedPath]);

  const clearUserActivityMutation = trpc.user.clearUserActivity.useMutation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clearUserActivityParam = urlParams.has("clearActivity");
    const apiHost = import.meta.env.VITE_API_HOST;

    if (
      clearUserActivityParam &&
      apiHost !== "active.sessions.edu" &&
      studentId > 0
    ) {
      clearUserActivityMutation.mutate({ userId: studentId });
      sessionStorage.clear();
    }
    // including mutation would cause an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  // can be accessed in both signed in and signed out states
  const signUp = (
    <Route
      path="/sign-up/*"
      element={
        <div className={styles.centeredContainer}>
          <SignUp forceRedirectUrl={requestedPath} />
        </div>
      }
    />
  );

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={<Layout />}>
        <Route path="/">
          <Route index element={<HandleBaseUrl />} />
        </Route>

        <Route
          path="/lesson/:lessonId"
          element={<LessonViewerWithParams studentId={studentId} />}
        />

        <Route
          path="/theme"
          element={
            <div style={{ padding: "1rem" }}>
              <ThemeToggle
                value={theme === "dark"}
                setValue={(enable) => setTheme(enable ? "dark" : "light")}
              />
              <ThemePreview />
            </div>
          }
        ></Route>
        {signUp}
      </Route>,
    ),
  );

  return (
    <>
      <SignedOut>
        <RouterProvider
          router={createBrowserRouter(
            createRoutesFromElements(
              <>
                {signUp}
                <Route
                  path="*"
                  element={
                    <div className={styles.centeredContainer}>
                      <SignIn
                        signUpUrl="/sign-up"
                        forceRedirectUrl={requestedPath}
                      />
                    </div>
                  }
                />
              </>,
            ),
          )}
        />
      </SignedOut>
      <SignedIn>
        <RouterProvider router={router}></RouterProvider>
      </SignedIn>
    </>
  );
}

function App() {
  const [queryClient] = useState(() => new QueryClient());

  const { getToken } = useAuth();

  const setTokenValue = useSetAtom(tokenAtom);

  useEffect(() => {
    if (getToken && setTokenValue) {
      getToken().then((token) => {
        console.log("set the token atom");
        setTokenValue(token);
      });
    }
  }, [getToken, setTokenValue]);

  // TODO: we're creating a client here and in the atoms file
  // should only be creating 1 client.
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: API_URL,
          headers: async () => ({
            Authorization: `Bearer ${await getToken()}`,
          }),
        }),
      ],
    }),
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <PrimeReactProvider>
          <InnerApp />
        </PrimeReactProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}

export default App;
