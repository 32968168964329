import React from "react";
import styles from "./Typography.module.css";
import { classNames } from "primereact/utils";

const variantMap = {
  transcriptMedium: styles.transcriptMedium,
  bodyLarge: styles.bodyLarge,
  bodyMedium: styles.bodyMedium,
  bodyMediumStrong: styles.bodyMediumStrong,
  titleMedium: styles.titleMedium,
  titleLarge: styles.titleLarge,
  titleLargeStrong: styles.titleLargeStrong,
  subtitleMedium: styles.subtitleMedium,
  displaySmall: styles.displaySmall,
  headlineMedium: styles.headlineMedium,
} as const;

const colorMap = {
  title: styles.colorTitle,
  subtitle: styles.colorSubtitle,
  caption: styles.colorCaption,
  transcript: styles.colorTranscript,
  primary: styles.colorPrimary,
  error: styles.colorError,
  success: styles.colorSuccess,
  alert: styles.colorAlert,
  displaySmall: styles.displaySmall,
} as const;

type TypographyProps = {
  variant: keyof typeof variantMap;
  color?: keyof typeof colorMap;
  children: React.ReactNode;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: Record<string, any>;
};

export const Typography: React.FC<TypographyProps> = ({
  variant,
  color,
  children,
  className: outerClassName,
  style,
}) => {
  const className = classNames(
    styles.typeBase,
    variantMap[variant],
    colorMap[color ?? "title"],
    outerClassName,
  );
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};
