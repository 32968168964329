import { useMemo, useRef, useState } from "react";
import { RightArrow, LeftArrow, Check } from "../common/Icons";
import { truncate } from "lodash";
import { useClickAway } from "use-click-away";
import { Button } from "../common/Button";
import styles from "./SettingsMenu.module.css";
import { classNames } from "primereact/utils";

export type Option = {
  id: string;
  label: string;
};

export type MenuItem<TOpt extends Option> = {
  name: string;
  icon: React.ReactElement;
  label: string;
  value: string;
  options: TOpt[];
};

interface SettingsMenuProps<TOpt extends Option> {
  settingsState: Record<string, TOpt>;
  side: "right" | "left";
  updateSettingsState: (menuName: string, option: TOpt) => void;
  closeSettingsMenu: () => void;
  menuItems: Array<MenuItem<TOpt>> | null;
  onSwitchToText?: () => void;
  hasAudioOnlyConstraint?: boolean;
}

export const SettingsMenu = <TOpt extends Option>({
  settingsState,
  hasAudioOnlyConstraint,
  updateSettingsState,
  closeSettingsMenu,
  menuItems,
  onSwitchToText,
  side,
}: SettingsMenuProps<TOpt>) => {
  const clickRef = useRef(null);

  useClickAway(clickRef, () => {
    closeSettingsMenu();
  });

  const [menuItemSelected, setMenuItemSelected] = useState<MenuItem<TOpt>>();

  const selectedId = useMemo(
    () =>
      menuItemSelected ? settingsState[menuItemSelected?.name].id : undefined,
    [settingsState, menuItemSelected],
  );

  const handleClose = () => {
    closeSettingsMenu();
    setMenuItemSelected(undefined);
  };

  const MenuList = (
    <ul className="list-none m-0 py-3 px-0">
      {(menuItems || []).map((item) => (
        <li
          key={item?.name}
          onClick={() => setMenuItemSelected(item)}
          className="flex flex-row justify-content-start align-items-center py-2 px-3 hover:bg-black-alpha-10 cursor-pointer"
        >
          <span className="text-sm font-bold -mb-1 mr-2">{item.icon}</span>
          <span className="text-sm font-bold">{item.label}</span>
          <div className="text-sm ml-auto flex justify-content-center align-items-center">
            <span className="mr-2">
              {truncate(settingsState[item.name].label, {
                length: 20,
              })}
            </span>
            <RightArrow />
          </div>
        </li>
      ))}
    </ul>
  );

  const SelectedMenuItem = (
    <ul className="list-none m-0 py-3 px-0">
      <li
        onClick={() => {
          setMenuItemSelected(undefined);
        }}
        className="flex align-items-center text-sm font-bold hover:bg-black-alpha-10 cursor-pointer px-3 py-2"
      >
        <LeftArrow />
        <span className="ml-2">{menuItemSelected?.label}</span>
      </li>
      {(menuItemSelected?.options || []).map((option) => (
        <li
          key={option?.id}
          onClick={() => {
            if (menuItemSelected?.name) {
              updateSettingsState(menuItemSelected?.name, option);
            }
            handleClose();
          }}
          className="flex align-items-center hover:bg-black-alpha-10 cursor-pointer text-sm font-bold px-3 py-2"
        >
          {selectedId === option.id && <Check />}
          <span className="ml-2">{option.label}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <div
      ref={clickRef}
      className={classNames(
        "absolute border-round-xl",
        styles.container,
        side === "right" ? styles.rightSided : styles.leftSided,
        side === "left" && !hasAudioOnlyConstraint && styles.offset,
      )}
    >
      {menuItemSelected ? SelectedMenuItem : MenuList}

      {onSwitchToText && (
        <div className={styles.switchToTextContainer}>
          <hr />
          <div>Can't use your Microphone?</div>
          <Button
            label="Switch to text"
            type="SECONDARY_FILLED"
            onClick={onSwitchToText}
          />
        </div>
      )}
    </div>
  );
};
