import { atom, useAtom, Atom, WritableAtom } from "jotai";
import { atomEffect } from "jotai-effect";
import { jotaiTRPCClient, Response, DISABLED } from "@arena-active/trpc-client";
import { useState } from "react";
import { atomWithStorage, createJSONStorage, loadable } from "jotai/utils";
import { createTRPCProxyClient, httpLink } from "@trpc/client";
import { API_URL } from "#lib/config.js";
import type { AppRouter } from "@arena-active/api";
import type {
  inferProcedureOutput,
  inferProcedureInput,
  ProcedureOptions,
} from "@arena-active/trpc-client";

export const tokenAtom = atom<string | null>(null);

const trpc = jotaiTRPCClient(API_URL);

type TRPCProxyCLient = ReturnType<typeof createTRPCProxyClient<AppRouter>>;

/**
 * Create a trpc client atom so that we can supply credentials once set.
 * @see https://x.com/dai_shi/status/1567884719450365952
 */
export const clientAtom: Atom<TRPCProxyCLient> = atom((get) =>
  createTRPCProxyClient<AppRouter>({
    links: [
      httpLink({
        url: API_URL,
        headers: {
          Authorization: `Bearer ${get(tokenAtom)}`,
        },
      }),
    ],
  }),
);

export const lessonIdAtom = atom<number>(0);
export const studentIdAtom = atom<number>(0);
export const currentActivityIdAtom = atom<number>(0);
export const firstNonCompletedActivityIdAtom = atom<number>(0);
export const showFooterAtom = atom<boolean>(false);

export const lessonAtom: WritableAtom<
  Promise<
    | inferProcedureOutput<AppRouter["publishedActiveLesson"]["getLessonNav"]>
    | undefined
  >,
  [],
  void
> = trpc.publishedActiveLesson.getLessonNav.atomWithQuery(
  async (get) => {
    const lessonId = get(lessonIdAtom);
    console.log("[lessonAtom] lessonId:", lessonId);
    if (lessonId > 0) {
      return { id: lessonId };
    } else {
      return DISABLED;
    }
  },
  {},
  (get) => get(clientAtom),
);

export const currentActivityAtom = loadable(
  trpc.activity.getActivity.atomWithQuery(
    async (get) => {
      const activityId = get(currentActivityIdAtom);
      const studentId = get(studentIdAtom);
      return activityId > 0 ? { id: activityId, userId: studentId } : DISABLED;
    },
    {},
    (get) => get(clientAtom),
  ),
);
export const activeSessionStateAtom: WritableAtom<
  Promise<
    | inferProcedureOutput<AppRouter["activity"]["getUserActiveSessionState"]>
    | undefined
  >,
  [],
  void
> = trpc.activity.getUserActiveSessionState.atomWithQuery(
  async (get) => {
    const studentId = get(studentIdAtom);
    const lessonId = get(lessonIdAtom);
    return studentId > 0 && lessonId > 0
      ? { userId: studentId, publishedActiveLessonId: lessonId }
      : DISABLED;
  },
  {},
  (get) => get(clientAtom),
);

export const markActivityCompletedAtom: WritableAtom<
  inferProcedureOutput<AppRouter["activity"]["upsertActivityState"]> | null,
  [
    [
      input: inferProcedureInput<AppRouter["activity"]["upsertActivityState"]>,
      opts?: ProcedureOptions | undefined,
    ],
  ],
  Promise<inferProcedureOutput<AppRouter["activity"]["upsertActivityState"]>>
> = trpc.activity.upsertActivityState.atomWithMutation((get) =>
  get(clientAtom),
);

export const isMarkActivityCompleteLoadingAtom = atom<boolean>(false);

export const isNewActivityLoadingAtom = atom<boolean>(false);

/**
 * Is either activity or marking activity as complete loading?
 */
export const isLoadingAtom = atom<boolean>(false);

atomEffect((get, set) => {
  const markLoading = get(isMarkActivityCompleteLoadingAtom);
  const newActivityLoading = get(isNewActivityLoadingAtom);
  set(isLoadingAtom, markLoading || newActivityLoading);
});

/**
 * Hook to expose the Atom for marking activity as complete.
 * @example
 * const { markCompleted, isLoading, error } = useMarkActivityCompleted();
 *
 * const handleCompleteActivity = async () => {
 *   try {
 *     await markCompleted(1, 2, 3, true, { responseText: 'Sample response' });
 *   } catch (err) {
 *     console.error('Failed to complete activity:', err);
 *   }
 * };
 *
 * @returns {Object} - Object containing the following properties: markActivityCompletedResult - the result of the mutation, markCompleted - the function to call to mark an activity as completed, isLoading - a boolean indicating if the mutation is in progress, error - an error object if the mutation fails
 */
export const useMarkActivityCompletedAtom = () => {
  const [markActivityCompletedResult, markActivityCompleted] = useAtom(
    markActivityCompletedAtom,
  );

  // const studentId = useAtomValue(studentIdAtom);
  const [isLoading, setIsLoading] = useAtom(isMarkActivityCompleteLoadingAtom);
  const [error, setError] = useState<Error | null>(null);
  const [, refetchActiveSessionState] = useAtom(activeSessionStateAtom);

  const markCompleted = async (
    id: number | undefined,
    studentId: number,
    activityId: number,
    completed: boolean,
    response: Response | undefined,
  ) => {
    setIsLoading(true);
    setError(null);
    try {
      const data = await markActivityCompleted([
        {
          id,
          userId: studentId,
          activityId,
          completed,
          response: response || undefined,
        },
      ]);
      await refetchActiveSessionState();
      return data;
    } catch (err) {
      setError(err as Error);
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return { markActivityCompletedResult, markCompleted, isLoading, error };
};

// atoms to manage progress information
export const totalStepsAtom = atom<number>(1);
export const completedStepsAtom = atom<number>(1);
export const selectedStepIndexAtom = atom<number | null>(null);

export const progressAtom = atom({ start: 0, finish: 1 });

// update progressAtom whenever totalStepsAtom or completedStepsAtom change
export const updateProgressEffect = atomEffect((get, set) => {
  const totalSteps = get(totalStepsAtom);
  const completedSteps = get(completedStepsAtom);
  const start = totalSteps > 0 ? ((completedSteps - 1) / totalSteps) * 100 : 0;
  const finish = totalSteps > 0 ? (completedSteps / totalSteps) * 100 : 0;
  set(progressAtom, { start, finish });
});

const interStitialSessionStorageStorage = createJSONStorage<number[]>(
  () => sessionStorage,
);
export const interstitialsAtom = atomWithStorage(
  "seenInterstitials",
  [],
  interStitialSessionStorageStorage,
);

// last path a user requested before signing in/up
const storage = createJSONStorage<string>(() => sessionStorage);
export const requestedPathAtom = atomWithStorage<string>(
  "requestedPath",
  "/",
  storage,
);

/**
 * Derived atom that holds the current activity data when loaded.
 */
export const currentActivityLoadedAtom = atom((get) => {
  const currentActivityLoadable = get(currentActivityAtom);

  if (
    currentActivityLoadable.state === "hasData" &&
    currentActivityLoadable.data
  ) {
    const currentActivity = currentActivityLoadable.data;
    return currentActivity;
  }
  // If the lesson data is still loading or has errored, return undefined
  return undefined;
});

export const loadableLessonAtom = loadable(lessonAtom);

/**
 * Derived atom that holds the current lesson data when loaded.
 */
export const lessonLoadedAtom = atom((get) => {
  const lessonLoadable = get(loadableLessonAtom);

  if (lessonLoadable.state === "hasData" && lessonLoadable.data) {
    const lesson = lessonLoadable.data;
    return lesson;
  }
  // If the lesson data is still loading or has errored, return undefined
  return undefined;
});
