import { MotionValue, motion, useSpring, useTransform } from "framer-motion";
import { CSSProperties, useEffect } from "react";
import styles from "./Counter.module.css";

const fontSize = 16;
const padding = 5;
const height = fontSize + padding;

export const Counter: React.FC<{ value: number }> = ({ value }) => {
  const containerStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    gap: "1px",
    overflow: "hidden",
    borderRadius: "0.375rem",
    paddingLeft: "0.1rem",
    lineHeight: "normal",
    fontSize: `${fontSize}px`,
    color: "var(--text-body, #FFF)",
  };

  const showHundreds = Math.floor(value / 100) > 0;
  const showTens = Math.floor(value) >= 10;
  return (
    <div style={containerStyle} className={styles.counter}>
      {showHundreds && <Digit place={100} value={value} />}
      {showTens && <Digit place={10} value={value} />}
      <Digit place={1} value={value} />
      <span style={{ fontSize: `${fontSize}px` }}>%</span>
    </div>
  );
};

function Digit({ place, value }: { place: number; value: number }) {
  const valueRoundedToPlace = Math.floor(value / place);
  const animatedValue = useSpring(valueRoundedToPlace);

  useEffect(() => {
    animatedValue.set(valueRoundedToPlace);
  }, [animatedValue, valueRoundedToPlace]);

  const digitStyle: CSSProperties = {
    position: "relative",
    width: "1ch", // Width of one character
    height: `${height}px`,
    fontFamily: "Noto Sans, sans-serif",
  };

  return (
    <div style={digitStyle}>
      {[...Array(10).keys()].map((i) => (
        <Number key={i} mv={animatedValue} number={i} />
      ))}
    </div>
  );
}

function Number({ mv, number }: { mv: MotionValue<number>; number: number }) {
  const y = useTransform(mv, (latest) => {
    const placeValue = latest % 10;
    const offset = (10 + number - placeValue) % 10;

    let memo = offset * height;

    if (offset > 5) {
      memo -= 10 * height;
    }

    return memo;
  });

  const numberStyle: CSSProperties = {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return <motion.span style={{ ...numberStyle, y }}>{number}</motion.span>;
}
