import { motion, animate } from "framer-motion";
import { useState, useEffect } from "react";
import { Counter } from "./Counter";
import { ProgressHamburger } from "./ProgressHamburger";

const draw = (start: number, finish: number) => ({
  hidden: { pathLength: start, opacity: 0 },
  visible: {
    pathLength: finish > 0.99 ? 1.01 : finish,
    opacity: 1,
    transition: {
      pathLength: { type: "spring", duration: 1.5, bounce: 0 },
    },
  },
});

/**
 * Renders animated progress circle with counter.
 * Note that size is not handled in counter yet (and may be deprecated)
 * @param start - where the progress starts
 * @param finish - where the progress ends
 * @param size - size of the circle TODO - this is not handled in counter yet
 * @returns
 */
export const ProgressCircle: React.FC<{
  start: number;
  finish: number;
  size?: number;
  onShowNav?: () => void;
}> = ({ start, finish, size = 88, onShowNav }) => {
  const [number, setNumber] = useState(start);
  const [isHovered, setIsHovered] = useState(false);
  const duration = (finish - start) / 100;

  useEffect(() => {
    const controls = animate(start, finish, {
      duration: duration,
      onUpdate: (latest) => {
        setNumber(Math.floor(latest));
      },
    });

    return () => controls.stop();
  }, [start, finish, duration]);

  const key = `circle-${start}-${finish}`;
  const strokeWidth = size * 0.05;
  const radius = size / 2 - strokeWidth / 2;
  const foreignObjectSize = (size * 2) / 3;
  const foreignObjectPosition = (size * 1) / 6;

  return (
    <motion.svg
      key={key}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      initial="hidden"
      animate="visible"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onShowNav}
      style={{ userSelect: "none" }}
    >
      {/* Background Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="var(--surface-level-4)"
        strokeWidth={strokeWidth}
        fill="transparent"
      />
      trok
      {/* Incomplete Circle */}
      <circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="var(--surface-level-4)"
        strokeWidth={strokeWidth}
        fill="transparent"
        strokeDasharray={`${Math.PI * 2 * radius}`}
        strokeDashoffset={0}
      />
      {/* Animated Circle */}
      <motion.circle
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="var(--text-body)"
        strokeWidth={strokeWidth}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        fill="transparent"
        variants={draw(start / 100, finish / 100)}
      />
      <foreignObject
        x={foreignObjectPosition}
        y={foreignObjectPosition}
        width={foreignObjectSize}
        height={foreignObjectSize}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: isHovered ? "pointer" : "default",
          }}
        >
          {isHovered ? <ProgressHamburger /> : <Counter value={number} />}
        </div>
      </foreignObject>
    </motion.svg>
  );
};
