// note: these are ordered in terms of preference

const CONTAINERS = [
  "webm",
  "mp3",
  "mp4",
  "ogg",
  "aac",
  "x-matroska",
  // "3gpp",
  // "3gpp2",
  // "3gp2",
  "quicktime",
  "mpeg",
  "flac",
  "x-flac",
  "wave",
  "wav",
  "x-wav",
  "x-pn-wav",
  "not-supported",
];

const CODECS = [
  "mp4a",
  "av1",
  "vp9",
  "vp9.0",
  "vp8",
  "vp8.0",
  "avc1",
  "h265",
  "h.265",
  "h264",
  "h.264",
  "opus",
  "vorbis",
  "pcm",
  "aac",
  "mpeg",
  // "rtx",
  // "red",
  // "ulpfec",
  // "g722",
  // "pcmu",
  // "pcma",
  // "cn",
  // "telephone-event",
  "not-supported",
];

/**
 * See https://stackoverflow.com/a/64656254
 */
function getAllSupportedMimeTypes(...mediaTypes: Array<"audio" | "video">) {
  if (!mediaTypes.length) mediaTypes.push("video", "audio");

  return [
    ...new Set(
      CONTAINERS.flatMap((ext) =>
        CODECS.flatMap((codec) =>
          mediaTypes.flatMap((mediaType) => [
            // NOTE: 'codecs:' will always be true (false positive)
            `${mediaType}/${ext};codecs=${codec}`,
          ]),
        ),
      ),
    ),
    ...new Set(
      CONTAINERS.flatMap((ext) =>
        CODECS.flatMap((codec1) =>
          CODECS.flatMap((codec2) =>
            mediaTypes.flatMap((mediaType) => [
              `${mediaType}/${ext};codecs="${codec1}, ${codec2}"`,
            ]),
          ),
        ),
      ),
    ),
  ].filter((variation) => MediaRecorder.isTypeSupported(variation));
}

/**
 * Get's the supported audio types that can be used for recording with {@link MediaRecorder}.
 */
export function getSupportedAudioRecordingTypes() {
  return getAllSupportedMimeTypes("audio");
}

/**
 * Get's the supported video types that can be used for recording with {@link MediaRecorder}.
 */
export function getSupportedVideoRecordingTypes() {
  return getAllSupportedMimeTypes("video");
}

/**
 * Obtains the correct file extension for a given `mimeType`.
 *
 * Note: This function is only designed to support `mimeType`s from {@link getSupportedAudioRecordingTypes} or {@link getSupportedVideoRecordingTypes}.
 */
export function getExtensionForMimeType(mimeType: string) {
  const match = /^(?<type>.+?)\/(?<container>.+?);(?<attributes>.+?)$/gm.exec(
    mimeType,
  );

  if (!match || !match.groups) {
    throw new Error(`Unrecognized mimeType: ${mimeType}`);
  }

  const { type, container } = match.groups;

  switch (type) {
    case "audio":
      switch (container as (typeof CONTAINERS)[number]) {
        case "webm":
          return "webm";
        case "mp4":
          return "m4a";
        case "mp3":
          return "mp3";
        case "ogg":
          return "ogg";
        case "x-matroska":
          return "mkv";
        default:
          throw new Error(`Unknown container: ${container}`);
      }
    case "video":
      switch (container as (typeof CONTAINERS)[number]) {
        case "webm":
          return "webm";
        case "mp4":
          return "mp4";
        case "ogg":
          return "ogg";
        case "x-matroska":
          return "mkv";
        default:
          throw new Error(`Unknown container: ${container}`);
      }
    default:
      throw new Error(`Unknown type: ${type}`);
  }
}
