import { useEffect, useRef, useState } from "react";
import { MainActivityProps } from "../layout/types";
import { Player, VideoPlayer } from "@arena-active/video-player";
import { LessonVideoActivity } from "@arena-active/trpc-client";
import styles from "./VideoPlayerActivity.module.css";
import { ExpiringButton } from "./ExpiringButton";
import { Button } from "@arena-active/client-lib";

export const VideoPlayerActivity: React.FC<MainActivityProps> = ({
  activity,
  isComplete,
  onComplete,
}) => {
  const playerRef = useRef<Player | null>(null);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [autoProgressCancelled, setAutoProgressCancelled] = useState(false);

  // TODO - this is zod parsed on the server, but should port zod models to a lib for safety/clarity
  const config: LessonVideoActivity = activity.config as LessonVideoActivity;

  useEffect(() => {
    if (isPlayerReady) {
      playerRef.current?.src({
        src: config.videoUrl,
      });
    }
  }, [config, isPlayerReady]);

  return (
    <VideoPlayer
      options={{
        autoplay: false,
        controls: true,
        fill: true,
        loop: false,
        preload: "auto",
      }}
      showReplayButton={true}
      endStateChildren={
        !isComplete && (
          <div className={styles.buttons}>
            {!autoProgressCancelled && (
              <Button
                size="MEDIUM"
                type="SECONDARY_OUTLINED"
                icon="pi pi-times"
                onClick={() => setAutoProgressCancelled(true)}
              />
            )}
            <ExpiringButton
              label="Continue"
              time={4}
              cancelled={autoProgressCancelled}
              onClick={() => onComplete?.()}
            />
          </div>
        )
      }
      onReady={(player: Player) => {
        playerRef.current = player;
        setIsPlayerReady(true);
      }}
      onVideoPlay={() => setAutoProgressCancelled(false)}
    ></VideoPlayer>
  );
};
