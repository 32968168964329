import React, { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import { useAtomValue } from "jotai";
import { ThemeSelectCard } from "./ThemeSelectCard";
import { Theme, themeAtom } from "../../../theme";

export interface ThemeSelectDropdownProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  onChanged?: (theme: Theme) => void;
}

export const ThemeSelectDropdown: React.FC<ThemeSelectDropdownProps> = ({
  onChanged,
  ...rest
}) => {
  const currentTheme = useAtomValue(themeAtom);

  const [selectedIndex, setSelectedIndex] = useState(
    currentTheme === "system" ? 0 : currentTheme === "light" ? 1 : 2,
  );

  return (
    <div {...rest} className="flex">
      <ThemeSelectCard
        mode={selectedIndex == 0 ? "selected" : "outlined"}
        theme="system"
        className="w-4"
        onClick={() => {
          setSelectedIndex(0);
          onChanged?.("system");
        }}
      >
        <p className="text-lg pl-2">System</p>
      </ThemeSelectCard>
      <ThemeSelectCard
        mode={selectedIndex == 1 ? "selected" : "outlined"}
        theme="light"
        className="w-4"
        onClick={() => {
          setSelectedIndex(1);
          onChanged?.("light");
        }}
      >
        <p className="text-lg pl-2">Light</p>
      </ThemeSelectCard>
      <ThemeSelectCard
        mode={selectedIndex == 2 ? "selected" : "outlined"}
        theme="dark"
        className="w-4"
        onClick={() => {
          setSelectedIndex(2);
          onChanged?.("dark");
        }}
      >
        <p className="text-lg pl-2">Dark</p>
      </ThemeSelectCard>
    </div>
  );
};
