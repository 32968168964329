import React, { type ReactElement, useRef, useState } from "react";
import styles from "./TrackBar.module.css";
import { classNames } from "primereact/utils";

interface TrackBarProps {
  current: number;
  setCurrent: (c: number) => void;
  total: number;
  showTrack?: boolean;
  showKnob?: boolean;
  trackHeight?: number;
  trackBarColor?: string;
  closeVolumeBar?: () => void;
  className?: string | undefined;
}

const getPercentage = (current: number, total: number): string => {
  const percentage = (current / total) * 100;
  return `${percentage}%`;
};

const getClientX = (event: MouseEvent | TouchEvent): number => {
  if (event instanceof TouchEvent) {
    return event.touches[0].clientX;
  }
  return event.clientX;
};

const TrackBar = ({
  current,
  setCurrent,
  total,
  showTrack = true,
  showKnob = true,
  trackHeight = 4,
  trackBarColor = "color-mix(in srgb, var(--text-body) 10%, transparent)",
  closeVolumeBar,
  className,
}: TrackBarProps): ReactElement => {
  const [isHover, setIsHover] = useState(false);
  const progressBarRef = useRef<HTMLDivElement>(null);
  const volumeBarTimer = useRef<ReturnType<typeof setInterval> | null>(null);

  const setPosition = (event: MouseEvent | TouchEvent): void => {
    if (!progressBarRef.current) return;

    const progressBarRect = progressBarRef.current.getBoundingClientRect();
    const maxRelativePos = progressBarRect.width;
    const relativePos = getClientX(event) - progressBarRect.left;

    if (relativePos < 0) {
      setCurrent(0);
      return;
    }

    if (relativePos > maxRelativePos) {
      setCurrent(total);
      return;
    }

    setCurrent((total * relativePos) / maxRelativePos);
  };

  const stopVolumeBarTimer = () => {
    volumeBarTimer?.current && clearTimeout(volumeBarTimer?.current);
  };

  const handleMouseEnter = (): void => setIsHover(true);
  const handleMouseLeave = (): void => {
    setIsHover(false);
    volumeBarTimer.current = setTimeout(() => {
      closeVolumeBar && closeVolumeBar();
    }, 1500);
  };

  const onMouseOrTouchMove = (event: MouseEvent | TouchEvent): void => {
    setPosition(event);
    stopVolumeBarTimer();
  };

  const onMouseDownOrTouchStart = (
    event: React.MouseEvent | React.TouchEvent,
  ): void => {
    if (event.nativeEvent instanceof MouseEvent) {
      window.addEventListener("mousemove", onMouseOrTouchMove);
      window.addEventListener("mouseup", onMouseOrTouchUp);
    } else {
      window.addEventListener("touchmove", onMouseOrTouchMove);
      window.addEventListener("touchend", onMouseOrTouchUp);
    }

    setPosition(event.nativeEvent);
    setIsHover(true);
  };

  const onMouseOrTouchUp = (event: MouseEvent | TouchEvent): void => {
    if (event instanceof MouseEvent) {
      window.removeEventListener("mousemove", onMouseOrTouchMove);
      window.removeEventListener("mouseup", onMouseOrTouchUp);
    } else {
      window.removeEventListener("touchmove", onMouseOrTouchMove);
      window.removeEventListener("touchend", onMouseOrTouchUp);
    }
    setIsHover(false);
  };

  const percentage = getPercentage(current, total);

  return (
    <div
      tabIndex={0}
      className={classNames(styles.container, className)}
      onMouseDown={onMouseDownOrTouchStart}
      onTouchStart={onMouseDownOrTouchStart}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={progressBarRef}
    >
      <div
        className={styles.track_bar}
        style={{
          background: `linear-gradient(to right, var(--text-body) ${percentage}, ${trackBarColor} ${percentage})`,
          height: showTrack ? trackHeight : 0,
        }}
      >
        <div
          className={classNames(
            styles.track_knob,
            (showKnob || isHover) && "opacity-100",
          )}
          style={{ left: percentage }}
        />
      </div>
    </div>
  );
};

export default TrackBar;
