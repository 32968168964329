import * as Sentry from "@sentry/react";

const productionOptions: Sentry.BrowserOptions = {
  // samples 10% of sessions
  replaysSessionSampleRate: 0.1,

  // sample sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
};

const developmentOptions: Sentry.BrowserOptions = {
  // samples 100% of sessions
  replaysSessionSampleRate: 1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.feedbackIntegration({
      colorScheme: "system",
    }),
  ],
};

/**
 * Default configuration for Sentry in production/dev client applications
 * For direct access to Sentry configuration, use `Sentry` exported from this module
 * @param options Sentry.BrowserOptions
 */
export const initSentry = (
  dsn: string,
  isProduction: boolean = false,
  stack?: string,
) => {
  if (!dsn) {
    console.warn("Sentry DSN not provided. Sentry will not be initialized.");
    return;
  }
  const options = isProduction ? productionOptions : developmentOptions;
  options.dsn = dsn;
  if (stack) {
    options.environment = stack;
  } else {
    options.environment = "local";
    options.replaysSessionSampleRate = 0;
  }

  options.integrations = Array.isArray(options.integrations)
    ? [...options.integrations]
    : [];

  // Add replay integration
  options.integrations.push(
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  );

  Sentry.init(options);
};

export const identifySentryUser = (email: string) => {
  Sentry.setUser({ email });
};

/**
 * Direct Sentry access for more advanced configuration.
 * For default configuration, use `initSentry` exported from this module
 */
export { Sentry };
