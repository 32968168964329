import styles from "./ThemePreview.module.css";
import { keys } from "./ThemeList";
interface HolderProps {
  name: string;
}

const Holder: React.FC<HolderProps> = ({ name }) => {
  const localStyle = {
    color: `var(--${name})`,
  };

  return (
    <div className={styles.holder} style={localStyle}>
      {name}
    </div>
  );
};

export const ThemePreview: React.FC = () => {
  return (
    <div className={styles.previewBoxes}>
      {keys.map((k, index) => (
        <Holder name={k} key={index} />
      ))}
    </div>
  );
};
