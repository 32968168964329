import { useLayoutEffect, useRef, useState } from "react";
import formatDuration from "format-duration";
import { RecordIcon } from "../common/Icons";
import styles from "./UserMediaRecorder.module.css";
import { useMediaQuery } from "react-responsive";

export interface RecorderTimerProps {
  punctuation?: string;
}

const defaultProps = {
  punctuation: ".",
} satisfies Partial<RecorderTimerProps>;

export function RecorderTimer(props: RecorderTimerProps) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { punctuation } = { ...defaultProps, ...props };
  const spanRef = useRef<HTMLSpanElement>(null);
  const [startTimeMs] = useState(() => performance.now());

  const [elapsedMs, setElapsedMs] = useState(0);

  useLayoutEffect(() => {
    const effectState = { isActive: true };

    function timerUpdate() {
      if (effectState.isActive) {
        const currentElapsedMs = performance.now() - startTimeMs;
        setElapsedMs(currentElapsedMs);
        requestAnimationFrame(timerUpdate);
      }
    }

    timerUpdate();

    return () => {
      effectState.isActive = false;
    };
  }, [startTimeMs]);

  useLayoutEffect(() => {
    if (spanRef.current) {
      let formattedDuration;
      if (isMobile) {
        const elapsedSeconds = Math.floor(elapsedMs / 1000);
        formattedDuration = `${elapsedSeconds.toString().padStart(2, "0")}`;
      } else {
        formattedDuration = formatDuration(elapsedMs, {
          leading: true,
          ms: true,
        });
      }

      // omit punctuation if mobile
      spanRef.current.textContent =
        formattedDuration + (isMobile ? "" : punctuation);
    }
  }, [elapsedMs, isMobile, punctuation]);

  return (
    <div className={styles.record_container}>
      <span className={styles.text} ref={spanRef} />
      {!isMobile && <RecordIcon />}
    </div>
  );
}
