import { ComponentType, useEffect, useState } from "react";
import { StorageClient, makeStorageServices } from "../storage";

interface WithStorageProps {
  storageClient?: ReturnType<typeof StorageClient>;
}

export function withStorageClient<T extends WithStorageProps>(
  WrappedComponent: ComponentType<T>,
  trpcClientUrl: string,
) {
  return (props: T) => {
    const [storageClient, setStorageClient] =
      useState<ReturnType<typeof StorageClient>>();

    useEffect(() => {
      const { client } = makeStorageServices(trpcClientUrl);
      setStorageClient(client);
    }, []);

    return <WrappedComponent {...props} storageClient={storageClient!} />;
  };
}
