import styles from "./NavSectionTitle.module.css";
import { Typography } from "@arena-active/client-lib";

export const NavSectionTitle: React.FC<{
  title: string;
  isSectionAvailable: boolean;
}> = ({ title, isSectionAvailable = false }) => {
  return (
    <div className={styles.navSectionTitle}>
      <Typography variant={"titleMedium"}>
        <span
          className={`
            ${styles.titleText} ${
              isSectionAvailable ? "" : styles.notAvailable
            }`}
        >
          {title}
        </span>
      </Typography>
    </div>
  );
};
