import React, { useRef, useEffect, FC, useState } from "react";
import videojs from "video.js";
import Player from "video.js/dist/types/player";
import "video.js/dist/video-js.css";
import styles from "./VideoPlayer.module.css";
import { ReplayIcon } from "./Icons";

export interface VideoJsPlayerOptions {
  sources?: { src: string; type: string }[];
  autoplay?: boolean;
  controls?: boolean;
  fluid?: boolean;
  fill?: boolean;
  loop?: boolean;
  preload?: string;
}

export interface VideoPlayerProps {
  options: VideoJsPlayerOptions;
  endStateChildren?: React.ReactNode;
  showReplayButton?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReady?: (player: any) => void;
  onVideoPlay?: () => void;
  onVideoEnded?: () => void;
}
// see:  https://videojs.com/guides/react/
export const VideoPlayer: FC<VideoPlayerProps> = (props: VideoPlayerProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player | null>(null);
  const { options, onReady } = props;
  const [videoEnded, setVideoEnded] = useState(false);
  useEffect(() => {
    // Make sure Video.js player is only initialized once
    if (!playerRef.current && videoRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
      const videoElement = document.createElement("video-js");

      videoElement.classList.add(styles.videoPlayer);
      videoRef.current.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        videojs.log("player is ready");
        player.aspectRatio("16:9");
        onReady?.(player);
      }));
    } else {
      const player = playerRef.current;

      if (player) {
        player.autoplay(options.autoplay);
        player.src(options.sources);
      }
    }
  }, [options, videoRef, onReady]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    player?.on("ended", function () {
      console.log("PLayer Ended");
      if (player.isFullscreen()) {
        player.exitFullscreen();
      }
      setVideoEnded(true);
      props.onVideoEnded?.();
      player.controls(false);
    });
    player?.on("play", () => {
      props.onVideoPlay?.();
      setVideoEnded(false);
      player.controls(true);
    });
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <>
      <div data-vjs-player="" className={styles.playerContainer}>
        <div ref={videoRef} className={styles.videoPlayerWrapper}>
          {videoEnded && (
            <div className={styles.videoOverlay}>
              <div className={styles.replayCircle}>
                <ReplayIcon
                  className={styles.replayIcon}
                  onClick={() => playerRef.current?.play()}
                />
              </div>
              {props.endStateChildren}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
