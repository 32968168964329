import { useAtomValue } from "jotai";
import { themeAtom } from "./themeAtoms";
import lightStyles from "./lightTheme.module.css";
import darkStyles from "./darkTheme.module.css";
import { getSystemTheme } from "../util";

export const ThemeContainer: React.FC<{
  foo?: string;
  children: JSX.Element | JSX.Element[];
}> = (props) => {
  const currentTheme = useAtomValue(themeAtom);
  const systemTheme = getSystemTheme();

  const theme = currentTheme === "system" ? systemTheme : currentTheme;

  const styles = theme === "light" ? lightStyles : darkStyles;
  return <div className={styles.themeBody}>{props.children}</div>;
};
