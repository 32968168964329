import React, { useEffect, useRef } from "react";
import styles from "./FeedbackPanel.module.css";
import { classNames } from "primereact/utils";
import { AiBot, AiBotMode } from "../aiBot/AiBot";
import { Typography } from "../typography/Typography";
import { AnimatePresence, motion } from "framer-motion";
import { FeedbackRating } from "../common/FeedbackRating";
import { Button } from "../common/Button";

export interface FeedbackPanelProps {
  className?: string;
  feedback: string;
  rating: number;
  observeOnly?: boolean;
  onContinue?: () => void;
}

export const FeedbackPanel: React.FC<FeedbackPanelProps> = ({
  className,
  feedback,
  rating,
  observeOnly,
  onContinue,
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(!!observeOnly);
  const [hasMore, setHasMore] = React.useState<boolean>(false);
  const tabRef = useRef<HTMLDivElement>(null);

  const panelRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const elem = tabRef.current;
    setHasMore(!!elem && elem.scrollHeight - elem.offsetHeight > 100);

    if (feedback) {
      panelRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [feedback]);

  return (
    <div className={classNames(className, styles.feedbackPanel)} ref={panelRef}>
      <div
        className={styles.tabBody}
        style={expanded ? { maxHeight: "unset" } : {}}
        ref={tabRef}
      >
        <div className={styles.feedback}>
          <div className={styles.botAndRating}>
            <AiBot mode={feedback ? AiBotMode.COMPLETED : AiBotMode.THINKING} />
            {rating > 0 && <FeedbackRating rating={rating} />}
          </div>

          <AnimatePresence>
            {feedback && (
              <motion.div
                className={styles.feedbackContainer}
                initial={{ opacity: 0, marginTop: 100 }}
                animate={{ opacity: 1, marginTop: 0 }}
              >
                <Typography variant="displaySmall">{feedback}</Typography>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {feedback && !expanded && !observeOnly && (
          <div
            className={styles.moreButton}
            style={{ display: hasMore ? "block" : "none" }}
          >
            <Button
              label="Show more"
              type="SECONDARY_FILLED"
              onClick={() => setExpanded(true)}
            />
          </div>
        )}
      </div>
      {feedback && !observeOnly && (!hasMore || expanded) && (
        <Button
          label="Continue"
          onClick={onContinue}
          className={styles.continueButton}
        />
      )}
    </div>
  );
};
