/**
 * This is a single file upload ui pane
 * that shows progress/completetion/deletion etc.
 */
import { FC, useState } from "react";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import styles from "./FileUploadPanel.module.css";
import { Typography } from "../typography/Typography";
import { classNames } from "primereact/utils";
import prettyBytes from "pretty-bytes";
import { UploadTarget } from "../../storage";

export interface UploadStatusProps {
  kiloBytes: number;
  totalKiloBytes: number;
}

const UploadStatus: FC<UploadStatusProps> = ({ kiloBytes, totalKiloBytes }) => {
  const percentage = Math.floor((kiloBytes / totalKiloBytes) * 100);

  return (
    <div className={styles.uploadStatus}>
      <Typography variant="subtitleMedium">{`${prettyBytes(
        kiloBytes,
      )} • ${percentage}%`}</Typography>
    </div>
  );
};

export interface FileAndIconProps {
  filename: string;
  progress: number;
  size: number;
  error?: string;
}

const FileAndIcon: FC<FileAndIconProps> = ({
  filename,
  progress,
  size,
  error,
}) => {
  return (
    <div className={styles.fileAndIcon}>
      <i className="pi pi-file"></i>
      <div className={styles.nameAndStatus}>
        <Typography variant="bodyMedium">{filename}</Typography>
        <UploadStatus totalKiloBytes={size} kiloBytes={progress} />
        {error && (
          <Typography variant="subtitleMedium" color="error">
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
};

export interface LoadingProgress {
  progress: number;
  total: number;
}

export type Status = "idle" | "uploading" | "success" | "error";
export interface FileUploadPanelProps {
  onRemove: (file: string) => void;
  target: UploadTarget;
  // filename: string;
  className?: string;
}

export const FileUploadPanel: FC<FileUploadPanelProps> = ({
  // filename,
  className,
  target,
  onRemove,
}) => {
  const [info, setInfo] = useState({
    progress: target.progress,
    status: target.status,
    error: target.error,
  });

  target.on("progress", (e) => {
    setInfo((prev) => {
      return {
        ...prev,
        progress: e.detail.progress,
        status: "uploading",
      };
    });
  });

  target.on("success", () => {
    setInfo((prev) => {
      return {
        ...prev,
        status: "success",
      };
    });
  });

  target.on("error", (e) => {
    setInfo((prev) => {
      return {
        ...prev,
        status: "error",
        error: e.detail.error,
      };
    });
  });

  const percentage =
    info.status === "uploading"
      ? Math.floor((info.progress / target.size) * 100)
      : 0;

  return (
    <div
      className={classNames(
        className,
        styles.panel,
        info.status === "error" && styles.errorPanel,
      )}
    >
      <div className={styles.details}>
        <FileAndIcon
          filename={target.filename}
          progress={info.progress}
          size={target.size}
          error={info.error}
        />
        <Button
          className={styles.removeButton}
          icon="pi pi-trash"
          rounded
          text
          aria-label="Remove"
          onClick={() => onRemove(target.filename)}
        />
      </div>

      {info.status === "uploading" && (
        <ProgressBar
          className={styles.progressBar}
          showValue={false}
          value={percentage}
        />
      )}
    </div>
  );
};
