import { useEffect, useRef, useState, FC } from "react";
import { AudioVisualizer } from "react-audio-visualize";
import { Button } from "primereact/button";
import {
  Pause,
  Play,
  VolumeDown,
  Settings,
  VolumeMute,
  VolumeUp,
  Playback,
} from "../common/Icons";
import { formatTime } from "../../util";
import TrackBar from "./TrackBar";
import { SettingsMenu, MenuItem, Option } from "./SettingsMenu";
import { Tooltip } from "../menu/Tooltip";
import styles from "./AudioPlayer.module.css";
import useKeypress from "react-use-keypress";
import { UploadBlob } from "../../storage";
import { useMediaQuery } from "react-responsive";

interface AudioPlayerProps {
  capturedAudio: UploadBlob;
  showAudioVisualizer: boolean | null;
}

export type SettingsType = {
  subtitle: Option;
  playbackSpeed: Option;
  quality: Option;
};

const DEFAULT_VOLUME: number = 75;

const playbackOptions: Array<Option> = [
  { id: "0.25", label: "0.25" },
  { id: "0.5", label: "0.5" },
  { id: "0.75", label: "0.75" },
  { id: "normal", label: "Normal" },
  { id: "1.25", label: "1.25" },
  { id: "1.5", label: "1.5" },
  { id: "1.75", label: "1.75" },
  { id: "2", label: "2" },
];

const menuItems: Array<MenuItem<Option>> = [
  {
    name: "playbackSpeed",
    icon: <Playback />,
    label: "Playback speed",
    value: "normal",
    options: playbackOptions,
  },
];

const RenderVolumeIcon: FC<{ volumeLevel: number }> = ({ volumeLevel }) => {
  if (volumeLevel === 0) {
    return <VolumeMute />;
  }
  if (volumeLevel > 0 && volumeLevel < 50) {
    return <VolumeDown />;
  }

  if (volumeLevel >= 50) {
    return <VolumeUp />;
  }

  return <VolumeDown />;
};

export const AudioPlayer = ({
  capturedAudio,
  showAudioVisualizer,
}: AudioPlayerProps): JSX.Element => {
  const visualizerRef = useRef<HTMLCanvasElement>(null);
  const audioRef = useRef<HTMLAudioElement>(new Audio());
  const savedVolume = useRef<number | null>(null);
  const [volumeLevel, setVolumeLevel] = useState<number>(DEFAULT_VOLUME);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [volumeBarOpen, setVolumeBarOpen] = useState<boolean>(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState<boolean>(false);
  const [settingsState, setSettingsState] = useState<SettingsType>({
    subtitle: { id: "off", label: "off" },
    playbackSpeed: { id: "normal", label: "normal" },
    quality: { id: "auto", label: "auto" },
  });

  const isSmallScreen = useMediaQuery({ maxWidth: 422 });

  const setTime = (time: number): void => {
    audioRef.current.currentTime = time;
    setCurrentTime(time);
  };

  const seekTo = (time: number): void => setTime(time);

  const muteAudio = (): void => {
    audioRef.current.volume = 0;
    savedVolume.current = volumeLevel;
    setVolumeLevel(0);
  };

  const unMuteAudio = (): void => {
    const savedVolumeLevel = savedVolume?.current;
    if (savedVolumeLevel) {
      audioRef.current.volume = savedVolumeLevel / 100;
      setVolumeLevel(savedVolumeLevel);
    }
  };

  const setVolume = (volume: number): void => {
    audioRef.current.volume = volume / 100;
    setVolumeLevel(volume);
  };

  const playAudio = (): void => {
    if (!audioRef.current.src) return;
    if (audioRef.current.duration !== Infinity)
      setDuration(audioRef.current.duration);

    audioRef.current.play();
    setIsPlaying(true);

    const handleTimeUpdates = (): void => {
      if (audioRef.current.currentTime >= duration) {
        pauseAudio();
        setTime(0);
        return;
      }
      setCurrentTime(audioRef.current.currentTime);

      requestAnimationFrame(handleTimeUpdates);
    };
    requestAnimationFrame(handleTimeUpdates);
  };

  const updateSettingsState = (menuName: string, option: Option) => {
    setSettingsState((prevState: SettingsType) => ({
      ...prevState,
      [menuName as string]: option,
    }));

    if (menuName === "playbackSpeed") {
      const value = option?.id;
      const strToNumber = (str: string) => {
        if (str === "normal") {
          return 1;
        }
        return Number(str);
      };
      audioRef.current.playbackRate = strToNumber(value);
    }
  };

  const pauseAudio = (): void => {
    audioRef.current.pause();
    setIsPlaying(false);
  };

  useEffect(() => {
    const audio = audioRef?.current;
    (async () => {
      if (audio) {
        audio.src = URL.createObjectURL(capturedAudio);
        audio.loop = false;
        audio.volume = DEFAULT_VOLUME / 100;
        audio.muted = false;
        audio.autoplay = false;
        audio.crossOrigin = null;
        audio.preload = "";
        audio.playbackRate = 1.0;

        audio.addEventListener(
          "ended",
          () => {
            setIsPlaying(false);
            setTime(0);
          },
          { once: true },
        );
      }

      const audioBuffer = await capturedAudio.arrayBuffer();
      const audioContext = new AudioContext();
      await audioContext.decodeAudioData(audioBuffer, (buffer) => {
        setDuration(buffer.duration);
      });
    })();

    return () => {
      URL.revokeObjectURL(audio.src);
    };
  }, [capturedAudio]);

  useKeypress(["p", "m"], (event: KeyboardEvent) => {
    if (event.key === "p") {
      isPlaying ? pauseAudio() : playAudio();
    }
    if (event.key === "m") {
      volumeLevel > 0 ? muteAudio() : unMuteAudio();
    }
  });

  return (
    <div className={styles.topContainer}>
      {showAudioVisualizer && (
        <AudioVisualizer
          ref={visualizerRef}
          blob={capturedAudio}
          width={500}
          height={75}
          barWidth={1}
          gap={0}
          barColor={"#f76565"}
          barPlayedColor={"#000000"}
          currentTime={currentTime}
        />
      )}
      {settingsMenuOpen && (
        <SettingsMenu
          settingsState={settingsState}
          menuItems={menuItems}
          updateSettingsState={updateSettingsState}
          closeSettingsMenu={() => setSettingsMenuOpen(false)}
          side="right"
        />
      )}
      <div className={styles.container}>
        <Tooltip hideOnTargetClick target=".tooltip" />
        <div className={styles.playbackContainer}>
          <div className={styles.playbackButtonContainer}>
            <Button
              className={`${styles.playButton} tooltip`}
              icon={isPlaying ? <Pause /> : <Play />}
              onClick={isPlaying ? pauseAudio : playAudio}
              data-pr-tooltip={isPlaying ? "Pause (P)" : "Play (P)"}
              data-pr-position="top"
              data-pr-at="center top-13"
            />
          </div>
          {!(isSmallScreen && volumeBarOpen) && (
            <div className={styles.timeDisplayContainer}>
              <span className={styles.currentTime}>
                {formatTime(currentTime)}
              </span>
              <span className={styles.separator}> / </span>
              <span className={styles.duration}>{formatTime(duration)}</span>
            </div>
          )}
        </div>

        <div className={styles.trackbarContainer}>
          <TrackBar
            total={duration}
            current={currentTime}
            setCurrent={seekTo}
          />
        </div>

        {/* <div className={styles.playbackSpeedContainer}>
          <span className={styles.playbackSpeedLabel}>
            {settingsState?.playbackSpeed?.id === "normal"
              ? "1"
              : settingsState?.playbackSpeed?.label}
            x
          </span>
          <Button
            className={styles.playbackSpeedButton}
            icon={<Playback fillColor="var(--text-body)" size={21} />}
          />
        </div> */}

        <div className={styles.volumeAndSettingsContainer}>
          <div
            className={styles.volumeContainer}
            onMouseOver={() => setVolumeBarOpen(true)}
            onMouseLeave={() => setVolumeBarOpen(false)}
          >
            <Button
              className={`${styles.volumeButton} tooltip`}
              icon={<RenderVolumeIcon volumeLevel={volumeLevel} />}
              onClick={() => {
                if (volumeLevel > 0) {
                  muteAudio();
                }
              }}
              data-pr-tooltip={volumeLevel > 0 ? "Mute (M)" : "Unmute (M)"}
              data-pr-position="top"
              data-pr-at="center top-13"
            />
            {volumeBarOpen && (
              <TrackBar
                current={volumeLevel}
                setCurrent={setVolume}
                total={100}
                className={styles.volumeTrackBar}
                closeVolumeBar={() => setVolumeBarOpen(false)}
              />
            )}
          </div>
          <div className={styles.settingsButtonContainer}>
            <Button
              className={`${styles.settingsButton} tooltip`}
              icon={<Settings />}
              onClick={(e) => {
                e.stopPropagation();
                setSettingsMenuOpen(true);
              }}
              data-pr-tooltip="Settings"
              data-pr-position="top"
              data-pr-at="center top-13"
              data-pr-disabled={settingsMenuOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
