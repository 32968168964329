import { MainActivityProps } from "../layout/types";
import {
  ActivityState,
  SingleResponseExercise,
} from "@arena-active/trpc-client";
import { ExerciseWithStorage } from "../exercise/Exercise";
import React, { useEffect } from "react";
import { Feedback, Response } from "../lesson/ActivityViewer";

export interface SingleResponseExerciseProps extends MainActivityProps {
  state?: ActivityState;
}

export const SingleResponseExerciseActivity: React.FC<
  SingleResponseExerciseProps
> = ({ onComplete, activity, state }) => {
  console.log("SingleResponseExerciseActivity", { activity, state });

  const [feedback, setFeedback] = React.useState<Feedback | undefined>(
    undefined,
  );
  const [response, setResponse] = React.useState<Response | undefined>(
    undefined,
  );

  useEffect(() => {
    const completeState = activity.states?.find((state) => state.completed);
    if (completeState?.response) {
      setFeedback({
        evaluationFeedback: completeState.response.evaluationFeedback,
        evaluationRating: completeState.response.evaluationRating,
      });
      setResponse({
        responseText: completeState.response.responseText,
        audioStorageKey: completeState.response.audioStorageKey,
        videoStorageKey: completeState.response.videoStorageKey,
      });
    }
  }, []);

  const exercise = activity.config as SingleResponseExercise;
  if (!exercise.promptContent) {
    return <div>Error: No config provided.</div>;
  }

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <ExerciseWithStorage
        responseType={activity.responseType}
        details={exercise.promptContent.prompt}
        response={response}
        feedback={feedback}
        onSubmit={(response) => {
          console.log("submitting", response);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onComplete?.(response, false).then((result: any) =>
            setFeedback(result?.response),
          );
        }}
        onComplete={() => {
          onComplete?.();
        }}
      />
    </div>
  );
};
