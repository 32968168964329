import { useLayoutEffect, useState } from "react";

/**
 * Utility hook to create an object URL from a `Blob`.
 *
 * Note: The URL will be automatically revoked.
 * @param blob the blob to use when creating the URL.
 * @returns the URL.
 */
export function useBlobUrl(blob: Blob | undefined) {
  const [url, setUrl] = useState<string | undefined>(undefined);

  useLayoutEffect(
    function manageUrlLifecycle() {
      if (blob) {
        const url = URL.createObjectURL(blob);

        setUrl(url);

        return function cleanupUrl() {
          URL.revokeObjectURL(url);
        };
      }
    },
    [blob],
  );

  return url;
}
