import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { setupSentryForStudentApp } from "./sentry";
import { ClerkProvider } from "@clerk/clerk-react";

const CLERK_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;

setupSentryForStudentApp();

if (!CLERK_KEY) {
  throw new Error("Missing Clerk Key - check for: VITE_CLERK_PUBLISHABLE_KEY");
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey={CLERK_KEY}
      appearance={{
        layout: {
          termsPageUrl:
            "https://lesson-content.active.sessions.edu/terms/index.html",
        },
      }}
    >
      <App />
    </ClerkProvider>
  </React.StrictMode>,
);
