import { FC, useEffect, useState } from "react";
import { Button, ButtonSize } from "@arena-active/client-lib";
import { motion } from "framer-motion";

import styles from "./ExpiringButton.module.css";

export const ExpiringButton: FC<{
  label: string;
  time?: number;
  cancelled?: boolean;
  size?: ButtonSize;
  onClick?: () => void;
}> = ({
  label,
  time = 2,
  cancelled = false,
  size = ButtonSize.medium,
  onClick,
}) => {
  const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout>();
  let handle: NodeJS.Timeout | null = null;

  useEffect(() => {
    handle = setTimeout(() => {
      onClick && onClick();
    }, time * 1000);
    setTimeoutHandle(handle);

    return () => {
      if (handle) {
        clearTimeout(handle);
      }
    };
  }, []);

  useEffect(() => {
    console.log({ cancelled, timeoutHandle });
    if (cancelled && timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
  }, [cancelled]);

  return (
    <Button
      label={label}
      type="SECONDARY_OUTLINED"
      size={size}
      className={styles.button}
      onClick={onClick}
    >
      {!cancelled && (
        <motion.div
          className={styles.mask}
          initial={{
            clipPath: `polygon(0 0, 0% 0, 0% 100%, 0 100%, 0 0 )`,
          }}
          animate={{
            clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%, 0 0 )`,
          }}
          transition={{ duration: time }}
        >
          <div className={styles.label}>{label}</div>
        </motion.div>
      )}
    </Button>
  );
};
