import { useWavesurfer } from "@wavesurfer/react";
import { memo, useEffect, useRef } from "react";
import RecordPlugin from "wavesurfer.js/plugins/record";

export interface AudioVisualizerProps {
  media: MediaStream | null;
  isRecordingAudio: boolean;
}

const AudioVisualizer = memo(function AudioVisualizer({
  media,
  isRecordingAudio,
}: AudioVisualizerProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const { wavesurfer } = useWavesurfer({
    container: containerRef,
    waveColor: "white",
    height: 100,
    barWidth: 2,
    barGap: 2,
  });

  useEffect(
    function initializeRecordingPlugin() {
      // Initializes the recording plugin with custom settings when recording starts
      // and when the media and wavesurfer instances are available.
      if (isRecordingAudio && wavesurfer && media) {
        wavesurfer
          .registerPlugin(
            new RecordPlugin({
              scrollingWaveform: true,
              renderRecordedAudio: false,
              scrollingWaveformWindow: 0.4,
            }),
          )
          .renderMicStream(media);
      }
    },
    [isRecordingAudio, wavesurfer, media],
  );

  return <div style={{ backgroundColor: "#000" }} ref={containerRef} />;
});

export { AudioVisualizer };
